import { CustomIconFromValue } from '@app/components'
import { isMenuItemInPathname, RouteItem } from '@app/constants/routes'
import { TranslateMessage } from '@app/lib/lang'
import { Box } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import classNames from 'classnames'
import Icon from '@app/components/Icon'

const useStyles = makeStyles((theme) =>
	createStyles({
		icon: {
			fontSize: 40,
			display: 'flex',
			justifyContent: 'center',
		},
		active: {
			fill: 'white',
			color: 'white',
		},
		listOuter: {
			color: '#87a0b2',
			fill: '#87a0b2',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: 110,
			height: 110,
			flexDirection: 'column',
			borderBottom: 'solid 1px #4e5d68',
			transition: 'fill 0.2s, color 0.2s',
			'&:hover': {
				fill: 'white',
				color: 'white',
			},
		},
		activeItem: {
			backgroundImage:
				'linear-gradient(left, white, white 5%, transparent 5%, transparent 100%)',
		},
		listText: {
			fontSize: '12px',
			marginTop: theme.spacing(2),
			// [theme.breakpoints.down('xl')]: {
			// 	display: 'none',
			// },
		},
	})
)
interface SidebarMainItemProps {
	menuItem: RouteItem
	onClick: (menuIteM: RouteItem) => void
}

export const SidebarMainItem: FC<SidebarMainItemProps> = ({
	menuItem,
	onClick,
}) => {
	const { pathname } = useRouter()
	const isSelected = isMenuItemInPathname(menuItem, pathname, true)
	const classes = useStyles()
	const listItem = (
		<>
			{menuItem.icon && (
				<ListItemIcon
					className={
						isSelected
							? classNames(classes.icon, classes.active)
							: classNames(classes.icon)
					}
				>
					{menuItem.icon
						.toLowerCase()
						.includes('svg'.toLowerCase()) ? (
						<CustomIconFromValue icon={menuItem.icon} />
					) : (
						<Icon icon={menuItem.icon} size={46} />
					)}
				</ListItemIcon>
			)}

			{menuItem.label && (
				<Box
					className={
						isSelected
							? classNames(classes.listText, classes.active)
							: classNames(classes.listText)
					}
				>
					<TranslateMessage id={menuItem.label} />
				</Box>
			)}
		</>
	)

	if (menuItem.to) {
		return (
			<Link href={menuItem.to} passHref>
				<ListItem
					selected={isSelected}
					button
					key={menuItem.id}
					onClick={() => onClick(menuItem)}
					className={
						isSelected
							? classNames(classes.listOuter, classes.activeItem)
							: classNames(classes.listOuter)
					}
				>
					{listItem}
				</ListItem>
			</Link>
		)
	}

	return (
		<ListItem
			selected={isSelected}
			button
			onClick={() => onClick(menuItem)}
			key={menuItem.id}
			className={
				isSelected
					? classNames(classes.listOuter, classes.activeItem)
					: classNames(classes.listOuter)
			}
		>
			{listItem}
		</ListItem>
	)
}
