import { RouteItem } from '@app/constants'
import { TranslateMessage } from '@app/lib/lang'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'
import { SidebarItem } from './SidebarItem'
import { usePermissions } from '@app/lib/packages'

const useStyles = makeStyles((theme) =>
	createStyles({
		groupHeader: {
			fontWeight: 'bold',
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(2),
			marginTop: theme.spacing(1),
			paddingLeft: '15px',
		},
	})
)

interface GroupedSidebarItemProps {
	menuItem: RouteItem
}

export const GroupedSidebarItem: FC<GroupedSidebarItemProps> = ({
	menuItem,
}) => {
	const { hasRoutePermission } = usePermissions()
	const classes = useStyles()
	const groupedSubs = menuItem.subs?.filter((q) => hasRoutePermission(q))

	return (
		<Box>
			{menuItem.label && (
				<Typography className={classes.groupHeader}>
					<TranslateMessage id={menuItem.label} />
				</Typography>
			)}
			{groupedSubs?.map((q) => (
				<SidebarItem key={q.id} menuItem={q} />
			))}
		</Box>
	)
}
