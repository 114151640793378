import React, { FC, useEffect, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { themeColors } from '@app/constants'
import { Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { CalendarTimeIcon, ClockIcon } from '@app/assets/icons'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { CustomTextField } from '.'
import { DatePicker, TimePicker } from '@mui/lab'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
	createStyles({
		textField: {
			borderColor: themeColors.borderColor,
			width: '100%',
			fontSize: '0.75rem',
		},
		inputEndAdornment: {
			borderLeftColor: themeColors.borderColor,
			borderLeftStyle: 'solid',
			borderLeftWidth: '1px',
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingTop: theme.spacing(2),
			cursor: 'pointer',
		},
	})
)

export interface CustomDateTimePickerProps {
	disabled?: boolean
	disablePast?: boolean
	maxDate?: Date
	minDate?: Date
	type?: 'date' | 'time' | 'dateTime'
	onChange: (date: Date | null, value?: string | null | undefined) => void
	value?: Date
	className?: any
	width?: number
}

export const CustomDateTimePicker: FC<CustomDateTimePickerProps> = ({
	type = 'dateTime',
	onChange: onChangeFromProps,
	value: valueFromProps,
	className,
	width,
	...otherProps
}) => {
	const classes = useStyles()
	const [value, setValue] = useState(valueFromProps)
	const [open, setOpen] = useState(false)

	const onChange = (value: any) => {
		setValue(value)
		onChangeFromProps(value)
	}

	useEffect(() => {
		setValue(valueFromProps)
	}, [valueFromProps])

	const getEndAdornment = () => {
		return (
			<InputAdornment position="end">
				<Box
					className={classes.inputEndAdornment}
					onClick={() => setOpen(true)}
				>
					{type === 'date' || type === 'dateTime' ? (
						<CalendarTimeIcon
							fill={themeColors.pinkishGrey}
							fontSize="1.2rem"
						/>
					) : (
						<ClockIcon
							fill={themeColors.pinkishGrey}
							fontSize="1.2rem"
						/>
					)}
				</Box>
			</InputAdornment>
		)
	}

	if (type === 'date') {
		return (
			<DatePicker
				mask="__.__.____"
				onChange={onChange}
				value={value || null}
				renderInput={(params: any) => (
					<CustomTextField
						inputProps={params.inputProps}
						value={params.value}
						ref={params.ref}
						inputRef={params.inputRef}
						endAdornment={getEndAdornment()}
					/>
				)}
				open={open}
				onClose={() => setOpen(false)}
				className={className}
				inputFormat="dd.MM.yyyy"
			/>
		)
	}

	if (type === 'time') {
		return (
			<TimePicker
				{...otherProps}
				ampm={false}
				mask="__:__"
				onChange={onChange}
				renderInput={(params: any) => (
					<CustomTextField
						inputProps={params.inputProps}
						value={params.value}
						ref={params.ref}
						inputRef={params.inputRef}
						endAdornment={getEndAdornment()}
					/>
				)}
				open={open}
				onClose={() => setOpen(false)}
				value={value || null}
				className={className}
			/>
		)
	}

	return (
		<DateTimePicker
			ampm={false}
			value={value || null}
			mask="__.__.____ __:__"
			renderInput={(params: any) => (
				<CustomTextField
					inputProps={params.inputProps}
					value={params.value}
					ref={params.ref}
					inputRef={params.inputRef}
					endAdornment={getEndAdornment()}
				/>
			)}
			open={open}
			onClose={() => setOpen(false)}
			className={className}
			onChange={onChange}
			inputFormat="dd.MM.yyyy HH:mm"
		/>
	)
}
