import classNames from 'classnames'
import React, { FC } from 'react'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import { CustomIconFromValue } from '../CustomIconFromValue'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'row',
			padding: theme.spacing(2.5),
		},
		text: {},
		rootDanger: {
			backgroundColor: theme.palette.error.main,
		},
		rootWarning: {
			backgroundColor: theme.palette.warning.main,
		},
		textDanger: {
			color: 'white',
		},
		textWarning: {
			color: 'black',
		},
	})
)

interface CustomBaseAlertProps {
	type: 'danger' | 'warning'
}

export const CustomBaseAlert: FC<CustomBaseAlertProps> = ({
	children,
	type,
}) => {
	const classes = useStyles()

	return (
		<Box
			className={classNames(classes.root, {
				[classes.rootDanger]: type === 'danger',
				[classes.rootWarning]: type === 'warning',
			})}
		>
			<CustomIconFromValue
				icon="svg warning"
				style={{ color: type === 'danger' ? 'white' : 'black' }}
			/>
			<Box
				ml={2}
				className={classNames(classes.text, {
					[classes.textDanger]: type === 'danger',
					[classes.textWarning]: type === 'warning',
				})}
			>
				{children}
			</Box>
		</Box>
	)
}
