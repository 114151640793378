import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'

import { Box } from '@mui/material'
import { CustomIconFromValue } from './CustomIconFromValue'
import { TranslateMessage } from '@app/lib/lang'

const useStyles = makeStyles((theme) =>
	createStyles({
		text: {
			color: theme.palette.grey[400],
			marginTop: theme.spacing(2),
		},
	})
)

interface NoDataProps {
	icon?: string
	text?: string
}

export const NoData: FC<NoDataProps> = (props) => {
	const { icon = 'svg no-data', text = 'shared.noData' } = props

	const classes = useStyles()

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100%"
		>
			<CustomIconFromValue icon={icon} />
			<Typography className={classes.text}>
				<TranslateMessage id={text} />
			</Typography>
		</Box>
	)
}
