import React, { FC } from 'react'
import { SnackbarProvider } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme) =>
	createStyles({
		info: {
			backgroundColor: theme.palette.info.main,
		},
		success: {
			backgroundColor: theme.palette.success.main,
		},
		error: {
			backgroundColor: theme.palette.error.main,
		},
		warning: {
			backgroundColor: theme.palette.warning.main,
			color: 'black',
		},
	})
)

export const AlertsProvider: FC = ({ children }) => {
	const { success, info, error, warning } = useStyles()

	return (
		<SnackbarProvider
			classes={{
				variantSuccess: success,
				variantError: error,
				variantWarning: warning,
				variantInfo: info,
			}}
			maxSnack={3}
		>
			<>{children}</>
		</SnackbarProvider>
	)
}
