import classNames from 'classnames'
import { FC, ReactNode, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import FormControl from '@mui/material/FormControl'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import useTranslation from '../lib/lang/useTranslation'
import { locationNumberPipe } from '@app/lib/masks/numberMask'
import { IconButton, Stack } from '@mui/material'
import Icon from './Icon'
import IMask from 'imask'

export interface CustomTextProps extends Omit<OutlinedInputProps, 'size'> {
	autocomplete?: boolean
	onBlur?: any
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	classes?: {
		adornedEnd?: string
		adornedStart?: string
		root?: string
		input?: string
	}
	className?: string
	disabled?: boolean
	value?: string
	id?: string
	name?: string
	multiple?: boolean
	placeholder?: string
	readOnly?: boolean
	type?:
		| 'text'
		| 'file'
		| 'ip'
		| 'number'
		| 'integer'
		| 'decimal'
		| 'email'
		| 'password'
		| 'time'
		| 'youtubeLink'
		| 'location'
	size?: 'small' | 'medium' | 'large'
	endAdornment?: ReactNode
	startAdornment?: ReactNode
	inputProps?: any
	ref?: any
	inputRef?: any
	decimals?: boolean
	required?: boolean
	width?: string
	height?: string
	isPassword?: boolean
	numOfDecimals?: number
}

const useStyles = makeStyles((theme) =>
	createStyles({
		textField: ({
			width,
			height,
		}: {
			width: string | undefined
			height: string | undefined
		}) => ({
			borderColor: theme.palette.divider, // Assuming themeColors is part of theme
			width: width, // Use the width prop if provided
			fontSize: '0.75rem',
			backgroundColor: theme.palette.common.white, // Assuming themeColors is part of theme
			'& .MuiOutlinedInput-root': {
				height: height,
			},
		}),
		smallSize: {
			fontSize: theme.typography.subtitle2.fontSize,
			paddingBottom: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingLeft: theme.spacing(1.3),
			paddingRight: theme.spacing(1),
		},
	})
)

const CustomTextField: FC<CustomTextProps> = ({
	autocomplete = false,
	className,
	classes,
	disabled,
	name,
	placeholder,
	size = 'large',
	value = '',
	required = false,
	width = '100%',
	height: heightFromProps,
	type = 'text',
	isPassword = false,
	onChange,
	numOfDecimals,
	...otherProps
}) => {
	const { t } = useTranslation()

	const height =
		heightFromProps ?? (otherProps.multiline ? undefined : '53px')

	const componentClasses = useStyles({ width, height })
	const [showPassword, setShowPassword] = useState(false)

	if (placeholder) {
		placeholder = t(placeholder)
	}

	let outlinedProps: OutlinedInputProps = {
		name,
	}

	if (!autocomplete && name) {
		outlinedProps.autoComplete = `new-${name}`
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show)

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	return (
		<FormControl
			className={classNames(
				componentClasses.textField,
				className,
				classes?.root
			)}
			disabled={disabled}
			variant="outlined"
		>
			<OutlinedInput
				{...otherProps}
				{...outlinedProps}
				required={required}
				classes={{
					input: classNames(classes?.input, {
						[componentClasses.smallSize]: size === 'small',
					}),
					adornedStart: classes?.adornedStart,
					adornedEnd: classes?.adornedEnd,
				}}
				onChange={(e) => {
					if (type === 'decimal' || type === 'integer') {
						let textValue = e.target.value

						if (textValue && textValue.includes(',')) {
							textValue = textValue.replace(/\,/g, '.')
						}

						const val = IMask.pipe(textValue, {
							mask: Number,
							scale: type === 'integer' ? 0 : numOfDecimals || 2,
							thousandsSeparator: '',
							radix: '.',
							normalizeZeros: false,
							padFractionalZeros: false,
						})
						if (onChange) {
							onChange({
								...e,
								target: {
									...e.target,
									value: val.toString(),
									name: name || '',
								},
							})
						}
					} else if (type === 'location') {
						let textValue = e.target.value

						if (textValue && textValue.includes(',')) {
							textValue = textValue.replace(/\,/g, '.')
						}

						const val = locationNumberPipe(textValue)

						if (onChange) {
							onChange({
								...e,
								target: {
									...e.target,
									value: val.toString(),
									name: name || '',
								},
							})
						}
					} else if (onChange) onChange(e)
				}}
				inputProps={{
					...otherProps.inputProps,
					onInvalid: (e) => {
						if (type === 'email') {
							e.preventDefault()
						}
					},
					type: showPassword || !isPassword ? 'text' : 'password',
					// ...(type === 'email' && { pattern: '.+' }),
				}}
				endAdornment={
					isPassword ? (
						<Stack direction="row" alignItems="center" gap="20px">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? (
									<Icon icon="eye_off" size="25px" />
								) : (
									<Icon icon="eye_on" size="25px" />
								)}
							</IconButton>

							{otherProps.endAdornment}
						</Stack>
					) : (
						otherProps.endAdornment
					)
				}
				disabled={disabled}
				placeholder={placeholder}
				value={value}
				type={type}
			/>
		</FormControl>
	)
}

export default CustomTextField
