import { isMenuItemInPathname, menu } from '@app/constants'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useCurrentRouteInterface } from './types'

export const useCurrentRoute: useCurrentRouteInterface = () => {
	const { pathname } = useRouter()

	const route = useMemo(
		() => menu.find((q) => isMenuItemInPathname(q, pathname, true)),
		[pathname]
	)

	return {
		route,
	}
}
