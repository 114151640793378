import { createPipe } from 'imask'

export const numberPipeMaskOptions = {
	mask: Number,
	scale: 2,
	thousandsSeparator: '',
	radix: '.',
	normalizeZeros: false,
	padFractionalZeros: false,
} as IMask.MaskedNumber

export const numberPipe = createPipe(numberPipeMaskOptions)

export const locationNumberPipeMaskOptions = {
	mask: Number,
	scale: 6,
	thousandsSeparator: '',
	radix: '.',
	normalizeZeros: false,
	padFractionalZeros: false,
	min: -180,
	max: 180,
} as IMask.MaskedNumber

export const locationNumberPipe = createPipe(locationNumberPipeMaskOptions)
