import { SelectOption } from '@app/@types'
import { isObject } from '../utils'
import useTranslation from './useTranslation'

interface useTranslationHelpersInterface {
	(): {
		convertEnumToOptions: (
			enumType: any,
			translationPrefix: string
		) => SelectOption[]
	}
}

export const useTranslationHelpers: useTranslationHelpersInterface = () => {
	const { t } = useTranslation()

	const convertEnumToOptions = (enumType: any, translationPrefix: string) => {
		if (isObject(enumType)) {
			return Object.keys(enumType).map<SelectOption>((q) => ({
				label: t(`${translationPrefix}.${enumType[q]}`),
				value: enumType[q],
			}))
		}

		return [] as SelectOption[]
	}

	return {
		convertEnumToOptions,
	}
}
