import React, { FC, useContext, useEffect, useState } from 'react'

import { CustomDialogContext, CustomDialogOptions } from '../hooks'

import { CustomDialog } from './CustomDialog'

export const CustomDialogContainer: FC = () => {
	const { defaultAutoHideAfterOk, dialogs, closeDialog } =
		useContext(CustomDialogContext)
	const [, setShowDialogId] = useState<string>()

	const firstDialogId = dialogs.length ? dialogs[0].id : ''

	useEffect(() => {
		if (firstDialogId) {
			setShowDialogId(firstDialogId)
		}
	}, [firstDialogId])

	const onDialogClose = (dialogOptions: CustomDialogOptions) => () => {
		closeDialog(dialogOptions.id)

		if (dialogOptions.onClose) {
			dialogOptions.onClose()
		}
	}

	const onDialogConfirm = (dialogOptions: CustomDialogOptions) => () => {
		if (defaultAutoHideAfterOk) {
			closeDialog(dialogOptions.id)
		}

		if (dialogOptions.onConfirm) {
			dialogOptions.onConfirm()
		}
	}

	const renderDialog = (dialogOptions: CustomDialogOptions) => {
		const { id, onClose, onConfirm, ...otherProps } = dialogOptions

		return (
			<CustomDialog
				key={id}
				{...otherProps}
				isOpen={firstDialogId === id}
				onClose={onDialogClose(dialogOptions)}
				onConfirm={onDialogConfirm(dialogOptions)}
			/>
		)
	}

	if (!dialogs) {
		return null
	}

	return <>{dialogs.map(renderDialog)}</>
}
