import React, { FC, SVGProps, useState } from 'react'

interface CircleFilledPencilIconProps extends SVGProps<any> {
	fillOnHover?: string
}

export const CircleFilledPencilIcon: FC<CircleFilledPencilIconProps> = ({
	fill: fillFromProps,
	fillOnHover,
	...otherProps
}) => {
	const [fill, setFill] = useState(fillFromProps)

	const handleMouseEnter = () => {
		if (fillOnHover) {
			setFill(fillOnHover)
		}
	}

	const handleMouseLeave = () => {
		if (fillOnHover) {
			setFill(fillFromProps)
		}
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...otherProps}
		>
			<path
				fill={fill}
				d="M9.5 0C14.738 0 19 4.262 19 9.5S14.738 19 9.5 19 0 14.738 0 9.5 4.262 0 9.5 0z"
				transform="translate(-633 -607) translate(633 607)"
			/>
			<path
				fill="#FFF"
				d="M5.604 11.41c-.008.547-.004 1.094-.004 1.694.499 0 .964-.015 1.427.006.285.013.488-.071.691-.278 1.102-1.121 2.22-2.227 3.33-3.338.078-.077.151-.157.201-.21L9.428 7.463c.008-.003-.015 0-.029.012-1.235 1.231-2.47 2.462-3.7 3.698-.057.056-.093.157-.095.239zm5.708-5.76c-.289.28-.59.575-.876.854l1.755 1.751.874-.854-1.753-1.752zm-6.315 8.747c-.432-.007-.686-.252-.69-.68-.007-.893-.014-1.785.008-2.677.005-.183.093-.409.22-.538 2.08-2.096 4.171-4.181 6.263-6.265.318-.317.676-.316.992.001.897.897 1.789 1.799 2.677 2.704.325.33.319.688-.01 1.016-2.072 2.062-4.141 4.125-6.226 6.173-.154.15-.422.234-.646.256-.412.04-.831.012-1.248.011-.446 0-.893.006-1.34-.001z"
				transform="translate(-633 -607) translate(633 607)"
			/>
		</svg>
	)
}
