import classNames from 'classnames'
import { CustomIconFromValue } from '@app/components'
import { isMenuItemInPathname, RouteItem } from '@app/constants/routes'
import { useTranslation } from '@app/lib/lang'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { ListItemButton, Stack } from '@mui/material'
import Icon from '@app/components/Icon'

const useStyles = makeStyles(() =>
	createStyles({
		iconSub: {
			fontSize: 18,
			color: 'black',
			fill: 'black',
			paddingLeft: '15px',
			// minWidth: 36
		},
		iconSubActive: {
			paddingLeft: '15px',
			color: '#1e96a5',
			fill: '#1e96a5',
		},
	})
)

interface SidebarItemProps {
	menuItem: RouteItem
}

export const SidebarItem: FC<SidebarItemProps> = ({
	menuItem,
}: SidebarItemProps) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { pathname } = useRouter()
	const isSelected = isMenuItemInPathname(menuItem, pathname, true)
	const isDIsabled = menuItem.disabled
	const route = menuItem.to && !isDIsabled ? menuItem.to : ''

	return (
		<Link href={route} passHref>
			<ListItemButton key={menuItem.to} disabled={isDIsabled}>
				<Stack direction="row" alignItems="center">
					{menuItem.icon && (
						<ListItemIcon
							className={
								isSelected
									? classNames(classes.iconSubActive)
									: classNames(classes.iconSub)
							}
						>
							{menuItem.icon
								.toLowerCase()
								.includes('svg'.toLowerCase()) ? (
								<CustomIconFromValue
									icon={menuItem.icon}
									style={{ width: 22, height: 22 }}
								/>
							) : (
								<Icon icon={menuItem.icon} size={22} />
							)}
						</ListItemIcon>
					)}

					<ListItemText
						className={
							isSelected
								? classNames(classes.iconSubActive)
								: classNames(classes.iconSub)
						}
						primary={t(menuItem.label || '')}
					/>
				</Stack>
			</ListItemButton>
		</Link>
	)
}
