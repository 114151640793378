import classNames from 'classnames'
import React, { FC, useState, useEffect } from 'react'
// @ts-ignore
import Repeatable from 'react-repeatable'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { CustomIconFromValue } from './CustomIconFromValue'
import { themeColors } from '@app/constants'

const useStyles = makeStyles((theme) => {
	return createStyles({
		root: {
			background: 'white',
			borderRadius: '3px',
			border: 'solid 1px #dddddd',
			//padding: '7px 17px',
			display: 'flex',
			justifyContent: 'space-between',
		},
		unit: {
			display: 'inline-block',
		},
		right: {
			borderRadius: '0 3px 3px 0',
			borderLeft: `solid 1px ${themeColors.borderColor}`,
			display: 'flex',
			flexDirection: 'column',
			'& > div': {
				display: 'flex',
				flexGrow: 1,
			},
		},
		button: {
			alignItems: 'center',
			cursor: 'pointer',
			color: '#4a4a4a',
			display: 'flex',
			fontSize: '8px',
			flexGrow: 1,
		},
		buttonDisabled: {
			color: theme.palette.text.disabled,
			cursor: 'default',
		},
		left: {
			alignItems: 'center',
			display: 'flex',
			flexGrow: 1,
			paddingLeft: '10px',
		},
		largeSizeButton: {
			paddingLeft: theme.spacing(0.55),
			paddingRight: theme.spacing(0.55),
		},
		largeSizeInput: {
			height: 50,
			paddingBottom: theme.spacing(1.1),
			paddingTop: theme.spacing(1.1),
		},
		smallSizeButton: {
			paddingLeft: theme.spacing(0.45),
			paddingRight: theme.spacing(0.45),
		},
		smallSizeInput: {
			fontSize: theme.typography.subtitle2.fontSize,
			paddingBottom: 0,
			paddingTop: 0,
		},
	})
})

export interface NumInputProps {
	disabled?: boolean
	max?: number
	min?: number
	startNum?: number
	unit?: string
	size?: 'small' | 'medium' | 'large'
	value: number
	valueWidth?: number
	onChange: (value: number) => void
}

const NumInput: FC<NumInputProps> = ({
	disabled,
	max,
	min,
	onChange,
	size = 'medium',
	unit = '',
	value = 0,
	valueWidth = 22,
}) => {
	const classes = useStyles()

	const constrainNumber = (valueToConstrain?: number) => {
		if (typeof valueToConstrain !== 'number') {
			valueToConstrain = value
		}

		if (typeof min === 'number') {
			valueToConstrain = Math.max(min, valueToConstrain)
		}

		if (typeof max === 'number') {
			valueToConstrain = Math.min(max, valueToConstrain)
		}

		return valueToConstrain
	}

	value = constrainNumber()

	const [current, setCurrent] = useState(value)

	useEffect(() => {
		setCurrent(value)
	}, [value])

	const onValueChange = (newValue: number, checkRanges = true) => {
		if (!isNaN(min!) && checkRanges && newValue < min!) {
			newValue = min!
		}

		if (!isNaN(max!) && checkRanges && newValue > max!) {
			newValue = max!
		}

		setCurrent(newValue)

		if (checkRanges) {
			onChange(newValue)
		}
	}

	const incrementValue = () => {
		onValueChange(current + 1)
	}

	const decrementValue = () => {
		onValueChange(current - 1)
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.left}>
				<InputBase
					className={classNames({
						[classes.largeSizeInput]: size === 'large',
						[classes.smallSizeInput]: size === 'small',
					})}
					disabled={disabled}
					value={current || ''}
					style={{ width: current ? valueWidth : '100%' }}
					onChange={(e) =>
						onValueChange(parseInt(e.target.value), false)
					}
					onBlur={(e) => onValueChange(parseInt(e.target.value))}
				/>
				{unit && current ? (
					<InputBase
						readOnly={true}
						disabled={disabled}
						className={classNames({
							[classes.largeSizeInput]: size === 'large',
							[classes.smallSizeInput]: size === 'small',
						})}
						defaultValue={unit}
						style={{ width: valueWidth }}
					/>
				) : null}
			</Box>
			<Box className={classes.right}>
				<Repeatable
					repeatDelay={500}
					repeatInterval={32}
					onHold={incrementValue}
					onRelease={incrementValue}
				>
					<Box
						className={classNames(classes.button, {
							[classes.buttonDisabled]: disabled,
							[classes.largeSizeButton]: size === 'large',
							[classes.smallSizeButton]: size === 'small',
						})}
						style={{
							color: '#bababa',
						}}
					>
						<CustomIconFromValue icon="svg plus" style={{}} />
					</Box>
				</Repeatable>
				<Repeatable
					repeatDelay={500}
					repeatInterval={32}
					onHold={decrementValue}
					onRelease={decrementValue}
				>
					<Box
						className={classNames(classes.button, {
							[classes.buttonDisabled]: disabled,
							[classes.largeSizeButton]: size === 'large',
							[classes.smallSizeButton]: size === 'small',
						})}
						style={{
							borderTop: 'solid 1px #dddddd',
							color: '#bababa',
						}}
					>
						<CustomIconFromValue
							icon="svg minus"
							style={{ marginBottom: 10 }}
						/>
					</Box>
				</Repeatable>
			</Box>
		</Box>
	)
}

export default NumInput
