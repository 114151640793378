import React, { FC, ChangeEvent } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { useTranslation } from '@app/lib/lang'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import StyledCheckbox from './StyledCheckbox'

const useStyles = makeStyles((theme) =>
	createStyles({
		checkboxIcon: {
			height: theme.spacing(3),
			width: theme.spacing(3),
		},
	})
)

const StyledFormControlLabel = withStyles({
	root: {
		marginRight: '0px',
	},
})(FormControlLabel)

interface CustomCheckboxProps {
	disabled?: boolean
	label?: string
	name?: string
	onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
	value?: boolean
}

const CustomInnerCheckbox: FC<CustomCheckboxProps> = ({
	disabled,
	name,
	onChange,
	value = false,
}) => {
	const classes = useStyles()

	return (
		<Checkbox
			color="default"
			// checkedIcon={<CheckBoxIcon className={classes.checkboxIcon} />}
			// icon={<CheckBoxOutlineBlank className={classes.checkboxIcon} />}
			checked={value}
			disabled={disabled}
			onChange={onChange}
			name={name}
		/>
	)
}

export const CustomCheckbox: FC<CustomCheckboxProps> = ({
	disabled,
	label,
	...otherProps
}) => {
	const { t } = useTranslation()

	if (label) {
		const translatedLabel = t(label)

		return (
			<StyledFormControlLabel
				control={
					<StyledCheckbox
						onChange={otherProps.onChange}
						checked={otherProps.value}
						disabled={disabled}
					/>
					//<CustomInnerCheckbox {...otherProps} disabled={disabled} />
				}
				disabled={disabled}
				label={translatedLabel}
			/>
		)
	}

	return (
		<StyledCheckbox
			onChange={otherProps.onChange}
			checked={otherProps.value}
			disabled={disabled}
		/>
	)
}
