import React, { FC } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import { CustomDialogButtonType, CustomDialogButton } from '../hooks/types'

import { KeyValueObject } from '@app/@types'
import { TranslateMessage } from '@app/lib/lang'

interface CustomDialogProps {
	buttons?: CustomDialogButton[]
	headerTextTranslate?: string
	headerTranslateVariables?: KeyValueObject
	isOpen: boolean
	messageTextTranslate?: string
	messageTranslateVariables?: KeyValueObject
	onConfirm: () => void
	onClose: () => void
}

export const CustomDialog: FC<CustomDialogProps> = (props) => {
	const {
		buttons = [
			{
				buttonType: CustomDialogButtonType.ok,
				textTranslate: 'shared.ok',
			},
		],
		onConfirm,
		onClose,
		isOpen,
		headerTextTranslate,
		headerTranslateVariables,
		messageTextTranslate,
		messageTranslateVariables,
	} = props

	const onButtonClick = (button: CustomDialogButton) => () => {
		if (button.buttonType === CustomDialogButtonType.ok) {
			onConfirm()
		} else {
			onClose()
		}
	}

	return (
		<Dialog
			open={isOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{headerTextTranslate && (
					<TranslateMessage
						id={headerTextTranslate}
						values={headerTranslateVariables}
					/>
				)}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{messageTextTranslate && (
						<TranslateMessage
							id={messageTextTranslate}
							values={messageTranslateVariables}
						/>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{buttons.map((q) => (
					<Button
						key={q.textTranslate}
						autoFocus={q.buttonType === CustomDialogButtonType.ok}
						onClick={onButtonClick(q)}
					>
						<TranslateMessage id={q.textTranslate} />
					</Button>
				))}
			</DialogActions>
		</Dialog>
	)
}
