import { CircleCancelIcon, CircleCheckmarkIcon } from '@app/assets/icons'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, useEffect, useState } from 'react'
import { CustomButton } from './buttons'
import {
	CustomDurationPicker,
	CustomDurationPickerProps,
} from './CustomDurationPicker'
import { CustomIconFromValue } from './CustomIconFromValue'
import { DurationText } from './DurationText'

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			fontSize: '1rem',
			height: theme.spacing(4),
			paddingTop: 10,
			// paddingLeft: theme.spacing(1.5),
			// paddingRight: theme.spacing(1.5),
			minWidth: 0,
		},
		durationPicker: {
			maxWidth: theme.spacing(18),
		},
		cursorIcon: {
			cursor: 'pointer',
		},
	})
)

interface EditableDurationTextProps extends CustomDurationPickerProps {
	classes?: {
		duration?: string
	}
	isEditable?: boolean
}

const EditableInnerDurationText: FC<EditableDurationTextProps> = ({
	classes: classesFromProps,
	onChange,
	value = 0,
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [duration, setDuration] = useState(value)

	const classes = useStyles()

	useEffect(() => {
		setDuration(value)
	}, [value])

	const toggleDurationEditing = () => {
		setIsEditing(!isEditing)
	}

	const onDurationSubmit = () => {
		if (onChange) {
			onChange(duration)
		}

		toggleDurationEditing()
	}

	return isEditing ? (
		<Box alignItems="center" display="flex">
			<CustomDurationPicker
				className={classes.durationPicker}
				onChange={setDuration}
				value={duration}
				size="small"
			/>
			<Box display="flex" alignItems="center" ml={1} gap={1}>
				<CustomButton
					className={classes.button}
					onClick={onDurationSubmit}
					size="small"
				>
					<CircleCheckmarkIcon fill="white" />
				</CustomButton>

				<CustomButton
					className={classes.button}
					onClick={toggleDurationEditing}
					size="small"
				>
					<Box
						sx={{
							transform: 'scale(0.82)',
						}}
					>
						<CircleCancelIcon fill="white" />
					</Box>
				</CustomButton>
			</Box>
		</Box>
	) : (
		<Box alignItems="center" display="flex">
			<DurationText
				className={classesFromProps?.duration}
				durationInSeconds={duration || 0}
			/>
			<Box
				className={classes.cursorIcon}
				ml={1}
				onClick={toggleDurationEditing}
			>
				<CustomIconFromValue icon="svg pencil" />
			</Box>
		</Box>
	)
}

export const EditableDurationText: FC<EditableDurationTextProps> = ({
	classes,
	isEditable = false,
	value,
	...otherProps
}) => {
	if (!isEditable) {
		return (
			<DurationText
				className={classes?.duration}
				durationInSeconds={value || 0}
			/>
		)
	}

	return (
		<EditableInnerDurationText
			classes={classes}
			value={value}
			{...otherProps}
		/>
	)
}
