import React, { FC, SVGProps, useState } from 'react'

interface CircleFilledPlusIconProps extends SVGProps<any> {
	fillOnHover?: string
}

export const CircleFilledPlusIcon: FC<CircleFilledPlusIconProps> = ({
	fill: fillFromProps,
	fillOnHover,
	...otherProps
}) => {
	const [fill, setFill] = useState(fillFromProps)

	const handleMouseEnter = () => {
		if (fillOnHover) {
			setFill(fillOnHover)
		}
	}

	const handleMouseLeave = () => {
		if (fillOnHover) {
			setFill(fillFromProps)
		}
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 19 19"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...otherProps}
		>
			<path
				fill={fill}
				d="M9.5 0C14.738 0 19 4.262 19 9.5S14.738 19 9.5 19 0 14.738 0 9.5 4.262 0 9.5 0z"
				transform="translate(-658 -669) translate(658 669)"
			/>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M9.5 4c.38 0 .688.308.688.688v4.124h4.124c.38 0 .688.308.688.688 0 .38-.308.688-.688.688l-4.125-.001v4.126c0 .38-.307.687-.687.687-.38 0-.688-.308-.688-.688v-4.125H4.688C4.308 10.188 4 9.88 4 9.5c0-.38.308-.688.688-.688h4.125V4.688c0-.38.307-.688.687-.688z"
				transform="translate(-658 -669) translate(658 669)"
			/>
		</svg>
	)
}
