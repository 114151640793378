import { FC } from 'react'
import useTranslation from './useTranslation'

import { KeyValueObject } from '@app/@types'
import { capitalizeTitle } from '../helpers'

interface TranslateMessageProps {
	id: string
	values?: KeyValueObject
	isTitle?: boolean
}

export const TranslateMessage: FC<TranslateMessageProps> = (props) => {
	const { id, values, isTitle = false } = props
	const { t } = useTranslation()

	if (!id) {
		return null
	}
	const translation = t(id, values) || id

	if (isTitle) return <>{capitalizeTitle(translation)}</>

	return <>{translation}</>
}
