import { useContext } from 'react'
import { CustomFormContext } from './CustomFormProvider'
import { useCustomFormType } from './types'

export const useCustomForm: useCustomFormType = () => {
	const { disabled } = useContext(CustomFormContext)

	return {
		disabled,
	}
}
