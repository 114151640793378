import React from 'react'
import Router from 'next/router'
import { CHECK_AUTH } from '../lib/graphql/Login/queries'
import { NextPage } from 'next'
import { ApolloPageContext } from 'next-with-apollo'

export async function authCheck(ctx: ApolloPageContext) {
	if (
		process.env.NODE_ENV !== 'development' &&
		ctx.pathname !== '/login' &&
		ctx.pathname !== '/healthcheck'
	) {
		const { data } = await ctx.apolloClient.query({ query: CHECK_AUTH })

		if (!data?.checkAuth) {
			if (ctx.res) {
				ctx.res.writeHead(302, {
					Location: '/login',
				})
				ctx.res.end()
			} else {
				Router.push('/login')
			}

			return false
		}
	}

	return true
}

export function withAuth<TPageProps = {}>(Page: NextPage<TPageProps>) {
	const withAuth: NextPage<TPageProps> = (props: TPageProps) => (
		<Page {...props} />
	)

	withAuth.getInitialProps = async (ctx: ApolloPageContext) => {
		await authCheck(ctx)

		return {} as TPageProps
	}

	if (Page.getInitialProps) {
		withAuth.getInitialProps = Page.getInitialProps
	}

	withAuth.displayName = `withAuth(${Page.displayName})`

	return withAuth
}
