import { KeyValueObject } from '../../../../@types'

export enum AlertType {
	error = 'error',
	info = 'info',
	success = 'success',
	warning = 'warning',
}

export interface AlertMessage {
	id?: string
	alertType?: AlertType
	autoHideDuration?: number
	title?: string
	titleTranslate?: string
	titleTranslateVariables?: KeyValueObject
	message?: string
	messageTranslate?: string
	messageTranslateVariables?: KeyValueObject
}

export interface LamaAlertsContextState {
	alerts: AlertMessage[]
	defaultAutoHideDuration: number
	addAlert: (alert: AlertMessage) => string
	removeAlert: (alertId: string) => void
}

export interface useAlertsInterface {
	(): {
		addAlert: (alert: AlertMessage) => string
		addErrorAlert: (
			messageTranslate: string,
			messageTranslateVariables?: KeyValueObject
		) => string
		addInfoAlert: (
			messageTranslate: string,
			messageTranslateVariables?: KeyValueObject
		) => string
		addSuccessAlert: (
			messageTranslate: string,
			messageTranslateVariables?: KeyValueObject
		) => string
		addWarningAlert: (
			messageTranslate: string,
			messageTranslateVariables?: KeyValueObject
		) => string
		removeAlert: (alertId: string) => void
	}
}
