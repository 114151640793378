import React, { ComponentType, FC } from 'react'
import App from 'next/app'
import { ApolloClient } from '@apollo/client'
import withApollo from 'next-with-apollo'
import Immutable from 'immutable'
import Layout from '../layout'
import {
	Theme,
	StyledEngineProvider,
	ThemeProvider,
} from '@mui/material/styles'
import theme from '../lib/materialui/theme'
import shelfTheme from '@app/lib/materialui/shelfTheme'
import Head from 'next/head'
// import { auth, login, logout } from "../utils/auth"
import { ApolloProvider } from '@apollo/client'
import createApolloClient from '../lib/graphql/createApolloClient'
import createApolloDevelopment from '../lib/graphql/createApolloDevelopment'

import '../../public/graphiql.css'

import 'react-calendar-timeline/lib/Timeline.css'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import 'draft-js-alignment-plugin/lib/plugin.css'
import '@app/pageComponents/TemplateEditor/Editor/editor.css'
import '@simonwep/pickr/dist/themes/nano.min.css'
import 'public/fonts/fonts.css'

import { AlertsProvider, CustomDialogProvider } from '@app/lib/packages'
import { LocaleProvider } from '@app/lib/lang'
import { authCheck } from '@app/utils/auth'
import { CurrentUserProvider } from '@app/lib/packages/currentUser'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

;(Immutable.Iterable as any).noLengthWarning = true

const getCookie = (context: any) =>
	context && context.req && context.req.headers
		? context.req.headers.cookie
		: document.cookie

interface AppContentProps {
	apollo: ApolloClient<any>
	component: ComponentType
	pageProps: any
}

const AppContent: FC<AppContentProps> = ({
	apollo,
	component: Component,
	pageProps,
}) => {
	return (
		<>
			<Head>
				<title>Agitron Dashboard</title>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
				<link href="/fonts/agitronfonts/style.css" rel="stylesheet" />
			</Head>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<LocaleProvider>
							<ApolloProvider client={apollo}>
								<CurrentUserProvider>
									<Layout>
										<CustomDialogProvider>
											<AlertsProvider>
												<Component {...pageProps} />
											</AlertsProvider>
										</CustomDialogProvider>
									</Layout>
								</CurrentUserProvider>
							</ApolloProvider>
						</LocaleProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			</LocalizationProvider>
		</>
	)
}

const NoLayoutAppContent: FC<AppContentProps> = ({
	apollo,
	component: Component,
	pageProps,
}) => {
	return (
		<ApolloProvider client={apollo}>
			<Component {...pageProps} />
		</ApolloProvider>
	)
}

const AuthorizedAppContent = AppContent
const AuthorizedNoLayoutAppContent = NoLayoutAppContent

class MyApp extends App {
	componentDidMount() {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			;(jssStyles.parentElement as any).removeChild(jssStyles)
		}
	}

	static async getInitialProps({ Component, ctx }: any) {
		let pageProps = {}
		const isAuthenticated = await authCheck(ctx)

		if (isAuthenticated && Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx)
		}

		return { pageProps }
	}

	render() {
		const { Component, pageProps, apollo }: any = this.props
		let layout = null

		if (Component.hasOwnProperty('layout')) {
			layout = Component['layout']
		}

		if (layout) {
			if (layout === 'errorPage') {
				return (
					<>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<Component {...pageProps} />
							</ThemeProvider>
						</StyledEngineProvider>
					</>
				)
			}

			if (layout === 'healthCheck') {
				return <Component {...pageProps} />
			}

			if (layout === 'empty') {
				return (
					<AuthorizedNoLayoutAppContent
						apollo={apollo}
						component={Component}
						pageProps={pageProps}
					/>
				)
			}
		}
		if (layout === 'virtualShelfHeadless') {
			return (
				<>
					<Head>
						<title>Agitron Dashboard</title>
						<meta
							name="viewport"
							content="minimum-scale=1, initial-scale=1, width=device-width"
						/>
						<link
							href="/fonts/agitronfonts/style.css"
							rel="stylesheet"
						/>
					</Head>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={shelfTheme}>
							<LocaleProvider>
								<ApolloProvider client={apollo}>
									<CustomDialogProvider>
										<AlertsProvider>
											<Component {...pageProps} />
										</AlertsProvider>
									</CustomDialogProvider>
								</ApolloProvider>
							</LocaleProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</>
			)
		}

		if (layout === 'loginPage')
			return (
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<LocaleProvider>
							<ApolloProvider client={apollo}>
								<CustomDialogProvider>
									<AlertsProvider>
										<Component {...pageProps} />
									</AlertsProvider>
								</CustomDialogProvider>
							</ApolloProvider>
						</LocaleProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			)

		return (
			<AuthorizedAppContent
				apollo={apollo}
				component={Component}
				pageProps={pageProps}
			/>
		)
	}
}

export default withApollo(({ ctx }) =>
	process.env.NODE_ENV == 'development'
		? createApolloDevelopment({}, ctx, {})
		: createApolloClient({}, ctx, {
				getToken: () => getCookie(ctx),
		  })
)(MyApp)
