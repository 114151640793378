import { KeyValueObject } from '@app/@types'

export enum CustomDialogType {
	error = 'error',
	info = 'info',
	success = 'success',
	warning = 'warning',
}

export enum CustomDialogButtonType {
	cancel = 'cancel',
	ok = 'ok',
}

export interface CustomDialogButton {
	buttonType: CustomDialogButtonType
	textTranslate: string
}

export interface CustomDialogOptions extends CustomDialogCallbackOptions {
	id?: string
	buttons?: CustomDialogButton[]
	dialogType?: CustomDialogType
	headerTextTranslate?: string
	headerTranslateVariables?: KeyValueObject
	messageTextTranslate?: string
	messageTranslateVariables?: KeyValueObject
}

export interface CustomDialogCallbackOptions {
	onConfirm?: () => void
	onClose?: () => void
}

export interface CustomDialogContextState {
	dialogs: CustomDialogOptions[]
	defaultAutoHideAfterOk: boolean
	addDialog: (dialog: CustomDialogOptions) => string
	closeDialog: (dialogId?: string) => void
}

export interface useCustomDialogInterface {
	(): {
		addDialog: (dialog: CustomDialogOptions) => string
		addDeleteConfirmationDialog: (
			entityTitle: string,
			dialog: CustomDialogOptions
		) => string
		addErrorDialog: (errorDialog: CustomDialogOptions) => string
		addInfoDialog: (infoDialog: CustomDialogOptions) => string
		addSuccessDialog: (successDialog: CustomDialogOptions) => string
		addWarningDialog: (warningDialog: CustomDialogOptions) => string
		closeDialog: (dialogId?: string) => void
		addLeavePageConfirmationDialog: (dialog: CustomDialogOptions) => string
	}
}
