import { useTranslation } from '@app/lib/lang'
import TextField from '@mui/material/TextField'
import React, { FC, ReactNode } from 'react'

export interface CustomStandardTextProps {
	onBlur?: any
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	classes?: {
		adornedStart?: string
		root?: string
		input?: string
	}
	className?: string
	disabled?: boolean
	value?: string
	id?: string
	name?: string
	placeholder?: string
	readOnly?: boolean
	type?: 'text' | 'ip' | 'number'
	endAdornment?: ReactNode
	startAdornment?: ReactNode
}

export const CustomStandardTextField: FC<CustomStandardTextProps> = ({
	className,
	classes,
	disabled,
	placeholder,
	value = '',
	...otherProps
}) => {
	const { t } = useTranslation()

	if (placeholder) {
		placeholder = t(placeholder)
	}

	return (
		<TextField
			{...otherProps}
			autoComplete="off"
			classes={{ root: classes?.input }}
			disabled={disabled}
			placeholder={placeholder}
			value={value}
		/>
	)
}
