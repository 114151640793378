import React, { FC } from 'react'
import { useField } from 'formik'

import {
	CustomStandardTextField,
	CustomStandardTextProps,
} from '../CustomStandardTextField'
import { useCustomForm } from './hooks'

interface CustomFormTextFieldProps extends CustomStandardTextProps {
	name: string
	style?: any
}

export const CustomFormStandardTextField: FC<CustomFormTextFieldProps> = ({
	disabled: disabledFromProps,
	name,
	onChange,
	onBlur,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const [field] = useField({
		name,
	})

	const onTextChange = (e: React.ChangeEvent<any>) => {
		field.onChange(e)

		if (onChange) {
			onChange(e)
		}
	}

	return (
		<CustomStandardTextField
			{...otherProps}
			disabled={disabled || disabledFromProps}
			name={name}
			onBlur={field.onBlur}
			onChange={onTextChange}
			value={field.value || ''}
		/>
	)
}
