import { Box } from '@mui/material'
import { CustomDivider } from './CustomDivider'
import { BoxProps } from '@mui/system'
import { themeColors } from '@app/constants'
import theme from '@app/lib/materialui/theme'

interface HeaderSectionProps extends BoxProps {
	hideBottomDivider?: boolean
	noBottomPadding?: boolean
}

export function HeaderSection(props: HeaderSectionProps) {
	const {
		children,
		hideBottomDivider = false,
		noBottomPadding = true,
		...boxProps
	} = props

	return (
		<Box
			sx={{
				backgroundColor: themeColors.white,
			}}
			{...boxProps}
		>
			{
				<Box
					sx={{
						paddingLeft: theme.spacing(3),
						paddingRight: theme.spacing(3),
						paddingTop: theme.spacing(2.5),
						paddingBottom: theme.spacing(2.5),
					}}
				>
					{children}
				</Box>
			}

			{!hideBottomDivider && <CustomDivider mb={0} mt={0} />}
		</Box>
	)
}
