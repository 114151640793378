import merge from 'deepmerge'
import { v4 as uuidv4, validate } from 'uuid'

import { KeyValueObject } from '@app/@types'
import { ValueEntities } from '@app/@types/shelf'

export function combineLabels(...labels: (string | null | undefined)[]) {
	if (!labels?.length) {
		return ''
	}

	return labels.filter((q) => q).join(' - ')
}

export function copy<T>(obj: T) {
	return merge<T>({}, obj)
}

export const createGuid = () => {
	return uuidv4()
}

export const createId = () => {
	return Math.floor(Math.random() * 1000)
}

export const isGuid = (value: string) => validate(value)

export const getDotNotationPropertyLast = (dotNotationProperty: string) => {
	if (dotNotationProperty) {
		const splittedDotNotationProperty = dotNotationProperty.split('.')

		return splittedDotNotationProperty[
			splittedDotNotationProperty.length - 1
		]
	}

	return ''
}

export const getFirstPropertyFromObject = <T = any>(value: any) => {
	if (value) {
		const keys = Object.keys(value)

		if (keys.length) {
			return value[keys[0]] as T
		}
	}

	return value as T
}

export const getDotNotationPropertyValue = <TPropertyValue = any>(
	value: any,
	dotNotationProperty?: string
) => {
	if (value && isObject(value) && isString(dotNotationProperty)) {
		const splittedDotNotationProperty = dotNotationProperty!.split('.')

		while (splittedDotNotationProperty.length && value) {
			const currentProperty = splittedDotNotationProperty.shift()

			if (isArray(value) && currentProperty === 'templateKey') {
				const templateKey = splittedDotNotationProperty.shift()

				const valueEntity = value.find(
					(obj: ValueEntities) => obj.templateKey === templateKey
				)

				if (valueEntity) {
					return (value = valueEntity.value)
				}
			}

			if (isArray(value) && currentProperty) {
				value = (value as KeyValueObject[]).map((q) =>
					getDotNotationPropertyValue(q, currentProperty!)
				)

				break
			} else {
				value = value[currentProperty!]
			}
		}
	}

	return value as TPropertyValue
}

export const isArray = (array: any) => {
	return Array.isArray(array) || array instanceof Array
}

export const isBase64 = (base64: string) => {
	return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
		base64
	)
}

export const isNumber = (value: any) =>
	!isNaN(parseFloat(value)) && isFinite(value)

export const isString = (value: any) => {
	return typeof value === 'string'
}

export const isObject = (value: any) => {
	return typeof value === 'object' && value !== null
}

export const isEmptyObject = (value: any) => {
	return Object.keys(value).length === 0 && value.constructor === Object
}

export const isSSR = () => typeof window === 'undefined'

export function findLastIndex<T = any>(
	array: T[],
	callback: (arrayItem: T) => boolean
) {
	if (array?.length) {
		for (let i = array.length - 1; i >= 0; i--) {
			if (callback(array[i])) {
				return i
			}
		}
	}

	return -1
}

export const objectToDotNotation = (object: any, prefix = '') => {
	return Object.keys(object).reduce((messages, key) => {
		let value = object[key]
		let prefixedKey = prefix ? `${prefix}.${key}` : key

		if (isString(value) || !value) {
			messages[prefixedKey] = value
		} else {
			Object.assign(messages, objectToDotNotation(value, prefixedKey))
		}

		return messages
	}, {} as KeyValueObject)
}

export const valueEntitiesToObject = (valueEntities: ValueEntities[]) => {
	const initialValueEntities = valueEntities?.reduce((acc, item) => {
		acc[item.templateKey] = item.value
		return acc
	}, {} as { [key: string]: any })

	return initialValueEntities
}

export const getValueEntityValue = (
	valueEntities: ValueEntities[] | any,
	templateKey: string
) => {
	const obj = valueEntities?.find(
		(obj: any) => obj.templateKey === templateKey
	)

	const value = obj?.value

	if (/^\d+$/.test(value)) {
		return value
	} else {
		return value || ''
	}
}
