import { FC, ReactNode } from 'react'
import { EPaperSizeType } from '../../constants/ePaperSizes'
import { frameLoader } from './frameLoader'
import FrameSmall from './FrameSmall'
import FrameLarge from './FrameLarge'
import FrameScale from './FrameScale'
import { Box } from '@mui/material'
import { bigNoTemplate } from './bigNoTemplate'
import { smallNoTemplate } from './smallNoTemplate'
import { getBase64ImageUrl } from '@app/lib/imageUtility'
import { DeviceType } from '@app/lib/graphql'

interface FramePreviewProps {
	image?: string | null
	loading: boolean
	paperSizeType: EPaperSizeType
	deviceType?: DeviceType | null
}

export const FramePreview: FC<FramePreviewProps> = ({
	image,
	loading,
	paperSizeType,
	deviceType,
}) => {
	const isSmall = paperSizeType.startsWith('A290')
	const isLarge = paperSizeType.startsWith('A430')

	const noTemplate = isLarge ? bigNoTemplate : smallNoTemplate

	let frameImage: string

	if (loading) {
		frameImage = frameLoader
	} else if (!image) {
		frameImage = noTemplate
	} else {
		frameImage = getBase64ImageUrl(image)
	}

	if (deviceType === DeviceType.SmartScale) {
		return (
			<CustomBox>
				<FrameScale image={frameImage} width="100%" />
			</CustomBox>
		)
	} else if (isSmall) {
		return (
			<CustomBox>
				<FrameSmall image={frameImage} width="100%" />
			</CustomBox>
		)
	} else if (isLarge) {
		return (
			<CustomBox>
				<FrameLarge image={frameImage} width="100%" />
			</CustomBox>
		)
	} else {
		return (
			<CustomBox>
				<FrameSmall image={frameImage} width="100%" />
			</CustomBox>
		)
	}
}

function CustomBox({ children }: { children: ReactNode }) {
	return (
		<Box
			sx={{
				position: 'relative',
				zIndex: 1,
				width: '100%',
			}}
		>
			{children}
		</Box>
	)
}
