import { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from '@app/lib/lang'
import { isArray } from '@app/lib/utils'
import { CustomTextProps } from '../CustomTextField'

const useStyles = makeStyles((theme) =>
	createStyles({
		validationMessage: {
			color: theme.palette.error.main,
		},
	})
)

const emailRegex =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export interface CustomFormGroupProps extends Pick<CustomTextProps, 'type'> {
	name: string
	label?: string
	fieldTranslatePrefix?: string
	required?: boolean
	validateFunction?: () => void
}

export const CustomFormGroup: FC<CustomFormGroupProps> = ({
	children,
	label,
	name,
	fieldTranslatePrefix,
	required,
	type,
	validateFunction,
}) => {
	const [, meta] = useField({
		name,
		required,
		validate: (value) => {
			if (validateFunction) {
				const error = validateFunction()
				return error
			}

			if (value && type === 'email' && !emailRegex.test(value)) {
				return 'email'
			}

			if (
				required &&
				(value === undefined ||
					value === null ||
					value === '' ||
					(isArray(value) && !(value as any[]).length))
			) {
				return 'required'
			}

			return
		},
	})
	const { submitCount } = useFormikContext()
	const { t } = useTranslation()

	const { validationMessage } = useStyles()

	let errorMessage = ''
	let labelTranslate: string

	if (label !== undefined) {
		labelTranslate = label
	} else {
		labelTranslate = fieldTranslatePrefix
			? `${fieldTranslatePrefix}.${name}`
			: name
	}

	if (meta.error && (meta.touched || submitCount > 0)) {
		errorMessage = t(`shared.validation.${meta.error}`, {
			field: t(labelTranslate),
		})
	}

	return (
		<>
			{children}
			{errorMessage && (
				<Box className={validationMessage} mt={1}>
					{errorMessage}
				</Box>
			)}
		</>
	)
}
