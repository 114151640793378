import { FC } from 'react'

interface ConditonalWrapperProps {
	condition?: boolean
	children: React.ReactElement
	wrapper: (children: React.ReactElement) => JSX.Element
}

export const ConditionalWrapper: FC<ConditonalWrapperProps> = ({
	condition,
	wrapper,
	children,
}) => (condition ? wrapper(children) : children)
