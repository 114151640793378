import { themeColors } from '@app/constants'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let shelfTheme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: { light: '#17aad8', main: '#1d65a9', dark: '#1b2c36' },
			secondary: { light: '#f8f8f8', main: '#cbd3d5' },
			warning: {
				main: '#ffea03',
			},
			background: {
				default: themeColors.backgroundColor,
			},
		},

		typography: {
			fontSize: 12,
			fontFamily: 'klavika-light',
			button: {
				textTransform: 'none',
			},
			subtitle1: {
				color: '#4a4a4a',
				fontSize: '10px',
				textTransform: 'uppercase',
			},
			h2: {
				fontSize: 150,
				fontWeight: 300,
				letterSpacing: 2,
			},

			h3: {
				fontSize: 120,
				fontWeight: 300,
				letterSpacing: 2,
			},
			h4: {
				fontWeight: 300,
				letterSpacing: 1.25,
				fontSize: 75,
			},
			h5: {
				fontWeight: 300,
				fontSize: 50,
			},
		},
		components: {
			MuiTab: {
				styleOverrides: {
					labelIcon: {
						minHeight: 0,
					},
					wrapped: {
						flexDirection: 'row',
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						minWidth: 40,
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						'&$selected': {
							backgroundColor: 'transparent',
							borderLeft: '5px solid white',
						},
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: '1em',
					},
				},
			},
		},
	})
)

export default shelfTheme
