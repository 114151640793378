import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { useDebounce } from 'use-debounce'
import { CustomForm, CustomFormProps } from './CustomForm'

import { useUpdateEffect } from '@app/lib/hooks'

interface CustomAutoSubmitFormProps {
	debounce?: number
}

const CustomAutoSubmitInnerForm: FC<CustomAutoSubmitFormProps> = ({
	children,
	debounce = 300,
}) => {
	const { values, submitForm } = useFormikContext()
	const [debouncedValues] = useDebounce(values, debounce)

	useUpdateEffect(() => {
		submitForm()
	}, [debouncedValues])

	return <>{children}</>
}

export const CustomAutoSubmitForm: FC<CustomFormProps> = ({
	children,
	...otherProps
}) => (
	<CustomForm {...otherProps}>
		<CustomAutoSubmitInnerForm>{children}</CustomAutoSubmitInnerForm>
	</CustomForm>
)
