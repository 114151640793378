import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'
import { TranslateMessage } from '@app/lib/lang'
import { themeColors } from '@app/constants'

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			height: '100%',
		},
		icon: {
			marginBottom: theme.spacing(2),
		},
		text: {
			color: themeColors.pinkishGrey,
		},
	})
)

export const ModuleDisabled: FC = () => {
	const classes = useStyles()

	return (
		<Box className={classes.container}>
			<img className={classes.icon} src="/icons/lift-off.svg" />
			<Typography className={classes.text}>
				<TranslateMessage id="shared.moduleDisabled.headerFirstLine" />
			</Typography>
			<Typography className={classes.text}>
				<TranslateMessage id="shared.moduleDisabled.headerSecondLine" />
			</Typography>
		</Box>
	)
}
