import Typography, { TypographyProps } from '@mui/material/Typography'
import React, { FC } from 'react'

interface DurationTextProps extends TypographyProps {
	durationInSeconds: number
}

export const DurationText: FC<DurationTextProps> = ({
	durationInSeconds,
	...otherProps
}) => {
	const durationText = new Date(durationInSeconds * 1000)
		.toISOString()
		.substr(11, 8)

	return <Typography {...otherProps}>{durationText}</Typography>
}
