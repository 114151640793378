import { CustomDivider } from '@app/components'
import { RouteItem } from '@app/constants'
import { usePermissions } from '@app/lib/packages/permissions'
import List from '@mui/material/List'
import React, { FC, Fragment } from 'react'
import { GroupedSidebarItem } from './GroupedSidebarItem'
import { SidebarItem } from './SidebarItem'

interface SiderbarExtensionProps {
	menuItem: RouteItem
}

export const SiderbarExtension: FC<SiderbarExtensionProps> = ({ menuItem }) => {
	const { hasRoutePermission } = usePermissions()
	const filteredMenuItems = menuItem.subs?.filter((q) =>
		hasRoutePermission(q)
	)

	if (!filteredMenuItems) {
		return null
	}

	return (
		<List>
			{filteredMenuItems.map((subMenuItem, i) =>
				subMenuItem.subs?.length ? (
					<Fragment key={subMenuItem.id}>
						{i !== 0 && <CustomDivider />}
						<GroupedSidebarItem menuItem={subMenuItem} />
					</Fragment>
				) : (
					<SidebarItem key={subMenuItem.id} menuItem={subMenuItem} />
				)
			)}
		</List>
	)
}
