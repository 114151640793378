import { themeColors } from '@app/constants'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				light: themeColors.agitronLight,
				dark: themeColors.agitronDark,
				main: themeColors.agitronLight,
			},
			secondary: {
				light: themeColors.light,
				dark: themeColors.dark,
				main: themeColors.dark,
			},
			warning: {
				main: themeColors.yellow,
			},
			success: {
				main: themeColors.green,
			},
			background: {
				default: themeColors.backgroundColor,
			},
			info: {
				// main: themeColors.dark,
				main: themeColors.dark,
			},
		},
		typography: {
			fontSize: 12,
			button: {
				textTransform: 'none',
			},
			subtitle1: {
				color: '#4a4a4a',
				fontSize: '10px',
				textTransform: 'uppercase',
			},
			h3: {
				fontSize: 30,
				fontWeight: 300,
				letterSpacing: '0.5px',
			},
			h4: {
				fontWeight: 300,
				letterSpacing: '0.5px',
			},
		},
		components: {
			MuiTab: {
				styleOverrides: {
					labelIcon: {
						minHeight: 0,
					},
					wrapped: {
						flexDirection: 'row',
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						minWidth: 40,
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						'&$selected': {
							backgroundColor: 'transparent',
							borderLeft: '5px solid white',
						},
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: '1em',
					},
				},
			},
		},
		breakpoints: {
			values: {
				xs: 0, // default is 0
				sm: 600, // default, change if needed
				md: 1000, // default, change if you want to adjust md breakpoint
				lg: 1280, // default, change if needed
				xl: 1600, // default, change if needed
			},
		},
	})
)

export default theme
