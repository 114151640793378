import {
	getDaysInMonth,
	getHours,
	getMinutes,
	getSeconds,
	isSameDay,
	isSameMonth,
	isSameYear,
	set,
	setDate,
} from 'date-fns'
import { WeekDay } from './graphql'

export const MIN_DATE = new Date('0001-01-01T00:00:00Z')
export const MAX_DATE = new Date(8640000000000000)
const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/
const iso8601Extended =
	/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/

export function convertDaysToSeconds(numberOfDays: number) {
	return numberOfDays * 24 * 60 * 60
}

export function convertDaysToMiliseconds(numberOfDays: number) {
	return convertDaysToSeconds(numberOfDays) * 1000
}

export function fromTimestamp(timestampInSeconds: number) {
	return new Date(timestampInSeconds * 1000)
}

export function getHalfMonthDay(date: Date) {
	const numberOfDaysInMonth = getDaysInMonth(date)
	const half = Math.ceil(numberOfDaysInMonth / 2)

	return setDate(date, half)
}

export function getTotalSecondsOfCurrentTime(date: Date) {
	return getHours(date) * 3600 + getMinutes(date) * 60 + getSeconds(date)
}

export function isIso8601(value: string) {
	return iso8601.test(value) || iso8601Extended.test(value)
}

export function isSameDate(dateLeft: Date | number, dateRight: Date | number) {
	return (
		isSameDay(dateLeft, dateRight) &&
		isSameMonth(dateLeft, dateRight) &&
		isSameYear(dateLeft, dateRight)
	)
}

export function fromTotalSecondsToDate(totalSeconds: number) {
	if (!totalSeconds) {
		totalSeconds = 0
	}

	const date = new Date()

	const seconds = totalSeconds % 60
	const hours = Math.floor(totalSeconds / 3600)
	const minutes = (totalSeconds - hours * 3600) / 60

	const newDate = set(date, {
		hours,
		minutes,
		seconds,
	})

	return newDate
}

export function setDateToStartOfDay(date: Date) {
	return set(date, {
		hours: 0,
		minutes: 0,
		seconds: 0,
	})
}

export function setDateToEndOfDay(date: Date) {
	return set(date, {
		hours: 23,
		minutes: 59,
		seconds: 59,
	})
}

export const getDayName = (dayIndex: number) => {
	if (dayIndex === 1) {
		return WeekDay.Monday
	} else if (dayIndex === 2) {
		return WeekDay.Tuesday
	} else if (dayIndex === 3) {
		return WeekDay.Wednesday
	} else if (dayIndex === 4) {
		return WeekDay.Thursday
	} else if (dayIndex === 5) {
		return WeekDay.Friday
	} else if (dayIndex === 6) {
		return WeekDay.Saturday
	}

	return WeekDay.Sunday
}
