import classNames from 'classnames'
import React, { FC } from 'react'
import Link from 'next/link'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { themeColors } from '@app/constants'
import { CustomButton } from './CustomButton'
import { lighten } from '@mui/system'
import { Action_Type, Module_Type } from '@app/lib/graphql'
import { PermissionCheck } from '@app/lib/packages'

const useStyles = makeStyles((theme) =>
	createStyles({
		deleteButton: {
			backgroundColor: themeColors.vermillion,
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: lighten(themeColors.vermillion, 0.12),
			},
		},
	})
)

export interface DeleteButtonProps {
	className?: string
	disabled?: boolean
	link?: string
	loading?: boolean
	size?: 'small' | 'medium' | 'large'
	onClick?: () => void
	moduleType: Module_Type
}

export const DeleteButton: FC<DeleteButtonProps> = ({
	className,
	children,
	disabled = false,
	link,
	loading = false,
	size = 'large',
	onClick,
	moduleType,
}) => {
	const classes = useStyles()

	const onDeleteButtonClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<PermissionCheck
			moduleType={moduleType}
			actionType={Action_Type.Delete}
		>
			<ConditionalWrapper
				condition={!!link}
				wrapper={(children) => <Link href={link!}>{children}</Link>}
			>
				<CustomButton
					color="error"
					disabled={disabled}
					loading={loading}
					className={classNames([classes.deleteButton, className])}
					onClick={onDeleteButtonClick}
					type="button"
					size={size}
				>
					{children}
				</CustomButton>
			</ConditionalWrapper>
		</PermissionCheck>
	)
}
