import classnames from 'classnames'
import React, { FC, useState } from 'react'
import { SelectOption } from '@app/@types'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import { TranslateMessage } from '@app/lib/lang'
import Grid from '@mui/material/Grid'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			borderColor: theme.palette.secondary.dark,
			borderRadius: theme.spacing(0.5),
			borderStyle: 'solid',
			borderWidth: '1px',
			padding: '1px',
		},
		button: {
			borderRadius: theme.spacing(0.5),
			paddingLeft: theme.spacing(3),
			color: 'black',
			fontSize: theme.spacing(1.3),
			fontWeight: 'bold',
			paddingRight: theme.spacing(3),
			textTransform: 'uppercase',
			width: '100%',
		},
		buttonSelected: {
			backgroundColor: theme.palette.secondary.dark,
			color: 'white',
		},
	})
)

interface CustomToggleGroupProps {
	defaultValue?: string
	values: SelectOption[]
	onChange?: (selectedOption: SelectOption) => void
}

export const CustomToggleGroup: FC<CustomToggleGroupProps> = ({
	defaultValue,
	onChange,
	values,
}) => {
	const [selectedValue, setSelectedValue] = useState(defaultValue)

	const classes = useStyles()

	const onButtonClick = (optionValue: SelectOption) => () => {
		setSelectedValue(optionValue.value)

		if (onChange) {
			onChange(optionValue)
		}
	}

	const calculateGridSize = (): any => {
		return 12 / values.length
	}

	return (
		<Grid className={classes.root} container>
			{values.map((q, i) => (
				<Grid item xs={calculateGridSize()} key={i}>
					<Button
						color="primary"
						className={classnames(classes.button, {
							[classes.buttonSelected]: q.value === selectedValue,
						})}
						variant={
							q.value === selectedValue ? 'contained' : 'text'
						}
						onClick={onButtonClick(q)}
					>
						<TranslateMessage id={q.label} />
					</Button>
				</Grid>
			))}
		</Grid>
	)
}
