import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import Checkbox from '@mui/material/Checkbox'

const useStyles = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		// borderRadius: 2,
		width: 20,
		height: 20,
		// border: 'solid 1px var(--lines)',
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
		backgroundColor: 'white',
		// backgroundImage:
		// 	'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		// 'input:hover ~ &': {
		// 	backgroundColor: '#ebf1f5',
		// },
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#1d65a9',
		// backgroundImage:
		// 	'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 20,
			height: 20,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		// 'input:hover ~ &': {
		// 	backgroundColor: '#17aad8',
		// },
	},
})

// Inspired by blueprintjs
const StyledCheckbox = (props: any) => {
	const classes = useStyles()

	return (
		<Checkbox
			className={classes.root}
			disableRipple
			color="default"
			checkedIcon={
				<span className={clsx(classes.icon, classes.checkedIcon)} />
			}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			onChange={props.onChange}
			checked={props.checked || false}
			disabled={props.disabled}
			{...props}
		/>
	)
}

export default StyledCheckbox
