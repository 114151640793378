import { Module_Type } from '@app/lib/graphql'

export interface RouteItem {
	id: string
	icon?: string
	label?: string
	moduleType?: Module_Type | Module_Type[]
	route?: string
	to?: string
	subs?: RouteItem[]
	disabled?: boolean
}

export const isMenuItemInPathname = (
	menuItem: RouteItem,
	pathname: string,
	recursive = true
): boolean => {
	if (menuItem.to === pathname) {
		return true
	}

	if (recursive) {
		if (menuItem.subs?.length) {
			return menuItem.subs.some((q) =>
				isMenuItemInPathname(q, pathname, recursive)
			)
		}

		const splittedPathName = pathname.split('/')

		while (splittedPathName.length) {
			if (splittedPathName.join('/') === menuItem.to) {
				return true
			}

			splittedPathName.pop()
		}
	}

	return false
}

const proccessMenuItems = (menuItems?: RouteItem[]): RouteItem[] =>
	(menuItems || []).map((q) => {
		let label = q.label
		if (!q.label) {
			label = `menu.${q.id}`

			if (q.subs?.length) {
				label += '.label'
			}
		}
		return {
			...q,
			label,
			to:
				q.to ||
				(q.subs?.length ? undefined : `/${q.id.split('.').join('/')}`),
			subs: proccessMenuItems(q.subs),
		}
	})

const menuItems: RouteItem[] = [
	{
		id: 'app',
		icon: 'svg health',
		label: 'menu.dashboard',
		to: '/',
		route: 'app',
		subs: [],
	},

	// {
	// 	id: 'analitycs',
	// 	icon: 'svg analitycs',
	// 	subs: [
	// 		{
	// 			id: 'analitycs.overview',
	// 			icon: 'svg overview',
	// 		},
	// 		{
	// 			id: 'analitycs.reports',
	// 			icon: 'svg reports',
	// 		},
	// 		{
	// 			id: 'analitycs.pathTracking',
	// 			subs: [
	// 				{
	// 					id: 'analitycs.pathTracking.individualPath',
	// 					icon: 'svg individual',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.retentionShare',
	// 					icon: 'svg retentionShare',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.retentionTime',
	// 					icon: 'svg retentionTime',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.heatmap',
	// 					icon: 'svg heatmap',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.deadzones',
	// 					icon: 'svg deadzones',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.storeWaitTimes',
	// 					icon: 'svg waitTimes',
	// 				},
	// 				{
	// 					id: 'analitycs.pathTracking.microLocationValue',
	// 					icon: 'svg locationValue',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'analitycs.audience',
	// 			subs: [
	// 				{
	// 					id: 'analitycs.audience.peopleCounting',
	// 					icon: 'svg counting',
	// 				},
	// 				{
	// 					id: 'analitycs.audience.recurringPeople',
	// 					icon: 'svg recurringPeople',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'analitycs.trafficSources',
	// 			subs: [
	// 				{
	// 					id: 'analitycs.trafficSources.contentCodes',
	// 					icon: 'svg contentCodes',
	// 				},
	// 				{
	// 					id: 'analitycs.trafficSources.retargeting',
	// 					icon: 'svg retargeting',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	{
		id: 'advertising',
		icon: 'Advertising',
		moduleType: Module_Type.Signage,
		subs: [
			{
				id: 'advertising.overview',
				icon: 'svg overview',
				disabled: true,
			},
			{
				id: 'advertising.reports',
				icon: 'svg reports',
				disabled: true,
			},

			// {
			// 	id: 'advertising.proximity',
			// 	subs: [
			// 		{
			// 			id: 'advertising.proximity.locations',
			// 			icon: 'svg place',
			// 		},
			// 		{
			// 			id: 'advertising.proximity.beacons',
			// 			icon: 'svg beacons',
			// 		},
			// 		{
			// 			id: 'advertising.proximity.geofences',
			// 			icon: 'svg geofences',
			// 		},
			// 	],
			// },

			// {
			// 	id: 'advertising.content',
			// 	subs: [
			// 		{
			// 			id: 'advertising.content.landingPages',
			// 			icon: 'svg landingPages',
			// 		},
			// 		{
			// 			id: 'advertising.content.experiences',
			// 			icon: 'svg experiences',
			// 		},
			// 	],
			// },

			// {
			// 	id: 'advertising.messages',
			// 	subs: [
			// 		{
			// 			id: 'advertising.messages.proximity',
			// 			icon: 'svg proximity',
			// 		},
			// 		{
			// 			id: 'advertising.messages.scheduled',
			// 			icon: 'svg schedule',
			// 		},
			// 	],
			// },

			{
				id: 'advertising.signage',
				moduleType: Module_Type.Signage,
				subs: [
					// {
					// 	id: 'advertising.signage.devices',
					// 	moduleType: Module_Type.Signage,
					// 	icon: 'svg shelf-bar',
					// },
					{
						id: 'advertising.signage.channels',
						moduleType: Module_Type.Signage,
						icon: 'svg channels',
					},
					{
						id: 'advertising.signage.playlists',
						moduleType: Module_Type.Signage,
						icon: 'svg signage',
					},
				],
			},
		],
	},
	{
		id: 'virtualShelf',
		icon: 'Virtual-Shelf',
		moduleType: Module_Type.VirtualStore,
		subs: [
			{
				id: 'virtualShelf.overview',
				icon: 'svg overview',
				moduleType: Module_Type.VirtualStore,
				disabled: true,
			},
			{
				id: 'virtualShelf.reports',
				icon: 'svg reports',
				moduleType: Module_Type.VirtualStore,
				disabled: true,
			},
			{
				id: 'virtualShelf.screens',
				moduleType: Module_Type.VirtualStore,
				label: 'menu.virtualShelf.screens',
				subs: [
					{
						id: 'virtualShelf.stores',
						to: '/virtualShelf/stores',
						icon: 'svg designShop',
						moduleType: Module_Type.VirtualStore,
					},
					{
						id: 'virtualShelf.videoWall',
						to: '/virtualShelf/multiScreen',
						icon: 'svg videoWall',
						moduleType: Module_Type.VirtualStore,
					},
					// {
					// 	id: 'virtualShelf.orders',
					// 	to: '/virtualShelf/orders',
					// 	icon: 'svg overview',
					// 	moduleType: Module_Type.VirtualStore,
					// },
				],
			},
			{
				id: 'virtualShelf.virtualComponents',
				moduleType: Module_Type.VirtualStore,
				label: 'menu.virtualShelf.virtualComponents',
				subs: [
					{
						id: 'virtualShelf.shelves',
						to: '/virtualShelf/fillShelf',
						icon: 'svg fillShelf',
						moduleType: Module_Type.VirtualStore,
					},
					{
						id: 'virtualShelf.racks',
						to: '/virtualShelf/setupRack',
						icon: 'svg setupRack',
						moduleType: Module_Type.VirtualStore,
					},
					{
						id: 'virtualShelf.categories',
						to: '/virtualShelf/setupCategory',
						icon: 'svg virtualCategories',
						moduleType: Module_Type.VirtualStore,
					},
					{
						id: 'virtualShelf.productEditor',
						to: '/virtualShelf/productEditor',
						icon: 'svg productEditor',
						moduleType: Module_Type.VirtualStore,
					},
				],
			},
		],
	},
	{
		id: 'warehouse',
		icon: 'Warehouse',
		moduleType: Module_Type.Warehouse,
		subs: [
			{
				id: 'warehouse.overview',
				icon: 'svg overview',
				moduleType: Module_Type.Warehouse,
				disabled: true,
			},
			{
				id: 'warehouse.reports',
				icon: 'svg reports',
				moduleType: Module_Type.Warehouse,
			},
			{
				id: 'warehouse.warehouse',
				moduleType: Module_Type.Warehouse,
				subs: [
					{
						id: 'warehouse.warehouse.stock',
						icon: 'Stock',
						to: '/warehouse/stock',
						moduleType: Module_Type.Warehouse,
					},
					{
						id: 'warehouse.warehouse.products',
						icon: 'Products',
						to: '/warehouse/products',
						route: 'products',
						moduleType: Module_Type.Warehouse,
					},
				],
			},

			{
				id: 'warehouse.suppliers',
				subs: [
					{
						id: 'warehouse.suppliers.label',
						to: '/warehouse/suppliers',
						icon: 'svg supplier',
						moduleType: Module_Type.Warehouse,
					},
				],
			},
		],
	},
	{
		id: 'orders',
		icon: 'Orders',
		moduleType: Module_Type.Order,
		subs: [
			{
				id: 'orders.overview',
				icon: 'svg overview',
				disabled: true,
			},
			{
				id: 'orders.reports',
				icon: 'svg reports',
				disabled: true,
			},
			{
				id: 'orders.orders',
				subs: [
					{
						id: 'orders.orders.virtualStore',
						icon: 'virtual_store_orders',
						to: '/orders/virtual-shelf',
						route: 'products',
						moduleType: Module_Type.VirtualStore,
						disabled: true,
					},
					{
						id: 'orders.orders.warehouse',
						icon: 'warehouse_orders',
						to: '/orders/warehouse',
						moduleType: Module_Type.Warehouse,
					},
				],
			},

			{
				id: 'orders.archive',
				subs: [
					{
						id: 'orders.orders.virtualStore',
						icon: 'archive',
						to: '/orders/archive/virtual-shelf',
						route: 'products',
						moduleType: Module_Type.VirtualStore,
						disabled: true,
					},
					{
						id: 'orders.orders.warehouse',
						icon: 'archive',
						to: '/orders/archive/warehouse',
						moduleType: Module_Type.Warehouse,
					},
				],
			},
		],
	},
	// {
	// 	id: 'flow',
	// 	icon: 'svg flow',
	// 	label: 'menu.flows',
	// 	moduleType: Module_Type.Flow,
	// 	route: 'flow',
	// 	subs: [
	// 		{
	// 			id: 'flow.overview',
	// 			icon: 'svg overview',
	// 			label: 'menu.flowOverview',
	// 			moduleType: Module_Type.Flow,
	// 			to: '/flow/overview',
	// 			route: 'flowOverview',
	// 		},
	// 		{
	// 			id: 'flow.automation',
	// 			icon: 'svg flowAutomation',
	// 			label: 'menu.flowAutomation',
	// 			moduleType: Module_Type.Flow,
	// 			to: '/flow/automation',
	// 			route: 'flowAutomation',
	// 		},
	// 		{
	// 			id: 'flow.runners',
	// 			icon: 'svg flowRunners',
	// 			label: 'menu.flowRunners',
	// 			moduleType: Module_Type.Flow,
	// 			to: '/flow/runners',
	// 			route: 'flowRunners',
	// 		},
	// 	],
	// },
	{
		id: 'smartLabel',
		label: 'menu.iotFleet',
		icon: 'Devices',
		moduleType: [
			Module_Type.SmartLabel,
			Module_Type.Warehouse,
			Module_Type.SmartSensor,
			Module_Type.Bar,
			Module_Type.Signage,
		],
		subs: [
			{
				id: 'smartLabel.overview',
				icon: 'svg overview',
				label: 'menu.overview',
				to: '/devices/overview',
				route: 'overview',
				disabled: true,
			},
			{
				id: 'smartLabel.reports',
				icon: 'svg reports',
				label: 'menu.reports',
				to: '/devices/reports',
				route: 'reports',
				disabled: true,
			},
			{
				id: 'devices1',
				label: 'menu.devices',
				moduleType: [
					Module_Type.SmartLabel,
					Module_Type.Warehouse,
					Module_Type.SmartSensor,
					Module_Type.Bar,
				],
				subs: [
					{
						id: 'smartLabel.smartLabel',
						icon: 'Smart-Label',
						label: 'menu.smartLabel',
						moduleType: Module_Type.SmartLabel,
						to: '/devices/labelList',
						route: 'smartLabel',
					},
					{
						id: 'smartLabel.industrialScale',
						icon: 'Scale',
						label: 'menu.industrialScale',
						moduleType: Module_Type.Warehouse,
						to: '/devices/industrialScale',
					},
					{
						id: 'smartLabel.smartSensorLabel',
						icon: 'Sensor-Label',
						label: 'menu.smartSensorLabel',
						moduleType: Module_Type.SmartSensor,
						to: '/devices/smartSensorLabel',
						route: 'smartSensorLabel',
					},
					{
						id: 'smartLabel.smartShelfBar',
						icon: 'ShelfBar',
						label: 'menu.smartShelfBar',
						moduleType: Module_Type.Bar,
						to: '/devices/smartShelfBar',
					},
					// {
					// 	id: '7Colordisplay',
					// 	icon: 'Smart-Label',
					// 	label: 'Multi Color Display',
					// 	to: '/devices/multiColorDisplay',
					// },
				],
			},
			{
				id: 'devices2',
				label: 'menu.devices',
				moduleType: Module_Type.Signage,
				subs: [
					{
						id: 'smartLabel.smartSignage',
						icon: 'Signage',
						label: 'menu.smartSignage',
						moduleType: Module_Type.Signage,
						to: '/devices/smartSignage',
					},
				],
			},
		],
	},
	{
		id: 'orbis',
		label: 'menu.orbis.label',
		icon: 'Orbis',
		moduleType: Module_Type.Orbis,
		subs: [],
	},
	{
		id: 'dataInput',
		icon: 'svg data-input',
		label: 'menu.dataInput',
		moduleType: [
			Module_Type.Product,
			Module_Type.Planogram,
			Module_Type.Template,
			Module_Type.Signage,
		],
		subs: [
			{
				id: 'dataInput.overview',
				icon: 'svg overview',
				label: 'menu.overview',
				to: '/dataInput/overview',
				disabled: true,
			},
			{
				id: 'dataInput.reports',
				icon: 'svg reports',
				label: 'menu.reports',
				to: '/devices/reports',
				disabled: true,
			},
			...(typeof window !== 'undefined' &&
			window?.location.hostname !== 'visualart.agitron.app'
				? [
						{
							id: 'dataInput.products',
							label: 'menu.products',
							moduleType: Module_Type.Product,
							subs: [
								{
									id: 'dataInput.products',
									icon: 'svg products',
									label: 'menu.products',
									moduleType: Module_Type.Product,
									to: '/dataInput/products',
								},
							],
						},
				  ]
				: []),
			...(typeof window !== 'undefined' &&
			(window?.location.hostname === 'visualart.agitron.app' ||
				window?.location.hostname === 'localhost' ||
				window?.location.hostname === 'staging.agitron.app' ||
				window?.location.hostname === 'staging.agitron.dev')
				? [
						{
							id: 'dataInput.rootProducts',
							label: 'menu.products',
							moduleType: Module_Type.Product,
							subs: [
								{
									id: 'dataInput.rootProducts',
									icon: 'svg products',
									label: 'menu.products',
									moduleType: Module_Type.Product,
									to: '/dataInput/rootProducts',
								},
							],
						},
				  ]
				: []),
			{
				id: 'dataInput.layouts',
				label: 'menu.layouts',
				moduleType: [Module_Type.Planogram, Module_Type.Template],
				subs: [
					{
						id: 'dataInput.dispalyTemplates',
						icon: 'svg templates',
						label: 'menu.labelTemplateEditor',
						moduleType: Module_Type.Template,
						to: '/dataInput/templateEditor',
					},
					{
						id: 'dataInput.planograms',
						icon: 'svg planogram',
						label: 'menu.planograms',
						moduleType: Module_Type.Planogram,
						to: '/dataInput/planograms',
					},
				],
			},
			{
				id: 'dataInput.import',
				label: 'menu.import',
				moduleType: Module_Type.Product,
				subs: [
					{
						id: 'dataInput.manualImport',
						icon: 'svg manualImport',
						label: 'menu.manualImport',
						to: '/dataInput/manualImport',
					},
				],
			},
		],
	},
	{
		id: 'settings',
		icon: 'svg cog',
		subs: [
			// {
			// 	id: 'settings.overview',
			// 	icon: 'svg overview',
			// },
			{
				id: 'settings.documentation',
				icon: 'svg documentation',
			},
			{
				id: 'settings.company.licenses',
				icon: 'svg licenses',
			},
			{
				id: 'settings.access',
				moduleType: Module_Type.User,
				subs: [
					{
						id: 'settings.access.users',
						icon: 'svg user',
						moduleType: Module_Type.User,
						to: '/settings/users',
						route: 'users',
					},
					{
						id: 'settings.access.roles',
						icon: 'svg roles',
						moduleType: Module_Type.User,
						to: '/settings/roles',
						route: 'roles',
					},

					// {
					// 	id: 'settings.access.permissions',
					// 	icon: 'svg permissions',
					// },
				],
			},
			{
				id: 'settings.assetmanagement',
				moduleType: Module_Type.Storage,
				subs: [
					{
						id: 'settings.assetmanagement.files',
						icon: 'svg files',
						moduleType: Module_Type.Storage,
						to: '/settings/assetmanagement/assets',
						route: 'assets',
					},
				],
			},
			{
				id: 'settings.integrations',
				moduleType: Module_Type.DevTools,
				subs: [
					// {
					// 	id: 'settings.integrations.modules',
					// 	icon: 'svg modules',
					// },
					{
						id: 'settings.integrations.graphqldocs',
						icon: 'svg apiPlayground',
						moduleType: Module_Type.DevTools,
						to: '/settings/graphqldocs',
						route: 'graphqldocs',
					},
					// {
					// 	id: 'settings.integrations.sdk',
					// 	icon: 'svg sdk',
					// },
				],
			},
			// {
			// 	id: 'settings.company',
			// 	subs: [
			// 		{
			// 			id: 'settings.company.settings',
			// 			icon: 'svg settings',
			// 		},
			// 		{
			// 			id: 'settings.company.licenses',
			// 			icon: 'svg licenses',
			// 		},
			// 	],
			// },
			{
				id: 'settings.infrastructure',
				moduleType: [Module_Type.Router, Module_Type.Root],
				subs: [
					{
						id: 'settings.infrastructure.locations',
						icon: 'svg place',
						moduleType: Module_Type.Root,
					},
					{
						id: 'settings.infrastructure.routers',
						icon: 'svg router',
						moduleType: Module_Type.Router,
						to: '/settings/infrastructure/routers',
						route: 'routers',
					},
				],
			},
		],
	},
]

export const menu = proccessMenuItems(menuItems)

const getRouteTreeRecursive = (
	routeItems: RouteItem[],
	menuItemToFind: RouteItem,
	tree: RouteItem[]
): RouteItem[] => {
	for (const routeItem of routeItems) {
		if (menuItemToFind.id.startsWith(routeItem.id)) {
			const newTree = [...tree, menuItemToFind]

			if (menuItemToFind.subs?.length) {
				return getRouteTreeRecursive(
					menuItemToFind.subs,
					menuItemToFind,
					newTree
				)
			}

			return newTree
		}
	}

	return tree
}

export const getRouteTree = (menuItemToFind: RouteItem) =>
	getRouteTreeRecursive(menu, menuItemToFind, [])
