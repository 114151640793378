import { useField } from 'formik'
import { FC } from 'react'
import {
	CustomDateTimePicker,
	CustomDateTimePickerProps,
} from '../CustomDateTimePicker'
import { useCustomForm } from './hooks'

interface CustomFormDateTimePickerProps
	extends Omit<CustomDateTimePickerProps, 'onChange' | 'value'> {
	name: string
}

export const CustomFormDateTimePicker: FC<CustomFormDateTimePickerProps> = ({
	disabled: disabledFromProps,
	name,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const [field, , helpers] = useField({
		name,
	})

	const onDateChange = (
		date: Date | null,
		value: string | null | undefined
	) => {
		helpers.setValue(date)
	}

	return (
		<CustomDateTimePicker
			{...otherProps}
			disabled={disabled || disabledFromProps}
			onChange={onDateChange}
			value={field.value}
		/>
	)
}
