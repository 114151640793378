import { ID } from '@app/@types'
import { CurrentUser } from '@app/@types/users'

export const currentUserStickyStateKey = 'currentUserSettings'

export type CurrentUserStickyState = {
	defaultRootId?: ID | null
	filters?: any
}

export type CurrentUserContextState = {
	currentUser?: CurrentUser
	permissions: string[]

	setDefaultUserSettings: (
		newDefaultRootId?: ID | null,
		filters?: any,
		tableName?: string
	) => void
	deleteDefaultUserSettings: () => void
}

export type useCurrentUserType = {
	(): {
		currentUser?: CurrentUser
		setDefaultUserSettings: (
			newDefaultRootId?: number | null,
			filters?: any,
			tableName?: string
		) => void
		deleteDefaultUserSettings: () => void
	}
}
