import { useEffect, useRef } from 'react'

export const useTimeout = (callback: () => void, delay: number | null) => {
	const savedCallback = useRef(callback)
	const timeoutId = useRef<NodeJS.Timeout>()

	const clearSavedTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current)
		}
	}

	// Remember the latest callback if it changes.
	useEffect(() => {
		savedCallback.current = callback
	})

	// Set up the timeout.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		if (delay === null) {
			clearSavedTimeout()

			return
		}

		timeoutId.current = setTimeout(() => savedCallback.current(), delay)

		return () => {
			clearSavedTimeout()
		}
	}, [delay])
}

export default useTimeout
