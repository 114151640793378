import { addMinutes, format, isValid } from 'date-fns'
import { useTranslation } from './lang'
import { Unit } from './enums'

interface EnumSelectOption {
	id: number
	label: string
	value: any
}

export const EnumToSelectOption = (
	enumType: any,
	translationPrefix: string = '',
	allOption?: EnumSelectOption,
	idProperty?: boolean,
	removeOption?: string,
	labelLength?: number
) => {
	const { t } = useTranslation()

	let arr = (Object.keys(enumType) as Array<keyof typeof enumType>).map(
		(key, i) => ({
			id: idProperty ? enumType[key] : i,
			label: (translationPrefix
				? t(`${translationPrefix}.${enumType[key]}`)
				: enumType[key]
			).substring(0, labelLength),
			value: enumType[key],
		})
	)
	if (removeOption) {
		arr = arr.filter((option) => option.value !== removeOption)
	}
	if (allOption) {
		arr.unshift(allOption)
	}

	// PUT A SPECIFIC ELEMENT TO THE BACK, IF IT EXISTS
	const lastElement = 'Other'

	if (arr.some((item) => item.label === lastElement)) {
		const otherElementsArr = arr.filter(
			(item) => item.label === lastElement
		)
		const lastElementArr = arr.filter((item) => item.label !== lastElement)

		return lastElementArr.concat(otherElementsArr)
	}

	return arr
}

export function capitalize(str: string) {
	if (typeof str !== 'string' || str.length === 0) {
		return str
	}
	return str[0].toUpperCase() + str.slice(1)
}

export function capitalizeTitle(title: string | null | undefined) {
	if (!title) return ''

	const lowercaseWords = [
		'ghs',
		'a',
		'an',
		'the',
		'and',
		'but',
		'or',
		'on',
		'in',
		'with',
		'to',
		'of',
		'for',
		'by',
		'as',
		'at',
	]

	const acronyms = ['ghs', 'lot', 'sku', 'id', 'qr', 'cas', 'csv']

	return title
		.split(' ')
		.map((word, index, array) => {
			// Check if word is an acronym
			if (acronyms.includes(word.toLowerCase())) {
				return word.toUpperCase()
			}

			// Always capitalize the first and last word
			if (index === 0 || index === array.length - 1) {
				return (
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				)
			}

			// Check if word is in the list of lowercase words
			if (lowercaseWords.includes(word.toLowerCase())) {
				return word.toLowerCase()
			}

			// Capitalize other words
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		})
		.join(' ')
		.replace(/\/(\w)/g, (match, letter) => `/${letter.toUpperCase()}`)
}

export function addTimezoneOffset(date: Date) {
	const offset = date.getTimezoneOffset()
	return addMinutes(date, -offset)
}

export const getDateString = (date: Date): string => {
	if (!date) return '/'

	if (isValid(date)) return format(date, 'dd.MM.yyyy')
	else {
		const newDate = new Date(date)

		if (isValid(newDate)) return format(newDate, 'dd.MM.yyyy')
		else return '/'
	}
}

export const getTimeString = (date: number | Date) => {
	if (!date) return '/'

	if (!isValid(date)) return '/'

	return format(date, 'HH:mm')
}

export function extractInitials(fullName: string) {
	if (!fullName) return ''

	const names = fullName.split(' ')
	const initials = names[0][0] + names[names.length - 1][0]
	return initials.toUpperCase()
}

export function getUnitTranslation(labelKey: string) {
	return Object.keys(Unit)
		.map((key) => key.toUpperCase())
		.includes(labelKey.toUpperCase())
		? `${'unit'}.${labelKey.toUpperCase()}`
		: capitalize(labelKey.toLowerCase())
}
