import { RouteItem } from '@app/constants'
import { getPermissionName } from '@app/constants/permissions'
import { Action_Type, Module_Type } from '@app/lib/graphql'
import { useContext } from 'react'
import { CurrentUserContext } from '@app/lib/packages/currentUser/hooks'
import { usePermissonType } from './types'

export const usePermissions: usePermissonType = () => {
	const { permissions } = useContext(CurrentUserContext)

	const isAdmin = () => permissions.includes(Module_Type.Admin)

	const hasPermission = (permissionToCheck: string) => {
		return isAdmin() || permissions.includes(permissionToCheck)
	}

	const hasModulePermission = (moduleType: Module_Type) => {
		return isAdmin() || permissions.some((q) => q.endsWith(moduleType))
	}

	const hasModuleActionPermission = (
		moduleType: Module_Type,
		actionType: Action_Type
	) => {
		return hasPermission(getPermissionName(moduleType, actionType))
	}

	const hasRoutePermission = (routeItem: RouteItem): boolean => {
		const hasPermissionToRoute = Array.isArray(routeItem.moduleType)
			? routeItem.moduleType.some((moduleType) =>
					hasModulePermission(moduleType)
			  )
			: hasModulePermission(routeItem.moduleType as Module_Type)
		return (
			!routeItem.moduleType ||
			(hasPermissionToRoute &&
				(!routeItem.subs?.length ||
					routeItem.subs.some((q) => hasRoutePermission(q))))
		)
	}

	return {
		hasModulePermission,
		hasModuleActionPermission,
		hasPermission,
		hasRoutePermission,
	}
}
