import React, { FC } from 'react'
import classNames from 'classnames'

import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { menu, RouteItem } from '@app/constants'
import { SidebarMainItem } from './SidebarMainItem'
import { usePermissions } from '@app/lib/packages/permissions'

interface SideBarProps {
	onMenuItemClick: (menuItem: RouteItem) => void
	open: Boolean
}

const drawerWidth = ''

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		drawer: {
			background: theme.palette.secondary.dark,
			width: 110,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			background: theme.palette.secondary.dark,
			// transition: theme.transitions.create("width", {
			// 	easing: theme.transitions.easing.sharp,
			// 	duration: theme.transitions.duration.leavingScreen
			// }),
			// overflowX: "hidden",
			width: theme.spacing(14) + 2,
			// [theme.breakpoints.down('lg')]: {
			// 	position: 'fixed',
			// 	zIndex: 100,
			// },
		},
		drawerClose: {
			background: theme.palette.secondary.dark,
			// transition: theme.transitions.create("width", {
			// 	easing: theme.transitions.easing.sharp,
			// 	duration: theme.transitions.duration.leavingScreen
			// }),
			// overflowX: "hidden",
			width: 0,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(14) + 2,
			},
		},
		toolbar: {
			color: 'white',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
		},
		content: {
			background: theme.palette.secondary.light,
			flexGrow: 1,
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(3),
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
	})
)

const SideBar: FC<SideBarProps> = ({ onMenuItemClick, open }) => {
	const classes = useStyles()
	const { hasRoutePermission } = usePermissions()

	const permittedMenuItems = menu.filter((q) => hasRoutePermission(q))

	return (
		<Drawer
			variant="permanent"
			className={classNames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: classNames({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
			PaperProps={{
				style: {
					border: 'none',
				},
			}}
		>
			<div className={classes.toolbar} />
			<Divider />
			<List style={{ paddingTop: 0 }}>
				{permittedMenuItems.map((item) => (
					<SidebarMainItem
						key={item.id}
						menuItem={item}
						onClick={onMenuItemClick}
					/>
				))}
			</List>
		</Drawer>
	)
}

export default SideBar
