import { createContext, PropsWithChildren } from 'react'
import { CustomFormContextState } from './types'

export const CustomFormContext = createContext<CustomFormContextState>({
	disabled: false,
})

type CustomFormProviderProps = {
	disabled?: boolean
}

export const CustomFormProvider = ({
	children,
	disabled = false,
}: PropsWithChildren<CustomFormProviderProps>) => {
	const contextValue: CustomFormContextState = {
		disabled,
	}

	return (
		<CustomFormContext.Provider value={contextValue}>
			{children}
		</CustomFormContext.Provider>
	)
}
