import React, { FC, SVGProps } from 'react'

export const CircleFilledCheckmarkIcon: FC<SVGProps<any>> = ({
	fill,
	...otherProps
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="4 4 20 20"
		{...otherProps}
	>
		<path
			fill={fill}
			d="M13.5 4c5.238 0 9.5 4.262 9.5 9.5S18.738 23 13.5 23 4 18.738 4 13.5 8.262 4 13.5 4z"
			transform="translate(-1297 -805) translate(1297 805) rotate(45 13.5 13.5)"
		/>
		<path
			fill="#FFF"
			d="M11.533 17.28L9.22 14.967c-.293-.293-.293-.768 0-1.06.293-.293.767-.293 1.06 0l1.783 1.782 5.47-5.47c.293-.292.768-.292 1.06 0 .293.294.293.768 0 1.061l-6 6c-.292.293-.767.293-1.06 0z"
			transform="translate(-1297 -805) translate(1297 805)"
		/>
	</svg>
)
