import { isBase64 } from './utils'

export const getBase64ImageUrl = (src: string) => {
	if (src && isBase64(src) && !src.startsWith('data:image/png')) {
		return `data:image/png;base64,${src}`
	}

	return src
}
export const blobToImage = (blob: any) => {
	return new Promise((resolve) => {
		const url = URL.createObjectURL(blob)
		let img = new window.Image()
		img.onload = () => {
			URL.revokeObjectURL(url)
			resolve(img)
		}
		img.src = url
	})
}
