import {
	Action_Type,
	BlinkSpeed,
	BlinkTime,
	ButtonActions,
	Country,
	DisplayType,
	LedColor,
	LogType,
	ModeEnum,
	Module_Type,
	OrderMode,
	OrderStatus,
	PurchasedModules,
	VirtualShelfVariant,
	WeekDay,
} from '@app/lib/graphql'
import { getYear } from 'date-fns'
import { HistoryType, Unit } from '@app/lib/enums'

export const en = {
	/* 01.General */
	'general.copyright': `Agitron © ${getYear(
		Date.now()
	)} All Rights Reserved.`,
	tvDevice: {
		name: 'Name',
		token: 'Token',
		uuid: 'UUID',
		tags: 'Tags',
		channel: {
			name: 'Channel',
		},
		root: {
			name: 'Root',
		},
		width: 'Width',
		height: 'Height',
	},
	aisle: {
		add: 'Add Aisle',
		label: 'Aisle',
		name: 'Aisle name',
		key: 'Key',
		rootId: 'Location',
	},
	assetManager: {
		createFolder: {
			name: 'Folder name',
		},
		dropzone: {
			helperText: 'Drag & Drop files or browse to upload',
			buttonHelperText: 'Browse',
		},
		fileTypes: {
			image: 'Image',
			other: 'Unknown file type',
			video: 'Video',
		},
		media: 'Media',
		search: 'Search',
		searchPlaceholder: 'Search by filename...',
		deleteFile: {
			success: 'File "{entityName}" successfully deleted!',
			error: 'There was an error deleting "{entityName}"! Please try again later...',
		},
	},
	bay: {
		add: 'Add Bay',
		aisleId: 'Choose Aisle',
		label: 'Bay',
		key: 'Key',
	},
	componentVar: {
		create: 'Add Variable',
		name: 'Name',
		templateKey: 'Template key (unique)',
		type: 'Type',
		deviceValueTypes: {
			base64: 'Base64',
			number: 'Number',
			price: 'Price',
			text: 'Text',
			qr: 'QR',
			ean: 'EAN',
		},
	},
	dashboard: {
		attention: 'Attention',
		apiErrCount: 'API Error',
		batteryUnderCount: 'Battery under 20%',
		devices: 'Devices',
		deviceErrCount: 'Device error',
		deviceRefreshing: 'Device refreshing',
		healthDashboard: 'Health Dashboard',
		infrastructure: 'Infrastructure',
		processingErrCount: 'Processing error',
		industrialScale: 'Industrial Scale',
		smartSensorLabel: 'Sensor Label',
		smartShelfBar: 'Shelf Bar',
		smartSignage: 'Smart Signage',
		routerErrCount: 'Router error',
	},
	user: {
		add: 'Add User',
		'login-title': 'Login',
		logout: 'Logout',
		register: 'Register',
		'forgot-password': 'Forgot Password',
		email: 'E-mail',
		firstName: 'First name',
		username: 'Username',
		mobilePhoneNumber: 'Mobile phone number',
		location: 'Location / venue',
		label: 'User',
		lastName: 'Last name',
		generatePassword: 'Generate',
		password: 'Password',
		'forgot-password-question': 'Forget password?',
		fullname: 'Full Name',
		permissions: 'Permissions',
		rootIDs: 'Locations',
		roleIDs: 'Roles',
		roles: 'Roles',
		'login-button': 'LOGIN',
		'register-button': 'REGISTER',
		'reset-password-button': 'RESET',
		secondHeader: 'User permissions',
		secondHeaderDescription:
			'Select a predefined role or create user specific one',
		locationRestrictions: 'Location restrictions',
		role: 'Role',
		maxUsers:
			'Reached max number of users. Please contact Agitron support for more information.',
	},
	order: {
		article: 'Article',
		editArticle: 'Edit article',
		orderQuantity: 'Order quantity',
		status: 'Status',
		orderList: 'Order list',
		deliveryDate: 'Delivery date',
		planogram: 'Planogram (A/B/S/P)',
		changesBy: 'Changed by',
		orderListInfo:
			"Here, you can see the audit trail for this specific order. The history is recorded for any change in the system from the order until the delivery. If any changes are made in the order process, you can see these labeled as 'Change'. After the order, the user can change the quantity, the supplier, and the status that is currently possible.",
		historyOverview: 'History overview',
		historyOverviewInfo:
			"Here, you can see the audit trail for this specific order. The history is recorded for any change in the system from the order until the delivery. If any changes are made in the order process, you can see these labeled as 'Change'. After the order, the user can change the quantity, the supplier, and the status that is currently possible.",
		forms: {
			update: {
				success: 'Order updated successfully',
				error: 'Could not update order at this time',
			},
			archive: {
				success: 'Order updated and automatically archived',
			},
			unarchive: {
				success: 'Order unarchived successfully',
				error: 'Could not unarchive order at this time',
			},
		},
	},
	warehouse: {
		label: 'Warehouse',
		button: {
			identifyByLight: 'Identify by light',
		},
		supplier: {
			label: 'Supplier',
			suppliers: 'Suppliers',
			add: 'Add supplier',
			allSupliers: 'All suppliers',
			edit: 'Edit supplier',
			email: 'Email',
			companyName: 'Company',
			supplierId: 'Supplier ID',
			address: 'Address',
			addressNumber: 'Address number',
			city: 'City',
			postCode: 'Postcode',
			country: 'Country',
			vat: 'VAT',
			forms: {
				create: {
					success: 'Supplier was successfully added!',
					error: 'There was an error with adding an attribute! Please try again later...',
				},
				delete: {
					success: 'Supplier was successfully deleted!',
					error: 'There was an error with deleting! Please try again later',
				},
				update: {
					success: 'Supplier was successfully edited!',
					error: 'There was an error with editing! Please try again later',
				},
			},
		},
		reports: {
			rootId: 'Location',
			supplierId: 'Supplier',
			lastUpdate: 'Last update',
			mode: 'Mode',
		},
		stock: {
			label: 'Stock',
			addStock: 'Add stock',
			editStock: 'Edit stock',
			internalId: 'Internal ID',
			internalStockId: 'Internal stock ID',
			partnerStockId: 'Partner stock ID',
			stockSettings: 'Stock settings',
			storageType: 'Storage type (bin)',
			quantityChange: 'Quantity change',
			info: 'The setting for the weight of one product is one of the most important entries in the system to guarantee data integrity. Please set it in decimal values; a maximum of 3 decimal points are allowed. To get the most precise value, weigh more of the same products on a precise scale and calculate the average.',
			productWeight: 'Weight of a piece in grams',
			maxLoad: 'Maximum volume on location in pieces',
			settingsOverview: 'Settings overview',
			pcsInStorage: 'pcs of stock in storage',
			settingsOverviewInfo:
				"Here, you can check the complete settings for this stock item with all parameters. To edit the settings, click the 'Edit' button at the top. If this stock item is bound to a Smart Device, you will see the IoT telemetry of this device at the bottom. Also, the LED function will only be available when a Smart Device is bound.",
			alarmSettings: 'Alarm settings',
			normalAlarm: 'Normal alarm',
			expressAlarm: 'Express alarm',
			expirationDate: 'Expiration date',
			productSettings: 'Product settings',
			orderSettings: 'Order settings',
			price: 'Pricing for piece',
			currency: 'Currency',
			supplier: 'Primary supplier',
			deliveryDeadline: 'Delivery deadline',
			orderQuantity: 'Order quantity',
			yearlyOrdersQuantity: 'Predicted yearly orders quantity',
			primarySupplier: 'Primary supplier',
			dateRange: 'Date range',
			supplierNote:
				'* In the order processing system, you have the capability to modify the primary supplier to any supplier within the system.',
			location: 'Location',
			locationSettings: 'Location settings',
			activated: 'Activated',
			activeOrders: 'Active orders',
			stockHistoryOverview: 'Stock history overview',
			stockHistoryOverviewInfo:
				'Here, you can see the audit trail for this specific stock item. The history is recorded for any change in the stock quantity; also recorded are the additional telemetry data at the specific change. Note, the data is automatically synced over the Smart Scale Device every 3 minutes, but the record is written in the system only if there was a change detected in the quantity.',
			ordersOveview: 'Orders overview',
			ordersOveviewInfo:
				'Here, you can see the active orders for this specific stock item. To see more detailed information about the order, click on the order card. All archived orders can be found in the orders module.',
			planogram: {
				label: 'Planogram',
				info: 'The optional planogram system is a diagram that shows how and where specific products should be placed on shelves. In the planogram management, you can then see the visualized structure at the location. Caution: When the planogram is activated, products are bound to their positions and also to the device. There is no direct connection between the product and the device anymore.',
				position: 'Position',
				alert: 'CAUTION! The planogram system is real-time; all edits are saved automatically to the system and have an instant effect.',
				dialogs: {
					update: {
						error: 'Could not update planogram at this time',
					},
					disable: {
						description:
							'Disabling planogram will unlink stock from the position. Do you wish to proceed?',
						title: 'Confirm planogram deactivation',
					},
				},
			},
			quantity: 'Quantity',
			forms: {
				create: {
					success: 'Stock was added successfully!',
					error: 'Could not add stock at this time. Please try again later...',
				},
				update: {
					success: 'Stock was updated successfully!',
					error: 'Could not update stock at this time. Please try again later...',
				},
			},
		},
		order: {
			orderId: 'Order ID',
			orderDate: 'Order date',
			mode: 'Mode',
			deliveryDate: 'Delivery date',
			status: 'Status',
			orderQuantity: 'Order quantity',
			supplier: 'Supplier',
			noData: 'There are currently no active orders on this stock',
		},
		product: {
			create: 'Create Product',
			label: 'Product',
			selectProduct: 'Select product',
			name: 'Name',
			root: 'Location',
			products: 'Products',
			description: 'Description',
			dimensions: 'Dimensions',
			material: 'Material',
			internalId: 'Internal ID',
			productId: 'Product ID',
			surface: 'Surface',
			supplier: 'Supplier',
			unit: 'Unit',
			dialogs: {
				delete: {
					message:
						'Deleting {entityName} will also delete all stock assigned to it. Do you wish to proceed?',
				},
			},
			forms: {
				create: {
					success: 'Product was added successfully!',
					error: 'There was an error with adding a product! Please try again later...',
				},
				delete: {
					error: 'There was an error with deleting "{entityName}". Please try again later...',
					success: '"{entityName}" was successfully deleted!',
				},
				update: {
					success: '"{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
				led: {
					success: 'LED will blink for {duration} seconds',
					error: 'Unable to turn on the LED at this time',
				},
			},
		},
	},
	orders: {
		label: 'Orders',
	},
	menu: {
		app: 'Home',
		assets: 'Files',
		orbis: {
			label: 'Orbis',
		},
		warehouse: {
			label: 'Warehouse',
			overview: 'Overview',
			reports: 'Reports',
			warehouse: {
				label: 'Warehouse',
				stock: 'Stock',
				products: 'Products',
			},
			suppliers: {
				label: 'Suppliers',
			},
		},
		orders: {
			label: 'Orders',
			overview: 'Overview',
			reports: 'Reports',
			orders: {
				label: 'Orders',
				warehouse: 'Warehouse',
				virtualStore: 'Virtual store',
			},
			archive: {
				label: 'Archive',
				warehouse: 'Warehouse',
				virtualStore: 'Virtual store',
			},
		},
		advertising: {
			label: 'Advertising',
			overview: 'Overview',
			reports: 'Reports',
			proximity: {
				label: 'Proximity',
				locations: 'Locations',
				beacons: 'Beacons',
				geofences: 'Geofences',
			},
			messages: {
				label: 'Messages',
				proximity: 'Proximity',
				scheduled: 'Scheduled',
			},
			content: {
				label: 'Content',
				landingPages: 'Landing pages',
				experiences: 'Experiences',
			},
			signage: {
				devices: 'Devices',
				label: 'Signage',
				channels: 'Channels',
				playlists: 'Playlists',
			},
		},
		analitycs: {
			label: 'Analitycs',
			overview: 'Overview',
			reports: 'Reports',
			pathTracking: {
				label: 'Path tracking',
				individualPath: 'Individual Path',
				retentionShare: 'Retention Share',
				retentionTime: 'Retention Time',
				heatmap: 'Heatmap',
				deadzones: 'Deadzones',
				storeWaitTimes: 'Store Wait Times',
				microLocationValue: 'Micro Location Value',
			},
			audience: {
				label: 'Audience',
				peopleCounting: 'People Counting',
				recurringPeople: 'Recurring People',
			},
			trafficSources: {
				label: 'Traffic Sources',
				contentCodes: 'Content Codes',
				retargeting: 'Retargeting',
			},
		},
		virtualShelf: {
			label: 'Virtual Store',
			overview: 'Overview',
			reports: 'Reports',
			stores: 'Stores',
			videoWall: 'Video Wall',
			store: 'Store',
			orders: 'Orders',
			shelves: 'Shelves',
			racks: 'Racks',
			categories: 'Categories',
			virtualComponents: 'Virtual Components',
			screens: 'Screens',
			fillShelf: 'Fill Shelf',
			setupRack: 'Setup Rack',
			setupCategory: 'Setup Category',
			productEditor: 'Product Editor',
			category: 'Category',
		},
		dashboard: 'Dashboard',
		dataInput: 'Data',
		templateEditor: 'Template Editor',
		manualImport: 'Manual import',
		costumers: 'Costumers',
		devices: 'Devices',
		iotFleet: 'IoT Fleet',
		start: 'Start',
		'second-menu': 'Second Menu',
		'third-single': 'Third Single',
		second: 'Second',
		ui: 'UI',
		charts: 'Charts',
		chat: 'Chat',
		flows: 'Flow',
		flowOverview: 'Overview',
		flowAutomation: 'Flow automation',
		flowRunners: 'Flow runners',
		survey: 'Survey',
		todo: 'Todo',
		layouts: 'Layouts',
		import: 'Import',
		search: 'Search',
		smartLabel: 'Smart Label',
		reports: 'Reports',
		overview: 'Overview',
		labelPreview: 'Label Preview',
		labelTemplateEditor: 'Display Templates',
		labelGroups: 'Label Groups',
		locations: 'Location / Venue',
		infrastructure: 'Infrastructure',
		iotDevices: 'Devices',
		planograms: 'Planograms',
		products: 'Products',
		routers: 'Routers',
		roles: 'Roles',
		settings: {
			label: 'Settings',
			overview: 'Overview',
			documentation: 'Documentation',
			assetmanagement: {
				label: 'Asset Management',
				files: 'Files',
			},
			access: {
				label: 'Access',
				users: 'Users',
				roles: 'Roles',
				permissions: 'Permissions',
			},
			integrations: {
				label: 'Integrations',
				modules: 'Modules',
				graphqldocs: 'Api Playground',
				sdk: 'SDK',
			},
			company: {
				label: 'Company',
				settings: 'Settings',
				licenses: 'Licenses',
			},
			infrastructure: {
				label: 'Infrastructure',
				locations: 'Locations',
				routers: 'Routers',
			},
		},
		users: 'Users',
		industrialScale: 'Industrial Scale',
		smartSensorLabel: 'Sensor Label',
		smartSignage: 'Signage',
		smartShelfBar: 'Shelf Bar',
		smartSignageList: 'Signage List',
		smartShelfBarList: 'Shelf Bar List',
		smartSensorLabelList: 'Sensor Label List',
		list: {
			labelList: 'Label List',
			industrialScale: 'Industrial Scale List',
		},
	},
	labels: {
		functionSettings: 'Function Settings',
		settings: 'Settings',
		signage: 'Signage',
		'settings-sub':
			'Some functionalities are tied to the operation of the hardware and can affect battery life and refresh intervals.',
		'on-demand': 'On Demand Functionality',
		'on-demand-sub':
			'Based on your license you can activate or deactivate additional features on this device.',
		'ai-price': 'AI Price',
		freshness: 'Freshness Sensors',
		historyButtonTextClosed: 'Access the list of all transactions',
		historyButtonTextOpened: 'Close the list of all transactions',
		history: 'History',
		transactionComplete: 'Successful transfer',
		transactionProcessing: 'Processing',
		transactionError: 'Error in transfer',
		resetScreen: 'Reset Screen',
		historySub:
			'The device history shows the 10 most recent transactions involving this device. You can access the list of all transactions for the last 6 months at any time, after which time the logs will be deleted due to privacy regulations. Loading all transactions may take a few minutes.',
		fontFamily: 'Font Family',
		fontStyle: 'Font Style',
		fontSize: 'Font Size',
		fontColor: 'Font Color',
		objectConfig: 'Object Configuration',
		width: 'Width',
		height: 'Height',
		posX: 'Position X',
		posY: 'Position Y',
		textStyling: 'Text Styling',
		paddingBottom: 'Padding Bottom',
		paddingTop: 'Padding Top',
		paddingLeft: 'Padding Left',
		paddingRight: 'Padding Right',
		proccessing: 'Processing, refreshing …',
		serialNumber: 'Serial',
		size: 'Device',
		position: {
			shelf: {
				key: 'Shelf',
			},
		},
		root: {
			name: 'Location / Venue',
		},
		template: {
			name: 'Label Template',
		},
	},
	flow: {
		add: 'Add Flow Automation',
		controls: 'Controls',
		dataServices: 'Data Services',
		dataVariables: 'Data Variables',
		dragAndDrop: 'Drag & Drop Components',
		dropHere: 'Drop Here',
		if: 'If',
		ifOperators: 'If operators',
		label: 'Flow Automation',
		name: 'Flow automation name',
		secondHeader: 'Workflow rules',
		secondHeaderDescription:
			'Then Drag & Drop rule components from the data assets to the programming boxes to create a scenario.',
		sensors: 'Sensors',
		status: 'Status',
		then: 'Then',
		thenOperators: 'Then operators',
		operators: {
			changeTemplate: 'Change Template',
			changeTemplateValue: 'Change Template ({value})',
			decreaseBy: 'Decrease By',
			equals: 'Equals',
			exist: 'Exist',
			existValue: 'Exist ({value})',
			increaseBy: 'Increase By',
			noChangesTimer: 'No Changes Timer',
			greaterThan: 'Greater than',
			lowerThan: 'Lower than',
		},
		variables: {
			number: 'Value Number',
			numberLabel: 'Value',
			numberValue: 'Value Number ({value})',
			percentage: 'Value Percentage',
			percentageLabel: 'Percentage',
			percentageValue: 'Value Percentage ({value} %)',
		},
		warnings: {
			atleastOneIfStatement: 'Atleast one IF statement is required!',
			atleastOneThenStatement: 'Atleast one THEN statement is required!',
			statementMalformed:
				'Statement "{statementType}" number {index} is malformed! Please check guide how to correctly create workflow statement...',
		},
	},
	flowRunner: {
		add: 'Add Flow Runner',
		name: 'Name',
		flowId: 'Flow automation',
		label: 'Flow Runner',
		flowRunnerLink: {
			GROUP: 'Group',
			PRODUCT: 'Product',
			ROOT: 'Location',
			TAG: 'Tag',
		},
		tabs: {
			settings: {
				activeDays: 'Repeat every',
				addLink: 'Add Link',
				flowRunnerLinkType: 'Type',
				header: 'Activation Schedule',
				headerDescription:
					'Set the scheduler for running this flow configuration. You can check all activity of this runner in the history tab.',
				manageLinks: 'Manage Links',
				secondHeader: 'Linked Products and Groups',
				secondHeaderDescription:
					'Products and assigned devices need to have all functionalities and functions that are defined in the selected Flow Automation.',
				repeatEvery: 'Repeat every',
				tabLabel: 'Runner settings',
				validFrom: 'Valid from',
				validFromLabel: 'Settings are valid from',
				validImmediately: 'Valid immediately',
				validInDateRange: 'Valid in date range',
				validInDateRangeLabel: 'Settings are valid in date range',
				validInRecurringSchedule: 'Recurring schedule',
				validInRecurringScheduleLabel:
					'Settings are valid in recurring schedule',
			},
			history: {
				tabLabel: 'History',
			},
		},
	},
	location: {
		add: 'Add Location',
		connectedRouters: 'Connected routers',
		deviceCount: 'Connected devices',
		key: 'Location / Key',
		label: 'Location / Venue',
		latitude: 'Latitude',
		longitude: 'Longitude',
		name: 'Location / Venue name',
		maxRoots:
			'Reached max number of locations. Please contact Agitron support for more information.',
		forms: {
			create: {
				generic: {
					success: 'Location was successfully created!',
					error: 'There was an error with creating! Please try again later',
				},
				success:
					'Location with name "{entityName}" was successfully added!',
				error: 'There was an error with inserting "{entityName}"! Please try again later...',
			},
			delete: {
				generic: {
					success: 'Location was successfully deleted!',
					error: 'There was an error with deleting! Please try again later',
				},
				error: 'There was an error with deleting location "{entityName}". Please try again latter...',
				success: 'Location "{entityName}" was successfully deleted!',
			},
			update: {
				generic: {
					success: 'Location was successfully edited!',
					error: 'There was an error with editing! Please try again later',
				},
				success:
					'Location with name "{entityName}" was successfully edited!',
				error: 'There was an error with editing "{entityName}"! Please try again later',
			},
		},
	},
	manualImport: {
		dataImportWizard: 'Data Import Wizard',
		uploadFile: 'Upload File',
		mappingFile: 'Mapping File',
		importData: 'Import Data',
		openData: 'Open Data',
		warehouse: 'Warehouse',
		virtualStore: 'Virtual Store',
		addRow: 'Add Row',
		addColumn: 'Add Column',
		addVariable: 'Add Variable',
		importSuccessful: 'Successfully imported {numOfProducts} products',
		forms: {
			success: 'Data was successfully imported!',
			error: 'There was an error with importing data! Please try again later...',
			missingFields:
				'Please fill all required fields! (Selects above the data table)',
			duplicateInternalIds:
				'Multiple products with the same internal ID found!',
		},
	},
	planograms: {
		addShelf: 'Add shelf',
		addPosition: 'Add position',
		noDevice: 'No device',
		notAssigned: 'Not assigned',
		orientation: {
			bottomRight: 'Bottom right',
			bottomLeft: 'Bottom left',
			topLeft: 'Top left',
			topRight: 'Top right',
		},
		label: 'Planogram (A/B/S/P)',
	},
	position: {
		deviceSerialNumber: 'Device',
		key: 'ID',
		label: 'Position',
		name: 'Name',
		x: 'X',
		y: 'Y',
		z: 'Z',
		productId: 'Choose product for position',
		templateVariantId: 'Choose template variant for position',
		shelfId: 'Choose shelf for Position',
		device: {
			serialNumber: 'Serial',
		},
		product: {
			name: 'Product',
		},
	},
	/* PRODUCTS */
	product: {
		atleastOneVariable: 'Atleast one variable should have non-empty value!',
		componentsForListPlaceholder: 'Choose variables to view...',
		create: 'Create product',
		eanCodes: 'EAN codes',
		internalId: 'Internal ID',
		label: 'Product',
		name: 'Name',
		rootKey: 'Location',
	},
	permission: {
		actionTypes: {
			[Action_Type.Create]: 'Create',
			[Action_Type.Delete]: 'Delete',
			[Action_Type.Update]: 'Update',
			[Action_Type.Read]: 'Read',
		},
		moduleTypes: {
			[Module_Type.Admin]: 'Admin',
			[Module_Type.Bar]: 'Bar',
			[Module_Type.DevTools]: 'Dev tools',
			[Module_Type.Flow]: 'Flow',
			[Module_Type.Planogram]: 'Planogram',
			[Module_Type.Product]: 'Product',
			[Module_Type.Router]: 'Router',
			[Module_Type.Signage]: 'Signage',
			[Module_Type.SmartLabel]: 'Smart label',
			[Module_Type.SmartSensor]: 'Smart sensor',
			[Module_Type.Storage]: 'Storage',
			[Module_Type.Template]: 'Template',
			[Module_Type.VirtualStore]: 'Virtual store',
			[Module_Type.Warehouse]: 'Warehouse',
			[Module_Type.Orbis]: 'Orbis',
			[Module_Type.Root]: 'Root',
			[Module_Type.User]: 'User',
			[Module_Type.Order]: 'Order',
			[Module_Type.ProductHistory]: 'Product history',
		},
	},
	role: {
		add: 'Add Role',
		label: 'Role',
		value: 'Name',
		allRoles: 'All roles',
		selectedRoles: 'Selected roles',
		warnings: {
			tooFewPermission: 'Atleast one permission must be added to role!',
		},
	},
	signage: {
		channel: {
			add: 'Add channel',
			calendar: 'Calendar',
			calendarRangeType: {
				day: 'Day',
				month: 'Month',
				week: 'week',
			},
			content: 'Content',
			contentSelector: {
				add: 'Add Content',
				description:
					'Add a Playlist or a Media File directly to the Schedule Manager.',
				mediaTab: 'Media',
				mediaSearchPlaceholder: 'Search by filename...',
				playlistSearchPlaceholder: 'Search by playlist name',
				playlistTab: 'Playlist',
			},
			dateRanges: 'Settings are valid in date range',
			devices: 'Devices',
			deviceType: 'Device Type',
			displayType: 'Output Format',
			isHfDevice: 'Shelf - MultiScreen',
			label: 'Channel',
			location: 'Location/Venue',
			name: 'Channel name',
			noSpecificDate: 'No specific date',
			manageSchedule: 'Manage Schedule',
			playing: 'Playing',
			priority: 'Priority',
			tags: 'Tags',
			type: 'Type',
			timeFrames: 'Settings are valid in recurring schedule',
			schedule: 'Schedule',
			scheduleManager: {
				description:
					'If you enable priority for this content, it will override all other schedules and rules during the period.',
			},
			screens: 'Screens',
			stopped: 'Stopped',
			weekdays: 'Repeat every',
			warnings: {
				deviceHasChannelAlreadyAssigned:
					'This device already has an assigned channel. Would you like to switch the device to this channel instead?',
				tooFewChannelItems:
					'Atleast one content item must be added to schedule!',
			},
			forms: {
				addItem: {
					max10Items: 'You can add max 10 items to this display',
				},
				create: {
					success:
						'Channel with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Channel was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting channel "{entityName}". Please try again latter...',
					success: 'Channel "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Channel with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
		},
		devices: {
			allLocations: 'All locations',
			allTags: 'All tags',
			allChannels: 'All channels',
			affectedDevices: 'Affected devices:',
			warnings: {
				deviceHasChannelAlreadyAssigned:
					'Some devices are already assigned to channel. Would you like to switch the devices to this channel instead?',
				errorActivatingDevice:
					'There was an error activating device! Please try again later...',
				errorActivatingDevices:
					'There was an error activating devices! Please try again later...',
			},
		},
		playlist: {
			add: 'Add playlist',
			name: 'Playlist name',
			label: 'Playlist',
			totalDuration: 'Total Duration',
			warnings: {
				tooFewPlaylistItems:
					'Atleast one playlist item must be added to playlist!',
			},
		},
		tvDevices: {
			name: 'Name',
			tags: 'Tags',
		},
		pendingForActivation: 'pending for activation',
	},
	shelf: {
		add: 'Add Shelf',
		addShelfDescription:
			'Select the shelf you want to add to the specific rack',
		addProduct: 'Add Product',
		addProductDescription:
			'Select the product you want to add to the specific shelf',
		label: 'Shelf',
		bayId: 'Select Bay',
		key: 'Key',
		manageRack: 'Manage Rack',
		name: 'Shelf Name',
		orientation: 'Orientation',
		useSimpleEditor: 'Use simple editor',
		structKey: 'Aisle Bay Shelf',
		warnings: {
			structKeyMustHaveThreeValues:
				'Value should be in the following format: AA BB SS!',
		},
		positions: 'Positions',
	},
	store: {
		storeName: 'Store Name',
		location: 'Location',
		structure: 'Structure',
		devices: 'Devices',
		manageHome: 'Manage home screen',
		addRack: 'Add Rack',
		addCategory: 'Add Category',
		idleTime: 'Idle time',
		screenSaver: 'Screen saver',
		addRackDescription: 'Select the rack you want to add to the category',
		addCategoryDescription:
			'Select the category you want to place to the home screen. You can change the position on the home screen canvas.',
		settingsDescription:
			'Select a predefined screensaver channel from the signage section.',
		useScreenSaver: 'Use screen saver from signange',
	},
	smartLabel: {
		batteryLife: 'Battery Life',
		latest: 'Latest',
		tooltip: {
			status: `<html><head><title>Page Title</title><style>.Device-status-explan { display: block;width: 275px; font-family: Roboto; font-size: 10px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: 0.17px; color: #bababa; } .Device-status-explan .text-style-1 { line-height: 20px;font-size: 12px; font-weight: bold; letter-spacing: 0.2px; color: white; } .Device-status-explan .text-style-2 { font-weight: bold;}</style> </head> <body> <span class="Device-status-explan"> <span class="text-style-1">Device status explanation</span></br><span class="text-style-2">Online - </span>All works normal</br><span class="text-style-2">Offline</span> - The device is offline</br></body> </html>`,
			transactions: `<html><head><title>Page Title</title><style>.Device-status-explan { display: block;width: 275px; font-family: Roboto; font-size: 10px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: 0.17px; color: #bababa; } .Device-status-explan .text-style-1 { line-height: 20px;font-size: 12px; font-weight: bold; letter-spacing: 0.2px; color: white; } .Device-status-explan .text-style-2 { font-weight: bold;}</style> </head> <body> <span class="Device-status-explan"> <span class="text-style-1">Device transactions</span></br><span class="text-style-2">Complete - </span>All transactions are confirmed</br><span class="text-style-2">Refreshing</span> - The last transaction is in processing, refreshing, this status is in real time with a 5 min timeout</br><span class="text-style-2">Error</span> - The device was not reachable and the last transaction was not successful </span> </body> </html>`,

			firmwareVersion: `<html><head><title>Page Title</title>
			<style>
			.Firmware-version-The {width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Firmware-version-The .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
			  </style>			</head>			
			  <body>
			  <span class="Firmware-version-The">
				<span class="text-style-1">Firmware version</span></br>The dialog displays the current version on the device and other compatible versions. The version can be upgraded or downgraded for the device, but not recommended. The system itself upgrade the firmware when needed.
			  </span>
			  </body>			</html>`,
			communicationInterval: `<html><head><title>Page Title</title>
			  <style>
			  .Communication-interv {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Communication-interv .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
				</style>			</head>			
				<body>
				<span class="Communication-interv">
  		<span class="text-style-1">Communication interval</span></br>The communication interval is the key parameter for the response speed of the device and the battery life. Lower intervals result in higher communication speeds and higher battery usage.
		</span>
				</body>			</html>`,
			planogramSystemMode: `<html><head><title>Page Title</title>
			  <style>
			  .Planogram-system-mod {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Planogram-system-mod .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
				</style>			</head>			
				<body>
				<span class="Planogram-system-mod">
		<span class="text-style-1">Planogram system mode</span></br>When the planogram mode is activated the products and devices will no longer be connected directly. The planogram system will bind the products with the shelf and shelf positions and this positions will be assigned to the device. The product and template can then only be changed over the planogram position interface. 
		</br>Read the good practices manual for more detailed planogram usage scenarios.
		</span>
				</body>			</html>`,
			useDivider: `<html><head><title>Page Title</title>
			  <style>
			  .Planogram-system-mod {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Planogram-system-mod .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
				</style>			</head>			
				<body>
				<span class="Planogram-system-mod">
		<span class="text-style-1">Divider search</span></br> 
		Search shelves with divider.</br> Search with divider if you mark aisles, bays and shelves in any other format than xxx-xx-xx (aisle-bay-shelf) 
		
		</span>
				</body>			</html>`,
			batteryLifetime: `<html><head><title>Page Title</title>
			  <style>
			  .Battery-lifetime-The {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Battery-lifetime-The .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
				</style>			</head>			
				<body>
				<span class="Battery-lifetime-The">
				<span class="text-style-1">Battery lifetime</span></br>The battery life is calculated based on the daily data from the device and is approximate.
				</br>The lifetime is affected from the network signal strength, the air temperature, communication intervals and refreshing cycles. Activated sensor consume in addition the battery lifetime (check the manual).
				</span>
				</body>			</html>`,
			connectionStabilityIndication: `<html><head><title>Page Title</title>
			  <style>
			  .Connection-stability {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .Connection-stability .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
			  .Connection-stability .text-style-2 {
				font-weight: bold;
			  }
				</style>			</head>			
				<body>
				<span class="Connection-stability">
		<span class="text-style-1">Connection stability</span></br><span class="text-style-2">Perfect</span> - Connection works at the speed of light.</br><span class="text-style-2">Standard</span> - Connection works without critical errors.</br><span class="text-style-2">Bad</span> - Connection is weak and may affect the responsiveness of the device and power consumption.</br><span class="text-style-2">No Network</span> - No connection. The heartbeat of the device is not available.
		</span>
				</body>			</html>`,
			aiPowerOptimisation: `<html><head><title>Page Title</title>
			  <style>
			  .AI-power-optimisatio {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .AI-power-optimisatio .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
			  .AI-power-optimisatio .text-style-2 {
				font-weight: bold;
			  }
				</style>			</head>			
				<body>
				<span class="AI-power-optimisatio">
			<span class="text-style-1">AI power optimisation</span></br>The communication interval is calculated based on the use of this device and the working hours of the location.</br><span class="text-style-2">This function is experimental.</span>
			</span>
				</body>			</html>`,
			highAvailability: `<html><head><title>Page Title</title>
			  <style>
			  .High-availability-Th {
				width: 275px;
				font-family: Roboto;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.17px;
				color: #bababa;
				display: block;
			  }
			  .High-availability-Th .text-style-1 {
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 0.2px;
				color: white;
				line-height: 20px;
			  }
				</style>			</head>			
				<body>
				<span class="High-availability-Th">
				<span class="text-style-1">High availability</span></br>The communication interval is set in high availability mode what means that the device response time is below 5s second from the request to the refresh (when connection stability is “Perfect or Standard”).
				</span>
				</body>			</html>`,
		},
		allStatuses: 'All Statuses',
		allTransactions: 'All transactions',
		battery: 'Battery',
		device: 'Device',
		devices: 'Devices',
		editPosition: 'Edit Position',
		cancelEditing: 'Cancel editing',
		connectionStability: 'Connection Stability',
		id: 'ID',
		forceResetDevice: 'Force reset device',
		forceResetSuccess: 'Device was succesfully force reset!',
		firmware: 'Firmware',
		heartbeat: 'Heartbeat',
		name: 'Name',
		noEditablePositions:
			'Shelf has no positions. Open planogram to add some!',
		noSelectedPositions: 'Please select a position...',
		noShelfId:
			'First select shelf from below "Shelf Selector" to start editing label position',
		disabledProduct:
			'If an option is disabled, it means that the product is already assigned to another device or a planogram position. If you wish to assign it to this device, please unlink it from another device or a planogram position first.',
		positionNamePlaceholder: 'Position Name',
		positionKeyPlaceholder: 'Position Key',
		productId: 'Product',
		product: 'Product',
		resetDevice: 'Reset device',
		retryResetDevice: 'Retry reset device',
		resetProccessing: 'Resetting...',
		resetFailed: 'Device is not in range of any router in root: {rootName}',
		resetSuccess: 'Label "{entityName}" was successfully reset!',
		deleteSuccess: 'Device "{entityName}" was successfully deleted!',
		serialNumber: 'Serial Number',
		shelfId: 'Shelf Selector',
		status: 'Status',
		template: 'Template',
		templateVariantId: 'Template variant',
		time: 'Time',
		transaction: 'Transaction',
		transactionTime: 'Transaction time',
		usePlanogram: 'Use planogram',
		useDivider: 'Use divider (000-00-00)',
		useShelfId: 'Use shelf id',
		openPlanogram: 'Open Planogram',
		openPosition: 'Open Position',
		notAssigned: 'Not assigned',
		shelfPositions: 'Shelf Positions',
		shelfMode: 'Shelf Mode',
		deleteDevice: 'Delete device',
		live: 'LIVE',
		preview: 'PREVIEW',
		dialogs: {
			delete: {
				title: 'Confirm device deletion',
				description:
					'This will action permanently remove the device from your cloud. Do you wish to proceed?',
			},
		},
	},
	smartSensorLabel: {
		add: 'Add Sensor Label',
		batteryLife: 'Battery Life',
		device: 'Device',
		id: 'ID',
		label: 'Sensor Label',
		name: 'Sensor Label Name',
		noNetwork: 'No Network',
		bad: 'Bad',
		perfect: 'Perfect',
		root: {
			name: 'Location',
		},
		tags: 'Tags',
		telemetry: 'Telemetry',
		serialNumber: 'MAC',
		sensor: 'Sensor',
		standard: 'Standard',
		humidityMonitoring: {
			endDate: 'View to',
			heading: 'Humidity monitoring',
			maxPressure: 'Max. pressure',
			minPressure: 'Min. pressure',
			startDate: 'View from',
		},
		temperatureMonitoring: {
			endDate: 'View to',
			heading: 'Telemetry monitoring',
			maxTemperature: 'Max. temperature',
			minTemperature: 'Min. temperature',
			startDate: 'View from',
		},
		telemetryTab: {
			description:
				'Activate the monitoring function for a special sensor. To use the data in other functions the specific sensor needs to be activated.',
			header: 'Telemetry',
		},
	},
	smartShelfBar: {
		device: 'Device',
		editHeader: 'Edit Label',
		label: 'Shelf Bar',
		groupId: 'Label group',
		macAddress: 'MAC',
		name: 'Label name',
		productId: 'Product',
		rootId: 'Location',
		templateVariantId: 'Template',
		token: 'Token',
		usePlanogram: 'Use planogram',
		x: 'Position x',
		barStates: {
			currentState: 'Current state',
			preview: 'Preview - new settings',
		},
		basicSettings: {
			background: 'Background',
			backgroundDescription:
				'You can choose between a static or dynamic video background. The video is automatically played in a loop.',
			dynamicVideo: 'Dynamic - Video',
			mediaUpload: 'Media upload',
			staticPicture: 'Static - Picture',
		},
		noShelfId: 'Shelf Bar must have a set Shelf for editing positions.',
		root: {
			name: 'Location',
		},
	},
	smartSignage: {
		scale: {
			add: 'Add Device',
			channelId: 'Selected Channel',
			label: 'Device',
			name: 'Device Name',
			ip: 'Device IP',
			password: 'Device Password',
			rootId: 'Location',
			username: 'Device Username',
			tags: 'Tags',
			type: 'Selected Model',
			types: {
				BIZERBA_KHIIPro_800: 'Bizerba KH II 800 Pro',
				DIBAL_D900: 'DIBAL D900',
				DIBAL_S500: 'Dibal S500',
				VIRTUAL_STORE: 'Virtual Store',
				DIGI_SM6000SSP: 'Digi SM6000SSP',
				DIGI_SM5300L: 'Digi SM5300L',
			},
		},
		virtualStore: {
			add: 'Add Device',
			label: 'Device',
			name: 'Device Name',
			rootId: 'Location',
			tags: 'Tags',
			token: 'Device Token',
			note: 'Note',
			warning: 'Sent',
			success: 'Received',
			default: 'Registered',
			tooltip: {
				status: `<html><head><title>Page Title</title><style>.Device-status-explan { display: block;width: 275px; font-family: Roboto; font-size: 10px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: 0.17px; color: #bababa; } .Device-status-explan .text-style-1 { line-height: 20px;font-size: 12px; font-weight: bold; letter-spacing: 0.2px; color: white; } .Device-status-explan .text-style-2 { font-weight: bold;}</style> </head> <body> <span class="Device-status-explan"> <span class="text-style-1">You can turn on signage in virtual store section.</span></br></body> </html>`,
			},
		},
		tabs: {
			tvDevices: {
				label: 'TV',
			},
			scales: {
				label: 'Scale',
			},
			virtualStoreDevices: {
				label: 'LCD Devices',
			},
		},
	},
	industrialScale: {
		led: {
			label: 'Pick & place by light',
			info: 'Some functionalities are tied to the operation of the hardware and can affect battery life and refresh intervals.',
			activateLed: 'Activate LED',
			color: 'Color',
			blinkTime: 'Blinking time',
			blinkSpeed: 'Blinking speed',
			notSupported: 'This device does not support LED functionality',
		},
		actionButtons: {
			label: 'Action buttons',
			info: 'The two action buttons can be configured for different tasks and can also be separately activated or deactivated.',
		},
	},
	codeEditor: {
		formatCode: 'Format Code',
		saveCode: 'Save Code',
		codeNotValid: 'Code is not valid',
		dialogs: {
			edit: {
				title: 'Save code',
				message:
					'Are you sure you want to edit template code? Saving code might break template. ALL devices using this template will update',
			},
		},
		form: {
			create: {
				success: 'Template was successfully added',
				error: 'There was an error with inserting template! Please try again later',
			},
			update: {
				success: 'Template was successfully edited',
				error: 'There was an error with editing template! Please try again later',
			},
			warnings: {
				oneOrMoreComponents:
					'Atleast one component is required in a template!',
			},
		},
	},
	templateEditor: {
		components: {
			barcode: {
				label: 'Barcode',
				qr: 'QR Link',
			},
			price: {
				label: 'Pricebox',
			},
			text: {
				label: 'Textbox',
			},
		},
		add: {
			searchLabel: 'Template title search',
		},
		displayTemplate: 'Display template',
		form: {
			create: {
				success: 'Template was successfully added',
				error: 'Error inserting template! You cannot create multiples of one size template for one variant',
			},
			update: {
				success: 'Template was successfully edited',
				error: 'There was an error with editing template! Please try again later',
			},
			warnings: {
				oneOrMoreComponents:
					'Atleast one component is required in a template!',
			},
		},
		connectedDevices: 'Connected devices',
		componentPositions: 'Component positions',
		componentStyling: 'Component styling',
		dataSources: 'Drag&Drop components',
		deviceSize: 'Display family',
		labels: {
			backgroundColor: 'Background color',
			borderColor: 'Border color',
			borderPixel: 'Border pixel',
			borderLine: 'Border line',
			font: 'Font',
		},
		isCustom: 'Enable advanced Code Editor',
		save: 'Save settings',
		name: 'Template title',
		tabs: {
			editor: 'Template Editor',
			preview: 'Template Preview',
			codeEditor: 'Code Editor',
		},
		templateVariantId: 'Template variant',
		selectProduct: 'Select product for template preview...',
		variable: 'Variable',
		variables: {
			header: 'Insert variables',
			maxLengthDisabledPlaceholder: 'First choose active variable...',
			maxLength: 'Variable max length',
			maxLengthPlaceholder: 'Default value is unlimited length',
			activeVariables: 'Active variables',
		},
		key: 'Key',
		displayFamily: 'Display family',
		templateTitle: 'Template title',
		codeEditor: 'Code Editor',
		addVariant: {
			label: 'Add variant',
			create: {
				success: 'Variant was successfully added',
				error: 'There was an error with inserting variant! Please try again later',
			},
			update: {
				success: 'Variant was successfully updated',
				error: 'There was an error updating the variant! Please try again later',
			},
			delete: {
				success: 'Variant was successfully deleted',
				error: 'There was an error deleting the variant! Please try again later',
			},
			error: 'There was an error! Please try again later',
		},
		editVariant: 'Edit variant',
		editTemplate: 'Edit template',
		addTemplate: 'Add template',
	},
	virtualShelf: {
		browserSupport: {
			yourBrowser:
				'Your browser doesn’t fully support this Agitron Virtual Store feature.',
			useInstead:
				'For the best experience, use Google Chrome, Microsoft Edge or Apple Safari instead.',
		},
		tooFewItems: 'Too few items. Please add more',
		fillShelf: {
			forms: {
				name: 'name',
				spacing: 'Spacing',
				variant: 'Shelf variant',
				create: {
					success:
						'Virtual shelf with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Virtual shelf was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting virtual shelf "{entityName}". Please try again latter...',
					success:
						'Virtual shelf "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Virtual shelf with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
			deleteShelfFromRack: {
				delete: {
					generic: {
						success: 'Virtual shelf was successfully removed!',
						error: 'There was an error with removing! Please try again later',
					},
					error: 'There was an error with removing virtual shelf "{entityName}" from Rack. Please try again latter...',
					success:
						'Virtual shelf "{entityName}" was successfully removed from Rack!',
				},
			},
			deleteCategory: {
				delete: {
					generic: {
						success: 'Virtual Category was successfully removed!',
						error: 'There was an error with removing! Please try again later',
					},
					error: 'There was an error with removing virtual category "{entityName}" from Setup category. Please try again latter...',
					success:
						'Virtual category "{entityName}" was successfully removed from Setup category!',
				},
			},
			deleteProductFromShelf:
				'Do you really wish to delete "{entityName} {group}"?',
			manageShelf: 'Manage Shelf',
			shelfName: 'Shelf Name',
			spacing: 'Spacing',
			variants: {
				[VirtualShelfVariant.Normal]: 'Normal',
				[VirtualShelfVariant.Agitron]: 'Agitron',
				[VirtualShelfVariant.GlassCenter]: 'Glass Center',
				[VirtualShelfVariant.GlassLeft]: 'Glass Left',
				[VirtualShelfVariant.GlassRight]: 'Glass Right',
				[VirtualShelfVariant.GlassFull]: 'Glass Full',
				[VirtualShelfVariant.GrayCenter]: 'Gray Center',
				[VirtualShelfVariant.GrayLeft]: 'Gray Left',
				[VirtualShelfVariant.GrayRight]: 'Gray Right',
				[VirtualShelfVariant.GrayFull]: 'Gray Full',
				[VirtualShelfVariant.WhiteCenter]: 'White Center',
				[VirtualShelfVariant.WhiteLeft]: 'White Left',
				[VirtualShelfVariant.WhiteRight]: 'White Right',
				[VirtualShelfVariant.WhiteFull]: 'White Full',
				[VirtualShelfVariant.WoodCenter]: 'Wood Center',
				[VirtualShelfVariant.WoodLeft]: 'Wood Left',
				[VirtualShelfVariant.WoodRight]: 'Wood Right',
				[VirtualShelfVariant.WoodFull]: 'Wood Full',
				[VirtualShelfVariant.WoodWhiteCenter]: 'White Wood Center',
				[VirtualShelfVariant.WoodWhiteLeft]: 'White Wood Left',
				[VirtualShelfVariant.WoodWhiteRight]: 'White Wood Right',
				[VirtualShelfVariant.WoodWhiteFull]: 'White Wood Full',
			},
		},
		setupRack: {
			forms: {
				name: 'name',
				fileId: 'Background image',
				backgroundFileId: 'Store background image',
				create: {
					success:
						'Virtual rack with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Virtual rack was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting virtual rack "{entityName}". Please try again latter...',
					success:
						'Virtual rack "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Virtual rack with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
			manageRack: 'Manage Rack',
			products: 'Products',
			max5: 'Maximal 5 shelves allowed',
		},
		setupCategory: {
			forms: {
				name: 'name',
				create: {
					success:
						'Virtual category with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Virtual category was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting virtual category "{entityName}". Please try again latter...',
					success:
						'Virtual category "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Virtual category with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
			addRack: 'Add Rack',
			manageCategory: 'Manage Category',
			products: 'Products',
		},
		virtualScreen: {
			forms: {
				name: 'name',
				catalogMode: 'Catalog mode',
				create: {
					success:
						'Virtual store with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Virtual store was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting virtual store "{entityName}". Please try again latter...',
					success:
						'Virtual store "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Virtual store with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
		},
		productEditor: {
			productName: 'Product name',
			activateProduct: 'Activate product',
			description: 'Description',
			primaryPhoto: 'Primary photo',
			attributeName: 'Attribute name',
			attributeEanCode: 'Attribute EAN code',
			price: 'Price',
			actionPrice: 'Action price',
			youtubeLink: 'Youtube link',
			forms: {
				create: {
					attribute: {
						generic: {
							success: 'Attribute was successfully added!',
							error: 'There was an error adding an attrbute! Please try again later',
						},
						success: 'Attribute was successfully added!',
						error: 'There was an error with adding an attribute! Please try again later...',
					},
				},
				delete: {
					product: {
						generic: {
							success: 'Product was successfully deleted!',
							error: 'There was an error with deleting! Please try again later',
						},
						error: 'There was an error with deleting product "{entityName}". Please try again later...',
						success:
							'Product "{entityName}" was successfully deleted!',
					},
					products: {
						generic: {
							success: 'Products were successfully deleted!',
							error: 'There was an error with deleting! Please try again later',
						},
					},
				},
				update: {
					generic: {
						success: 'Product was successfully edited!',
						error: 'There was an error with editing! Please try again later',
					},
					success:
						'Product with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
					assignedToShelves:
						'One of the products is assigned to shelf!',
				},
			},
		},
		orders: {
			orderNumber: 'Order number',
			quantity: 'Quantity',
			numItems: 'Number of items',
			status: 'Status',
		},
		multiScreen: {
			label: 'Video Wall',
			edit: 'Edit Video Wall',
			add: 'Add Video Wall',
			manageMultiScreen: 'Manage Video Wall',
			addScreen: 'Add Screen',
			addScreens: 'Add Screens',
			addScreenDescription:
				'Add a Signage Channel or direct a Virtual Rack to the Video Wall',
			signageChannel: 'Signage Channel',
			virtualRack: 'Virtual Rack',
			noScreens: 'No screens added',
			virtualCategoryPoint: 'Categories',
			forms: {
				name: 'name',
				create: {
					success:
						'Video Wall with name "{entityName}" was successfully created!',
				},
				delete: {
					generic: {
						success: 'Video Wall was successfully deleted!',
						error: 'There was an error with deleting! Please try again later',
					},
					error: 'There was an error with deleting Video Wall"{entityName}". Please try again latter...',
					success:
						'Video Wall "{entityName}" was successfully deleted!',
				},
				update: {
					success:
						'Video Wall with name "{entityName}" was successfully edited!',
					error: 'There was an error with editing "{entityName}"! Please try again later',
				},
			},
		},
	},
	layouts: {
		'error-title': 'Ooops... looks like an error occurred!',
		'error-code': 'Error code',
		'go-back-home': 'GO BACK HOME',
	},
	router: {
		add: 'Router add',
		connection: 'Connection',
		cpuUsage: 'CPU Load',
		cpuTemp: 'CPU Temp',
		device: 'Device',
		dhcp: 'DHCP',
		errors: {
			noConnection:
				'More that 5 min NO CONNECTION to {routerName} on location, {rootName} ',
		},
		name: 'Router name',
		uuid: 'UUID',
		ipAddress: 'IP address',
		label: 'Router',
		macAddress: 'MAC',
		memoryUsed: 'Memory Used',
		noData: 'There is no router data for this device token',
		rootId: 'Location',
		root: {
			name: 'Location',
		},
		routerNetwork: {
			dhcpIp: 'IP address',
			ip: 'IP address',
			dns1: 'Primary DNS server',
			dns2: 'Secondary DNS server',
			gateway: 'Default gateway',
			subnet: 'subnetMask',
			wifichannel: 'Selected Channel',
			wifimode: 'Wireless Network Mode',
		},
		settings: {
			connectionType: 'Connection type',
			networkWan: 'Network WAN',
			networkWanDescription:
				'With the default network settings DHCP is activated. In the cases when the IP needs to be static this option can be activated in configured.',
			shelfBarNetwork: 'Shelf Bar Wireless Network (WPA2+CCMP)',
			shelfBarNetworkDescription:
				'The Shelf Bar Access Point creates a hidden and secured internal Wi-Fi network for all Shelf Bar devices in range. There are selectable modes and channels to prevent interference with other devices.',
			staticIp: 'Static IP',
		},
		tabs: {
			connectedDevices: 'Connected devices',
			history: 'History',
		},
		type: 'Type',
		token: 'Device token',
		firmware: 'Firmware upgrade/rollback',
		version: 'Firmware',
		warnings: {
			systemStarting:
				'The system is now starting to load the new settings. It can takes up to 3 minutes.',
		},
		wifiChannel: 'Channel {channel} - {frequency} GHz',
		wifiModes: {
			GHz_2_4: '802.11b/g/n (2.4 GHz)',
			GHz_5: '802.11ac (5 GHz)',
		},
		forms: {
			create: {
				generic: {
					success: 'Router was successfully created!',
					error: 'There was an error with creating! Please try again later',
				},
				success:
					'Router with name "{entityName}" was successfully added!',
				error: 'There was an error with inserting "{entityName}"! Please try again later...',
			},
			delete: {
				generic: {
					success: 'Router was successfully deleted!',
					error: 'There was an error with deleting! Please try again later',
				},
				error: 'There was an error with deleting router "{entityName}". Please try again latter...',
				success: 'Router "{entityName}" was successfully deleted!',
			},
			update: {
				generic: {
					success: 'Router was successfully edited!',
					error: 'There was an error with editing! Please try again later',
				},
				success:
					'Router with name "{entityName}" was successfully edited!',
				error: 'There was an error with editing "{entityName}"! Please try again later',
			},
		},
	},
	licenses: {
		licenses: 'Licenses',
		manageLicenses: 'Manage licenses',
		manageSeats: 'Manage seats',
		users: 'Users',
		seatsInUse: '{numOfUsers} of {maxUsers} seats are in use',
		purchasedModules: 'Purchased modules',
	},
	twoFactor: {
		authenticationKey: 'Authentication Key',
		twoFactorAuthentication: 'Two-Factor Authentication',
		enable2FA: 'Enable 2FA',
		disable2FA: 'Disable 2FA',
		incorrectCode: 'Incorrect 6-digit code',
		checkTimezone:
			'Could not log in. Please ensure that your smartphone timezone settings are configured correctly and try again.',
		enter2FA:
			'Enter the 6-digit code from your Two-Factor Authentication app',
		forms: {
			enable: {
				success: 'Two-Factor Authentication enabled successfully',
				error: 'Could not enable 2FA. Please ensure that the code is inserted correctly and your smartphone timezone settings are configured correctly and try again.',
			},
			disable: {
				success: 'Two-Factor Authentication disabled successfully',
				error: 'Could not disable 2FA. Please ensure that the code is inserted correctly and your smartphone timezone settings are configured correctly and try again.',
			},
			adminDisable: {
				success:
					'Two-Factor Authentication for user {entityName} disabled successfully',
				error: 'Could not disable 2FA for {entityName} at this time. Please try again later.',
			},
		},
		dialogs: {
			disable: {
				title: 'Confirm disabling of 2FA',
				message:
					"This will remove {entityName}'s current Two-Factor Authentication settings.\n Do you wish to continue?",
			},
		},
	},
	shared: {
		add: 'Add',
		back: 'Back',
		Delete: 'Delete',
		addNew: 'Add New',
		selected: 'Selected',
		alarms: 'Alarms',
		batchProccess: 'Batch process',
		basicSettings: 'Basic settings',
		cancel: 'Cancel',
		createLabel: 'Create "{label}"',
		content: 'Content',
		generate: 'Generate',
		settings: 'Settings',
		orders: 'Orders',
		next: 'Next',
		change: 'Change',
		date: 'Date',
		product: 'Product',
		user: 'User',
		type: 'Type',
		time: 'Time',
		unarchive: 'Unarchive',
		created: 'Created',
		device: 'Device',
		disable: 'Disable',
		notSelected: 'Not selected',
		finish: 'Finish',
		locationRestrictions: 'Location restrictions',
		components: {
			customColorPicker: {
				placeholder: 'Select a color by clicking here...',
			},
		},
		daysOfWeek: {
			[`${WeekDay.Monday}_very_short`]: 'M',
			[`${WeekDay.Tuesday}_very_short`]: 'T',
			[`${WeekDay.Wednesday}_very_short`]: 'W',
			[`${WeekDay.Thursday}_very_short`]: 'T',
			[`${WeekDay.Friday}_very_short`]: 'F',
			[`${WeekDay.Saturday}_very_short`]: 'S',
			[`${WeekDay.Sunday}_very_short`]: 'S',
			[`${WeekDay.Monday}_short`]: 'Mon',
			[`${WeekDay.Tuesday}_short`]: 'Tue',
			[`${WeekDay.Wednesday}_short`]: 'Wed',
			[`${WeekDay.Thursday}_short`]: 'Thu',
			[`${WeekDay.Friday}_short`]: 'Fri',
			[`${WeekDay.Saturday}_short`]: 'Sat',
			[`${WeekDay.Sunday}_short`]: 'Sun',
		},
		defaultSelectPlaceholder: 'Select',
		typeToSearch: 'Type to search',
		delete: 'Delete',
		defaultFont: 'Default Font',
		dialogs: {
			delete: {
				title: 'Confirm delete',
				message: 'Do you really wish to delete "{entityName}"?',
				generic: {
					message: 'Do you really wish to delete this entity?',
				},
			},

			lostChanges: {
				title: 'Changes you made will be lost',
				message: 'Do you wish to continue?',
			},
		},
		continue: 'Continue',
		disabled: 'Disabled',
		done: 'Done',
		error: 'Error',
		everyDay: 'Every day',
		edit: 'Edit',
		noData: 'At the moment we have no data to display',
		field: 'Field',
		filters: 'Filters',
		insert: 'Insert',
		logout: 'Log out',
		colors: {
			black: 'Black',
			primary: 'Primary color',
			red: 'Red',
			secondary: 'Secondary color',
			yellow: 'Yellow',
			white: 'White',
		},
		forms: {
			create: {
				generic: {
					success: 'Entity was successfully created!',
					error: 'There was an error with creating! Please try again later',
				},
				success:
					'Entity with name "{entityName}" was successfully added!',
				error: 'There was an error with inserting "{entityName}"! Please try again later...',
			},
			delete: {
				generic: {
					success: 'Entity was successfully deleted!',
					error: 'There was an error with deleting! Please try again later',
				},
				violaterKeyConstraint:
					'Entity {entityName} contains orders or history and cannot be deleted',
				error: 'There was an error with deleting entity "{entityName}". Please try again latter...',
				success: 'Entity "{entityName}" was successfully deleted!',
			},
			update: {
				generic: {
					success: 'Entity was successfully edited!',
					error: 'There was an error with editing! Please try again later',
				},
				success:
					'Entity with name "{entityName}" was successfully edited!',
				error: 'There was an error with editing "{entityName}"! Please try again later',
			},
		},
		fileUpload: {
			chooseFile: 'Click here to choose a file...',
			failed: 'File uploading failed! Please try different files or try at a different time...',
			notValid: 'Files are not valid! Please select other...',
			success: 'File uploading was successfull!',
		},
		dateError: {
			startingAfterEndDate: 'Starting date cannot occur after end date',
			endingBeforeStartDate: 'Ending date cannot occur before start date',
		},
		history: 'History',
		moduleDisabled: {
			headerFirstLine: 'This feature is not available at the moment',
			headerSecondLine: 'Check your access rights',
		},
		offline: 'Offline',
		liveSearch: 'Search',
		ok: 'OK',
		open: 'Open',
		rootId: 'Location',
		text: {
			regular: 'Regular',
			semiBold: 'Semi Bold',
			bold: 'Bold',
		},
		registered: 'Registered',
		save: 'Save',
		seconds: 'seconds',
		saveNew: 'Save',
		selectAll: 'Select all',
		success: 'Success',
		complete: 'Complete',
		refreshing: 'Refreshing',
		validation: {
			email: 'Invalid email address',
			required: 'Field "{field}" is required!',
			youtubeLink: 'Youtube link is not valid!',
		},
		view: 'View',
		shortWeekDays: {
			MONDAY: 'Mon',
			TUESDAY: 'Tue',
			WEDNESDAY: 'Wed',
			THURSDAY: 'Thu',
			FRIDAY: 'Fri',
			SATURDAY: 'Sat',
			SUNDAY: 'Sun',
		},
		update: 'Update',
		userPermissions: 'User permissions',
		warning: 'Warning',
	},
	purchasedModules: {
		[PurchasedModules.Basic]: 'Basic',
		[PurchasedModules.Orbis]: 'Orbis',
		[PurchasedModules.Signage]: 'Signage',
		[PurchasedModules.VirtualStore]: 'VirtualStore',
		[PurchasedModules.Warehouse]: 'Warehouse',
	},
	modeEnum: {
		[ModeEnum.Automatic]: 'Automatic',
		[ModeEnum.Manual]: 'Manual',
	},
	country: {
		[Country.Belgium]: 'Belgium',
		[Country.Bulgaria]: 'Bulgaria',
		[Country.CzechRepublic]: 'Czech Republic',
		[Country.Denmark]: 'Denmark',
		[Country.Germany]: 'Germany',
		[Country.Estonia]: 'Estonia',
		[Country.Ireland]: 'Ireland',
		[Country.Greece]: 'Greece',
		[Country.Spain]: 'Spain',
		[Country.France]: 'France',
		[Country.Croatia]: 'Croatia',
		[Country.Italy]: 'Italy',
		[Country.Cyprus]: 'Cyprus',
		[Country.Latvia]: 'Latvia',
		[Country.Lithuania]: 'Lithuania',
		[Country.Luxembourg]: 'Luxembourg',
		[Country.Hungary]: 'Hungary',
		[Country.Malta]: 'Malta',
		[Country.Netherlands]: 'Netherlands',
		[Country.Austria]: 'Austria',
		[Country.Poland]: 'Poland',
		[Country.Portugal]: 'Portugal',
		[Country.Romania]: 'Romania',
		[Country.Slovenia]: 'Slovenia',
		[Country.Slovakia]: 'Slovakia',
		[Country.Finland]: 'Finland',
		[Country.Sweden]: 'Sweden',
		[Country.UnitedKingdom]: 'United Kingdom',
		[Country.Iceland]: 'Iceland',
		[Country.Liechtenstein]: 'Liechtenstein',
		[Country.Norway]: 'Norway',
		[Country.Switzerland]: 'Switzerland',
	},
	unit: {
		[Unit.Pcs]: 'Pcs',
	},
	orderMode: {
		[OrderMode.Manual]: 'Manual',
		[OrderMode.Automatic]: 'Automatic',
		[OrderMode.Express]: 'Express',
	},
	orderStatus: {
		[OrderStatus.Pending]: 'Ordered',
		[OrderStatus.Delivered]: 'Delivered',
		[OrderStatus.PartiallyDelivered]: 'Partially delivered',
		[OrderStatus.Failed]: 'Canceled',
		[OrderStatus.Processing]: 'In process',
	},
	ledColor: {
		[LedColor.Blue]: 'Blue',
		[LedColor.Cyan]: 'Cyan',
		[LedColor.Green]: 'Green',
		[LedColor.Magenta]: 'Magenta',
		[LedColor.Red]: 'Red',
		[LedColor.White]: 'White',
		[LedColor.Yellow]: 'Yellow',
	},
	blinkTime: {
		[BlinkTime.Short]: 'Short',
		[BlinkTime.Standard]: 'Standard',
		[BlinkTime.Long]: 'Long',
		[BlinkTime.VeryLong]: 'Very long',
	},
	blinkSpeed: {
		[BlinkSpeed.Slow]: 'Slow',
		[BlinkSpeed.Standard]: 'Standard',
		[BlinkSpeed.Fast]: 'Fast',
	},
	logType: {
		[LogType.Create]: 'Create',
		[LogType.Update]: 'Update',
	},
	historyType: {
		[HistoryType.MINUS]: 'Minus',
		[HistoryType.PLUS]: 'Plus',
		[HistoryType.CREATED]: 'Created',
	},
	buttonActions: {
		[ButtonActions.ExpressOrder]: 'Express Order',
	},
	displayType: {
		[DisplayType.BizerbaKhiiPro_800]: 'Bizerba KH II 800 Pro',
		[DisplayType.DibalD900]: 'DIBAL D900',
		[DisplayType.DibalS500]: 'Dibal S500',
		[DisplayType.VirtualStore]: 'Virtual Store',
		[DisplayType.DigiSm6000Ssp]: 'Digi SM6000SSP',
		[DisplayType.DigiSm5300L]: 'Digi SM5300L',
	},
}
