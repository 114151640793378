import React, { FC, SVGProps } from 'react'

export const CircleFilledCancelIcon: FC<SVGProps<any>> = ({
	fill,
	...otherProps
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="4 4 20 20"
		{...otherProps}
	>
		<path
			fill={fill}
			d="M13.5 4c5.238 0 9.5 4.262 9.5 9.5S18.738 23 13.5 23 4 18.738 4 13.5 8.262 4 13.5 4z"
			transform="translate(-1266 -804) translate(1266.935 804.935) rotate(45 13.5 13.5)"
		/>
		<path
			fill="#FFF"
			fillRule="nonzero"
			d="M13.5 8c.38 0 .688.308.688.688v4.124h4.124c.38 0 .688.308.688.688 0 .38-.308.688-.688.688l-4.125-.001v4.126c0 .38-.307.687-.687.687-.38 0-.688-.308-.688-.688v-4.125H8.688c-.38 0-.688-.307-.688-.687 0-.38.308-.688.688-.688h4.124V8.688c0-.38.308-.688.688-.688z"
			transform="translate(-1266 -804) translate(1266.935 804.935) rotate(45 13.5 13.5)"
		/>
	</svg>
)
