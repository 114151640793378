import CustomTextField from './CustomTextField'
/*
export const CustomTextIpField: FC<CustomTextProps> = ({ onChange, value = '', ...otherProps}) => {
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        setCurrentValue(ipPipe(value))
    }, [value])

    const onIpFieldChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (evt) => {
        const textValue = evt.target.value;

        const newValue = pipe(textValue, ipMaskoptions, PIPE_TYPE.MASKED, PIPE_TYPE.TYPED);

        if (value === newValue) {
            const ipPipeValue = ipPipe(textValue);

            setCurrentValue(ipPipeValue);
        }
        else if (onChange) {
            evt.target.value = newValue;

            onChange(evt);
        }
    }

    return <CustomTextField {...otherProps} onChange={onIpFieldChange} value={currentValue} />
}*/

export const CustomTextIpField = CustomTextField
