import { SelectOption } from '@app/@types'
import { WeekDay } from '@app/lib/graphql'

export const daysOfWeek: SelectOption<WeekDay>[] = [
	{
		label: `shared.daysOfWeek.${WeekDay.Monday}_short`,
		value: WeekDay.Monday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Tuesday}_short`,
		value: WeekDay.Tuesday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Wednesday}_short`,
		value: WeekDay.Wednesday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Thursday}_short`,
		value: WeekDay.Thursday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Friday}_short`,
		value: WeekDay.Friday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Saturday}_short`,
		value: WeekDay.Saturday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Sunday}_short`,
		value: WeekDay.Sunday,
	},
]

export const daysOfWeekVeryShort: SelectOption<WeekDay>[] = [
	{
		label: `shared.daysOfWeek.${WeekDay.Monday}_very_short`,
		value: WeekDay.Monday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Tuesday}_very_short`,
		value: WeekDay.Tuesday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Wednesday}_very_short`,
		value: WeekDay.Wednesday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Thursday}_very_short`,
		value: WeekDay.Thursday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Friday}_very_short`,
		value: WeekDay.Friday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Saturday}_very_short`,
		value: WeekDay.Saturday,
	},
	{
		label: `shared.daysOfWeek.${WeekDay.Sunday}_very_short`,
		value: WeekDay.Sunday,
	},
]

export const allWeekDays = daysOfWeekVeryShort.map((q) => q.value)
