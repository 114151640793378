import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
	})
)

export const PageSection: FC = ({ children }) => {
	const classes = useStyles()

	return <Box className={classes.container}>{children}</Box>
}
