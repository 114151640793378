import { TranslateMessage } from '@app/lib/lang'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import {
	CustomFormAgitronSwitch,
	CustomFormAgitronSwitchProps,
} from './CustomFormAgitronSwitch'
import { useCustomForm } from './hooks'

const useStyles = makeStyles((theme) =>
	createStyles({
		text: {
			fontSize: 12,
			marginRight: theme.spacing(2),
			textTransform: 'uppercase',
		},
	})
)

interface CustomFormLabelAgitronSwitch extends CustomFormAgitronSwitchProps {
	label?: string
}

export const CustomFormLabelAgitronSwitch: FC<CustomFormLabelAgitronSwitch> = ({
	disabled: disabledFromProps,
	label,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const styles = useStyles()

	return (
		<Box display="flex" alignItems="center" flexDirection="row">
			{label ? (
				<Typography className={styles.text}>
					<TranslateMessage id={label} />
				</Typography>
			) : null}
			<CustomFormAgitronSwitch
				disabled={disabled || disabledFromProps}
				{...otherProps}
			/>
		</Box>
	)
}
