import { FC, useEffect } from 'react'
import { useField } from 'formik'
import CustomTextField, { CustomTextProps } from '../CustomTextField'
import { CustomTextIpField } from '../CustomTextIpField'
import { CustomTextNumberField } from '../CustomTextNumberField'
import { useCustomForm } from './hooks'
import { OutlinedInputProps } from '@mui/material'

interface CustomFormTextFieldProps extends CustomTextProps {
	name: string
	isPassword?: boolean
	style?: OutlinedInputProps
	initialValue?: string
}

export const CustomFormTextField: FC<CustomFormTextFieldProps> = ({
	disabled: disabledFromProps,
	name,
	onChange,
	onBlur,
	type,
	isPassword,
	initialValue,
	...otherProps
}) => {
	const { disabled } = useCustomForm()

	const [field, _, helpers] = useField({
		name,
	})

	useEffect(() => {
		if (initialValue) helpers.setValue(initialValue)
	}, [])

	const onTextChange = (e: React.ChangeEvent<any>) => {
		field.onChange(e)

		if (onChange) {
			onChange(e)
		}
	}

	if (type === 'ip') {
		return (
			<CustomTextIpField
				{...otherProps}
				disabled={disabled || disabledFromProps}
				name={name}
				onBlur={field.onBlur}
				onChange={onTextChange}
				type="text"
				value={field.value || ''}
			/>
		)
	} else if (type === 'number') {
		return (
			<CustomTextNumberField
				{...otherProps}
				disabled={disabled || disabledFromProps}
				name={name}
				onBlur={field.onBlur}
				onChange={onTextChange}
				value={field.value}
			/>
		)
	}
	return (
		<CustomTextField
			{...otherProps}
			disabled={disabled || disabledFromProps}
			name={name}
			onBlur={field.onBlur}
			onChange={onTextChange}
			type={type}
			isPassword={isPassword}
			value={field.value || ''}
		/>
	)
}
