export const themeColors = {
	backgroundColor: '#f5f5f5',
	hoverColor: '#eeeeee',
	borderColor: '#dddddd',
	blueGrey: '#87a0b2',
	brownishGrey: '#646464',
	dandelion: '#ffea03',
	dark: '#1b2c36',
	light: '#f8f8f8',
	white: '#ffffff',
	yellow: '#edbb1d',
	green: '#2dc76d',
	agitronDark: '#15264c',
	agitronLight: '#17aad8',
	gunMetal: '#4e5d68',
	midBlue: '#1d65a9',
	paleGrey: '#e4eaec',
	pinkishGrey: '#bababa',
	seaFormGreen: '#6ece97',
	silver: '#cbd3d5',
	vermillion: '#ed1d1d',
	warmGrey: '#979797',
	warmPurple: '#5e2b7f',
	errorRed: '#DC143C',
	darkGrey: '#898989',
	black: '#000000',
}
