import { useField } from 'formik'
import React, { FC } from 'react'
import NumInput, { NumInputProps } from '../NumInput'
import { useCustomForm } from './hooks'

interface CustomFormNumberInputProps
	extends Omit<NumInputProps, 'value' | 'onChange'> {
	name: string
}

export const CustomFormNumberInput: FC<CustomFormNumberInputProps> = ({
	disabled: disabledFromProps,
	name,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const [field, , helpers] = useField({
		name,
	})

	const onNumberInputChange = (value: number) => {
		helpers.setValue(value)
	}

	return (
		<NumInput
			{...otherProps}
			disabled={disabled || disabledFromProps}
			onChange={onNumberInputChange}
			value={field.value}
		/>
	)
}
