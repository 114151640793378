import { useContext } from 'react'

import {
	useCustomDialogInterface,
	CustomDialogType,
	CustomDialogOptions,
	CustomDialogButtonType,
} from './types'

import { CustomDialogContext } from './CustomDialogProvider'

export const useCustomDialog: useCustomDialogInterface = () => {
	const { addDialog, closeDialog } = useContext(CustomDialogContext)

	const addErrorDialog = (customDialog: CustomDialogOptions) => {
		return addDialog({
			dialogType: CustomDialogType.error,
			...customDialog,
		})
	}

	const addDeleteConfirmationDialog = (
		entityTitle: string,
		customDialog: CustomDialogOptions
	) => {
		const dialogId = addDialog({
			headerTextTranslate: 'shared.dialogs.delete.title',
			messageTextTranslate: 'shared.dialogs.delete.message',
			messageTranslateVariables: {
				entityName: entityTitle,
			},
			dialogType: CustomDialogType.warning,
			buttons: [
				{
					buttonType: CustomDialogButtonType.cancel,
					textTranslate: 'shared.cancel',
				},
				{
					buttonType: CustomDialogButtonType.ok,
					textTranslate: 'shared.delete',
				},
			],
			...customDialog,
		})

		return dialogId
	}

	const addInfoDialog = (customDialog: CustomDialogOptions) => {
		return addDialog({
			dialogType: CustomDialogType.info,
			...customDialog,
		})
	}

	const addSuccessDialog = (customDialog: CustomDialogOptions) => {
		return addDialog({
			dialogType: CustomDialogType.error,
			...customDialog,
		})
	}

	const addWarningDialog = (customDialog: CustomDialogOptions) => {
		return addDialog({
			buttons: [
				{
					buttonType: CustomDialogButtonType.cancel,
					textTranslate: 'shared.cancel',
				},
				{
					buttonType: CustomDialogButtonType.ok,
					textTranslate: 'shared.ok',
				},
			],
			...customDialog,
		})
	}

	const addLeavePageConfirmationDialog = (
		customDialog: CustomDialogOptions
	) => {
		const dialogId = addDialog({
			headerTextTranslate: 'shared.dialogs.lostChanges.title',
			messageTextTranslate: 'shared.dialogs.lostChanges.message',

			dialogType: CustomDialogType.warning,
			buttons: [
				{
					buttonType: CustomDialogButtonType.cancel,
					textTranslate: 'shared.cancel',
				},
				{
					buttonType: CustomDialogButtonType.ok,
					textTranslate: 'shared.continue',
				},
			],
			...customDialog,
		})

		return dialogId
	}

	return {
		addDeleteConfirmationDialog,
		addDialog,
		addErrorDialog,
		addInfoDialog,
		addSuccessDialog,
		addWarningDialog,
		closeDialog,
		addLeavePageConfirmationDialog,
	}
}
