import { Form, Formik, FormikProps, isFunction, useFormikContext } from 'formik'
import { FC, ReactNode } from 'react'
import { CustomFormProvider } from './hooks'

type CustomFormRenderFunctionType<TValues = any> = (
	props: FormikProps<TValues>
) => ReactNode

export interface CustomFormProps<TValues = any> {
	children: CustomFormRenderFunctionType<TValues> | ReactNode
	debug?: boolean
	disabled?: boolean
	innerRef?: (ref: any) => void
	initialValues?: TValues
	includeFormElement?: boolean
	onSubmit?: (formValues: TValues) => void
	enableReinitialize?: boolean
	validationSchema?: any
}

const FormValuesDebug: FC = () => {
	const { values } = useFormikContext()

	return <pre>{JSON.stringify(values)}</pre>
}

export function CustomForm<TValues = any>({
	debug,
	disabled,
	children,
	innerRef,
	includeFormElement = true,
	initialValues = {} as unknown as any,
	onSubmit,
	enableReinitialize,
	validationSchema,
}: CustomFormProps<TValues>) {
	const onFormSubmit = (formValues: any) => {
		if (onSubmit) {
			onSubmit(formValues)
		}
	}

	if (isFunction(children)) {
		return (
			<CustomFormProvider disabled={disabled}>
				<Formik
					innerRef={innerRef}
					initialValues={initialValues}
					onSubmit={onFormSubmit}
					enableReinitialize={enableReinitialize}
					validationSchema={validationSchema}
				>
					{(props) =>
						includeFormElement ? (
							<Form>
								{debug && <FormValuesDebug />}
								{(children as CustomFormRenderFunctionType)(
									props
								)}
							</Form>
						) : (
							<>
								{debug && <FormValuesDebug />}
								{(children as CustomFormRenderFunctionType)(
									props
								)}
							</>
						)
					}
				</Formik>
			</CustomFormProvider>
		)
	}

	return (
		<CustomFormProvider disabled={disabled}>
			<Formik
				innerRef={innerRef}
				initialValues={initialValues}
				onSubmit={onFormSubmit}
				enableReinitialize={enableReinitialize}
				validationSchema={validationSchema}
			>
				{({ dirty }) => {
					// setIsDirty(dirty)
					return (
						<>
							{includeFormElement ? (
								<Form>
									{debug && <FormValuesDebug />}
									{children}
								</Form>
							) : (
								<>
									{debug && <FormValuesDebug />}
									{children}
								</>
							)}
						</>
					)
				}}
			</Formik>
		</CustomFormProvider>
	)
}
