import React, { FC, useEffect, useState } from 'react'
import { getImage } from '@app/lib/services/getImage.service'
import { getBase64ImageUrl } from '@app/lib/imageUtility'
interface CustomImageProps {
	className?: string
	filename?: string | null
	width?: string | number
	height?: string | number
	thumbnail?: string | null
	style?: any
}
export const CustomImage: FC<CustomImageProps> = ({
	className,
	filename,
	width,
	thumbnail,
	height,
	style,
}) => {
	const [image, setImage] = useState<null | string>(null)
	useEffect(() => {
		const fetchImage = async () => {
			const data = await getImage(filename!)
			if (data) setImage(URL.createObjectURL(data))
		}
		if (filename) fetchImage()
	}, [filename])

	return (
		<>
			{image ? (
				<img
					className={className}
					src={image}
					width={width}
					height={height}
					style={style}
				/>
			) : (
				<img
					src={getBase64ImageUrl(
						thumbnail || '/images/image-placeholder.png'
					)}
					className={className}
					width={width}
					height={height}
					style={style}
				/>
			)}
		</>
	)
}
