import { themeColors } from '@app/constants'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
		},
		divider: {
			backgroundColor: themeColors.borderColor,
			display: 'flex',
			alignSelf: 'center',
			height: '3px',
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			width: theme.spacing(2),
		},
		side: {
			flexGrow: 1,
		},
	})
)

interface CustomRowDivider {
	leftSide: JSX.Element
	rightSide: JSX.Element
}

export const CustomRowDivider: FC<CustomRowDivider> = ({
	leftSide,
	rightSide,
}) => {
	const classes = useStyles()

	return (
		<Box className={classes.container}>
			<Box className={classes.side}>{leftSide}</Box>
			<Box className={classes.divider} />
			<Box className={classes.side}>{rightSide}</Box>
		</Box>
	)
}
