import { useSnackbar } from 'notistack'

import { useAlertsInterface, AlertType, AlertMessage } from './types'
import { KeyValueObject } from '@app/@types'
import { useTranslation } from '@app/lib/lang'
import { createGuid } from '@app/lib/utils'

export const useAlerts: useAlertsInterface = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const { t } = useTranslation()

	const addAlert = (alertMessage: AlertMessage) => {
		alertMessage.id = alertMessage.id || createGuid()
		alertMessage.alertType = alertMessage.alertType || AlertType.info
		alertMessage.autoHideDuration = alertMessage.autoHideDuration || 5000

		enqueueSnackbar(
			t(
				alertMessage.messageTranslate || '',
				alertMessage.messageTranslateVariables
			),
			{
				key: alertMessage.id,
				autoHideDuration: alertMessage.autoHideDuration,
				variant: alertMessage.alertType,
				// ContentProps: {
				//     classes: {
				//         root: getCssClassFromAlertType(alertMessage.alertType)
				//     }
				// }
			}
		)

		return alertMessage.id
	}

	const addErrorAlert = (
		messageTranslate: string,
		messageTranslateVariables?: KeyValueObject
	) => {
		return addAlert({
			messageTranslate,
			messageTranslateVariables,
			alertType: AlertType.error,
		})
	}

	const addInfoAlert = (
		messageTranslate: string,
		messageTranslateVariables?: KeyValueObject
	) => {
		return addAlert({
			messageTranslate,
			messageTranslateVariables,
			alertType: AlertType.info,
		})
	}

	const addSuccessAlert = (
		messageTranslate: string,
		messageTranslateVariables?: KeyValueObject
	) => {
		return addAlert({
			messageTranslate,
			messageTranslateVariables,
			alertType: AlertType.success,
		})
	}

	const addWarningAlert = (
		messageTranslate: string,
		messageTranslateVariables?: KeyValueObject
	) => {
		return addAlert({
			messageTranslate,
			messageTranslateVariables,
			alertType: AlertType.warning,
		})
	}

	const removeAlert = (alertId: string) => {
		closeSnackbar(alertId)
	}

	return {
		addAlert,
		addErrorAlert,
		addInfoAlert,
		addSuccessAlert,
		addWarningAlert,
		removeAlert,
	}
}
