import React, { FC, useEffect, useState } from 'react'
import { pipe, PIPE_TYPE } from 'imask'

import { numberPipe, numberPipeMaskOptions } from '@app/lib/masks'

import CustomTextField, { CustomTextProps } from './CustomTextField'

export const CustomTextNumberField: FC<CustomTextProps> = ({
	name,
	onChange,
	value = '',
	decimals = false,
	...otherProps
}) => {
	const [currentValue, setCurrentValue] = useState('')

	useEffect(() => {
		if (value === undefined || value === null) {
			value = ''
		}

		setCurrentValue(numberPipe(value?.toString()))
	}, [value])

	const onIpFieldChange: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	> = (evt) => {
		let textValue = evt.target.value

		if (textValue && textValue.includes(',')) {
			textValue = textValue.replace(/\,/g, '.')
		}

		const newValue = pipe(
			textValue,
			numberPipeMaskOptions,
			PIPE_TYPE.MASKED,
			PIPE_TYPE.TYPED
		)

		if (value === newValue) {
			const pipeValue = numberPipe(textValue)

			setCurrentValue(pipeValue)
		} else if (onChange) {
			onChange({
				target: {
					name,
					value: decimals ? numberPipe(textValue) : textValue,
				},
			} as any)
		}
	}

	return (
		<CustomTextField
			{...otherProps}
			name={name}
			onChange={onIpFieldChange}
			value={currentValue}
		/>
	)
}
