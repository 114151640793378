import {
	fromTotalSecondsToDate,
	getTotalSecondsOfCurrentTime,
} from '@app/lib/dateUtilility'
import { TextField } from '@mui/material'
import { isValid } from 'date-fns'
import React, { FC } from 'react'
import { LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export interface CustomDurationPickerProps {
	onChange?: (newDuration: number) => void
	className?: string
	size?: 'medium' | 'small'
	value?: number
	innerTextFieldClasses?: any
}

export const CustomDurationPicker: FC<CustomDurationPickerProps> = ({
	onChange,
	size = 'medium',
	value,
	innerTextFieldClasses,
}) => {
	const onTimeChange = (date: Date | null) => {
		if (onChange && isValid(date)) {
			const totalSeconds = getTotalSecondsOfCurrentTime(date as Date)

			onChange(totalSeconds)
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<TimePicker
				ampm={false}
				value={fromTotalSecondsToDate(value as number) || null}
				onChange={onTimeChange}
				inputFormat="HH:mm:ss"
				views={['hours', 'minutes', 'seconds']}
				mask="__:__:__"
				renderInput={(params) => (
					<TextField
						{...params}
						size={size}
						className={innerTextFieldClasses}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}
