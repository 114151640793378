import React, { FC } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import loaderData from '../lib/lotties/loader.json'
import Lottie from 'react-lottie'

const useStyles = makeStyles(() =>
	createStyles({
		wrapper: {
			backgroundColor: 'rgba(255,255,255, 0.5)',
			display: 'flex',
			justifyContent: 'center',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 3,
			alignItems: 'center',
			overflow: 'hidden',
		},
	})
)

interface CustomLoaderProps {
	showLoader?: boolean
}

export const CustomLoader: FC<CustomLoaderProps> = ({ showLoader = true }) => {
	const { wrapper } = useStyles()

	if (!showLoader) {
		return null
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loaderData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	return (
		<Box className={wrapper}>
			<Lottie options={defaultOptions} height={200} width={200} />
		</Box>
	)
}
