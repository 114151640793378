import { SelectOption } from '@app/@types'
import { getDotNotationPropertyValue, isArray } from '@app/lib/utils'
import { useField } from 'formik'
import React, { FC, useState, useEffect } from 'react'
import { InputActionMeta } from 'react-select'
import CustomSelect, { CustomSelectProps } from '../CustomSelect'

interface CustomFormSelectProps extends CustomSelectProps {
	name: string
	multiple?: boolean
	initialValue?: any
	allValues?: boolean
	nullable?: boolean
	storeFullValue?: boolean
}

export const CustomFormSelect: FC<CustomFormSelectProps> = ({
	disabled: disabledFromProps,
	name,
	options = [],
	onChange,
	onInputChange,
	labelKey = 'label',
	valueKey = 'value',
	multiple = false,
	initialValue,
	allValues,
	nullable = true,
	storeFullValue = false,
	...otherProps
}) => {
	const [searchLabel, setSearchLabel] = useState<string>()
	const [field, , helpers] = useField({ name })
	const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])

	const filteredItems = searchLabel
		? options.filter((q) => {
				const label = getDotNotationPropertyValue<string>(q, labelKey)

				if (label) {
					return label
						.toLowerCase()
						.includes(searchLabel.toLowerCase())
				}

				return false
		  })
		: options

	const getSelectItem = (item: any): SelectOption => {
		let label = item

		if (allValues) {
			return {
				...item,
				label,
				value: getValue(item),
			}
		}

		return {
			label,
			value: getValue(item),
		}
	}

	const getValue = (item: any) => {
		let value = item

		if (valueKey) {
			value = getDotNotationPropertyValue(item, valueKey)
		}

		return value
	}

	useEffect(() => {
		let foundOptions: SelectOption[] = []

		if (multiple) {
			if (
				initialValue &&
				(!isArray(initialValue) || (initialValue as any[]).length)
			) {
				if (isArray(initialValue)) {
					foundOptions = initialValue.map(getSelectItem)
				} else {
					foundOptions = [getSelectItem(initialValue)]
				}
			} else {
				foundOptions = ((field.value as any[]) || []).map((value) => {
					let foundOption
					if (storeFullValue && typeof value === 'object') {
						foundOption = value
					} else {
						foundOption =
							options.find((q) => q.value === value) ||
							selectedOptions.find((q) => q.value === value)
					}

					if (foundOption) {
						return foundOption
					}

					if (initialValue && isArray(initialValue)) {
						const foundInitialValue = (initialValue as any[]).find(
							(q) => getValue(q) === field.value
						)

						if (foundInitialValue) {
							return getSelectItem(foundInitialValue)
						}
					}

					return {
						value,
						label: value,
					}
				})
			}
		} else {
			let foundOption
			if (storeFullValue && typeof field.value === 'object') {
				foundOption = field.value
			} else {
				foundOption =
					options.find((q) => q.value === field.value) ||
					selectedOptions.find((q) => q.value === field.value)
			}

			if (
				!foundOption &&
				initialValue &&
				getValue(initialValue) === field.value
			) {
				foundOption = getSelectItem(initialValue)
			}

			if (foundOption) {
				foundOptions = [foundOption]
			}
		}

		setSelectedOptions(foundOptions)
	}, [field.value, options])

	const onSelectInputChange = (
		input: string,
		actionMeta: InputActionMeta
	) => {
		setSearchLabel(input)

		if (onInputChange) {
			onInputChange(input, actionMeta)
		}
	}

	const selectedItem = multiple
		? options.filter((q) => {
				if (valueKey) {
					return (
						getDotNotationPropertyValue(q, valueKey) === field.value
					)
				}

				return q === field.value
		  })
		: options.find((q) => {
				if (valueKey) {
					return (
						getDotNotationPropertyValue(q, valueKey) === field.value
					)
				}

				return q === field.value
		  })

	const onSelectChange = (selectedOption: SelectOption | SelectOption[]) => {
		if (multiple) {
			helpers.setValue(
				storeFullValue
					? ((selectedOption || []) as SelectOption[])
					: ((selectedOption || []) as SelectOption[]).map((q) =>
							getDotNotationPropertyValue(q, valueKey)
					  )
			)
		} else {
			const selectedValue = getDotNotationPropertyValue(
				selectedOption,
				valueKey
			)

			helpers.setValue(
				storeFullValue
					? (selectedOption as SelectOption)
					: selectedValue
			)

			if (onChange) {
				onChange(
					selectedOption,
					selectedOption
						? options.find(
								(q) =>
									getDotNotationPropertyValue(q, valueKey) ===
									selectedValue
						  )
						: undefined
				)
			}
		}
	}
	return (
		<CustomSelect
			{...otherProps}
			disabled={disabledFromProps}
			labelKey={labelKey}
			options={filteredItems}
			onInputChange={onSelectInputChange}
			onChange={onSelectChange}
			selected={multiple ? selectedOptions : selectedItem}
			valueKey={valueKey}
			multiple={multiple}
		/>
	)
}
