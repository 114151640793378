import { BlinkSpeed, BlinkTime, LedColor, useMeQuery } from '@app/lib/graphql'
import { createContext, PropsWithChildren, useMemo } from 'react'
import { useStickyState } from '@app/lib/packages/stickyState'
import {
	CurrentUserContextState,
	CurrentUserStickyState,
	currentUserStickyStateKey,
} from './types'

const initialContextState: CurrentUserContextState = {
	permissions: [],
	setDefaultUserSettings: () => {},
	deleteDefaultUserSettings: () => {},
}

export const CurrentUserContext = createContext(initialContextState)

export const CurrentUserProvider = ({ children }: PropsWithChildren<any>) => {
	const { data, loading } = useMeQuery()
	const [currentUserStickyState, setCurrentUserStickyState] =
		useStickyState<CurrentUserStickyState>(currentUserStickyStateKey)

	const setDefaultUserSettings = (
		newDefaultRootId?: number | null,
		filters?: any,
		tableName?: string
	) => {
		if (tableName && filters) {
			setCurrentUserStickyState({
				...(currentUserStickyState || {}),
				defaultRootId:
					newDefaultRootId || newDefaultRootId === null
						? newDefaultRootId
						: currentUserStickyState?.defaultRootId,
				filters: {
					...currentUserStickyState?.filters,
					[tableName]: filters,
				},
			})
		} else
			setCurrentUserStickyState({
				...(currentUserStickyState || {}),
				defaultRootId:
					newDefaultRootId || newDefaultRootId === null
						? newDefaultRootId
						: currentUserStickyState?.defaultRootId,
			})
	}
	const deleteDefaultUserSettings = () => {
		setCurrentUserStickyState({
			...(currentUserStickyState || {}),
			defaultRootId: undefined,
			filters: undefined,
		})
	}

	const values = useMemo<CurrentUserContextState>(() => {
		if (data?.me) {
			const {
				permissions,
				allowedIPs,
				roles,
				rootIDs,
				ledConfig,
				...otherProps
			} = data.me

			return {
				currentUser: {
					...otherProps,
					allowedIPs: allowedIPs || [],
					defaultRootId:
						currentUserStickyState?.defaultRootId || undefined,
					filters: currentUserStickyState?.filters,
					roles: roles || [],
					rootIDs: rootIDs || [],
					ledConfig: ledConfig ?? {
						blinkSpeed: BlinkSpeed.Standard,
						blinkTime: BlinkTime.Standard,
						color: LedColor.Red,
					},
				},
				permissions: permissions || [],
				setDefaultUserSettings,
				deleteDefaultUserSettings,
			}
		}

		return initialContextState
	}, [data, currentUserStickyState])

	return (
		<CurrentUserContext.Provider value={values}>
			{loading ? null : <>{children}</>}
		</CurrentUserContext.Provider>
	)
}
