import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Switch, { SwitchProps } from '@mui/material/Switch'

export const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }: SwitchProps) => {
	return (
		<Switch
			// focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes!.root,
				switchBase: classes!.switchBase,
				thumb: classes!.thumb,
				track: classes!.track,
				checked: classes!.checked,
			}}
			{...props}
		/>
	)
})

export const AgitronSwitch = withStyles((theme) => ({
	root: {
		width: 58,
		height: 28,
		padding: 0,
		display: 'flex',
	},
	switchBase: {
		'&:hover': {
			background: 'transparent',
		},
		padding: 5,
		color: theme.palette.grey[500],
		'&$checked': {
			transform: 'translateX(30px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.secondary.dark,
				borderColor: theme.palette.secondary.dark,
			},
		},
	},
	thumb: {
		width: 18,
		height: 18,
		boxShadow: 'none',
	},
	track: {
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 14,
		opacity: 1,
		backgroundColor: theme.palette.common.white,
	},
	checked: {},
}))(Switch)
