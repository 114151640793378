import classNames from 'classnames'
import {
	CircleFilledCancelIcon,
	CircleFilledCheckmarkIcon,
	WarningIcon,
} from '@app/assets/icons'
import { themeColors } from '@app/constants'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			alignItems: 'center',
			backgroundColor: themeColors.dandelion,
			display: 'flex',
			padding: `${theme.spacing(2.5)} ${theme.spacing(2.5)}`,
		},
		icon: {
			cursor: 'pointer',
			fontSize: theme.spacing(3),
		},
		warningIcon: {
			fontSize: theme.spacing(2.5),
		},
	})
)

interface CustomWarningProps {
	className?: string
	visible?: boolean
	onCancel?: () => void
	onConfirm?: () => void
}

export const CustomWarning: FC<CustomWarningProps> = ({
	className,
	children,
	onCancel,
	onConfirm,
	visible = true,
}) => {
	const classes = useStyles()

	if (!visible) {
		return null
	}

	return (
		<Box className={classNames(classes.root, className)}>
			<WarningIcon className={classes.warningIcon} fill="black" />
			<Box ml={2} mr={4} flexGrow={1}>
				{children}
			</Box>
			<Box display="flex" alignItems="center">
				<CircleFilledCancelIcon
					className={classes.icon}
					fill={themeColors.vermillion}
					onClick={onCancel}
				/>
				<Box display="flex" ml={1.5}>
					<CircleFilledCheckmarkIcon
						className={classes.icon}
						fill={themeColors.seaFormGreen}
						onClick={onConfirm}
					/>
				</Box>
			</Box>
		</Box>
	)
}
