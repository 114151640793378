import { CustomButton, CustomButtonProps } from './CustomButton'

export function SubmitButton({
	onClick,
	size = 'large',
	type = 'submit',
	...otherProps
}: CustomButtonProps) {
	const onSubmitButtonClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<CustomButton
			{...otherProps}
			onClick={onSubmitButtonClick}
			size={size}
			type={type}
		/>
	)
}
