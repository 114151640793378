import React, { FC, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { OnErrorFn } from '@formatjs/intl'

import { objectToDotNotation } from '@app/lib/utils'

import { translations } from './translations'

export const LocaleProvider: FC = (props) => {
	const currentLocale = 'en'

	const messages = useMemo(
		() => objectToDotNotation(translations[currentLocale]),
		[currentLocale]
	)

	const onError: OnErrorFn = (err) => {
		if (err.code === 'MISSING_TRANSLATION') {
			if (process.env.NODE_ENV === 'development') {
				console.warn(err.message)
			}
		} else {
			throw err
		}
	}

	return (
		<IntlProvider
			locale={currentLocale}
			messages={messages}
			onError={onError}
		>
			{props.children}
		</IntlProvider>
	)
}
