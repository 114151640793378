import { useContext } from 'react'
import { CurrentUserContext } from './CurrentUserProvider'
import { useCurrentUserType } from './types'

export const useCurrentUser: useCurrentUserType = () => {
	const { currentUser, setDefaultUserSettings, deleteDefaultUserSettings } =
		useContext(CurrentUserContext)

	return {
		currentUser,
		setDefaultUserSettings,
		deleteDefaultUserSettings,
	}
}
