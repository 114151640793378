import React, { createContext, FC, useState } from 'react'

import { CustomDialogContextState, CustomDialogOptions } from './types'

import { CustomDialogContainer } from '../components'
import { createGuid } from '@app/lib/utils'

export const CustomDialogContext = createContext<CustomDialogContextState>({
	dialogs: [],
	defaultAutoHideAfterOk: true,
	addDialog: () => {
		return ''
	},
	closeDialog: () => {},
})

interface CustomDialogProviderProps {
	defaultAutoHideAfterOk?: boolean
}

export const CustomDialogProvider: FC<CustomDialogProviderProps> = (props) => {
	let { defaultAutoHideAfterOk = true } = props

	const [dialogs, setDialogs] = useState<CustomDialogOptions[]>([])

	const addDialog = (dialog: CustomDialogOptions) => {
		if (!dialog.id) {
			dialog.id = createGuid()
		}

		setDialogs([...dialogs, dialog])

		return dialog.id!
	}

	const closeDialog = () => {
		setDialogs([])
	}

	const contextValue: CustomDialogContextState = {
		dialogs,
		defaultAutoHideAfterOk,
		addDialog,
		closeDialog,
	}

	return (
		<CustomDialogContext.Provider value={contextValue}>
			<CustomDialogContainer />
			{props.children}
		</CustomDialogContext.Provider>
	)
}
