import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
	})
)

interface PageContentSectionProps {
	className?: string
}

export const PageContentSection: FC<PageContentSectionProps> = ({
	children,
}) => {
	const classes = useStyles()

	return <Box className={classes.container}>{children}</Box>
}
