import { FC, ReactElement, useEffect, useState } from 'react'

export const ClientOnly: FC = ({ children }) => {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true)
		}
	}, [])

	if (!isMounted) {
		return null
	}

	return children as ReactElement
}
