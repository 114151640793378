import React from 'react'
import { Stack, styled } from '@mui/material'
import IcomoonReact from 'icomoon-react'
import iconSet from '../../src/assets/icons/selection.json'

interface IconProps {
	color?: string
	size?: string | number
	icon: string
	className?: string
	outline?: boolean
}

const IconWrapper = styled(Stack)(() => ({
	width: 'max-content',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 9,
	borderRadius: '50%',
	backgroundColor: '#efeff0',
}))

export default function Icon(props: IconProps) {
	const {
		color,
		size = '24px',
		icon,
		className = '',
		outline = false,
	} = props

	if (outline) {
		return (
			<IconWrapper>
				<IcomoonReact
					className={className}
					iconSet={iconSet}
					color={color}
					size={size}
					icon={icon}
				/>
			</IconWrapper>
		)
	}

	return (
		<IcomoonReact
			className={className}
			iconSet={iconSet}
			color={color}
			size={size}
			icon={icon}
		/>
	)
}
