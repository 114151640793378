import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

interface useUpdateEffectInterface {
	(effect: EffectCallback, deps?: DependencyList): void
}

export const useUpdateEffect: useUpdateEffectInterface = (effect, deps) => {
	const isInitialMount = useRef(true)

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			effect()
		}
	}, deps)
}
