import React, { FC } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CustomIconFromValue } from '@app/components'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			textDecoration: 'none',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: theme.spacing(3) + 1,
			cursor: 'pointer',
			border: `solid 1.5px ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
			fontWeight: 'normal',
			paddingLeft: 0,
			paddingBottom: 0,
			paddingTop: 0,
			fontSize: '13px',
			position: 'relative',
			width: 'auto',
		},
		iconContainer: {
			marginLeft: '2px',
		},
		icon: {
			display: 'flex',
			alignItems: 'center',
		},
	})
)

interface CustomActionButtonProps {
	icon?: string
	onClick?: () => void
	href?: string
}

export const CustomActionButton: FC<CustomActionButtonProps> = ({
	children,
	icon,
	onClick,
	href,
}) => {
	const classes = useStyles()
	const theme = useTheme()

	return (
		<Button
			className={classes.root}
			onClick={href ? undefined : onClick}
			href={href}
		>
			{icon && (
				<Box className={classes.iconContainer}>
					<CustomIconFromValue
						className={classes.icon}
						icon={icon}
						style={{ color: theme.palette.primary.main }}
					/>
				</Box>
			)}
			<Box display="flex" justifyContent="center" flexGrow="1" mx={2}>
				{children}
			</Box>
		</Button>
	)
}
