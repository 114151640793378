import { isSSR } from '@app/lib/utils'
import { useState } from 'react'
import { useStickyStateInterface } from './types'
import { getLocalStorageValue } from './utils'

export const useStickyState: useStickyStateInterface = <T = any>(
	key: string,
	initialValue?: T
) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T | undefined>(() =>
		getLocalStorageValue(key, initialValue)
	)

	const setValue = (value: T | ((val: T) => T)) => {
		try {
			const valueToStore =
				value instanceof Function ? value(storedValue as T) : value

			setStoredValue(valueToStore)

			if (!isSSR()) {
				window.localStorage.setItem(key, JSON.stringify(valueToStore))
			}
		} catch (e) {
			console.log(e)
		}
	}

	return [storedValue, setValue]
}
