import { Action_Type, Module_Type } from '@app/lib/graphql'
import { PropsWithChildren } from 'react'
import { usePermissions } from '../hooks'

type PermissionCheckProps = {
	actionType?: Action_Type
	moduleType: Module_Type
}

export const PermissionCheck = ({
	children,
	actionType,
	moduleType,
}: PropsWithChildren<PermissionCheckProps>) => {
	const { hasModulePermission, hasModuleActionPermission } = usePermissions()

	if (!actionType) {
		if (!hasModulePermission(moduleType)) {
			return null
		}
	} else if (!hasModuleActionPermission(moduleType, actionType)) {
		return null
	}

	return <>{children}</>
}
