import { KeyValueObject } from '@app/@types'
import { Action_Type, Module_Type } from '@app/lib/graphql'

export const getPermissionName = (
	moduleType: Module_Type,
	actionType?: Action_Type
) => (actionType ? `${actionType}:${moduleType}` : moduleType)

export const gerPermissionMapFromNames = (permissionNames: string[]) => {
	if (!permissionNames?.length) {
		return []
	}

	const permissionMap: KeyValueObject<Action_Type[]> = {}

	permissionNames.forEach((permissionName) => {
		const [actionType, moduleType] = permissionName.split(':')

		if (moduleType) {
			if (!permissionMap[moduleType]) {
				permissionMap[moduleType] = []
			}

			if (
				actionType &&
				!permissionMap[moduleType].includes(actionType as Action_Type)
			) {
				permissionMap[moduleType].push(actionType as Action_Type)
			}
		}
	})

	return permissionMap
}
