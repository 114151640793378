export const getImage = async (filename: string) => {
	const IMAGE_URL =
		process.env.NODE_ENV === 'development'
			? `https://staging.agitron.dev/storage/access/`
			: window.location.protocol +
			  '//' +
			  window.location.hostname +
			  `/storage/access/`
	try {
		const token = localStorage.getItem('storageToken')
		const res = await fetch(IMAGE_URL + filename, {
			headers: {
				'Content-Type': 'application/octet-stream',
				Authorization: `Bearer ${token}`,
			},
		})
		const blob = await res.blob()

		return blob
	} catch (e) {
		console.log(e)
		return null
	}
}
