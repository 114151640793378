import { Box } from '@mui/material'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, MouseEventHandler } from 'react'
import { CustomIconFromValue } from './CustomIconFromValue'

const useStyles = makeStyles(() =>
	createStyles({
		close: {
			position: 'absolute',
			right: 10,
			top: 10,
		},
		drawer: (props: { width?: number }) => ({
			width: props.width ?? '50%',
			minWidth: props.width ?? '800px',
			marginTop: 64,
		}),
	})
)

interface CustomDrawerProps extends DrawerProps {
	width?: number
}

export const CustomDrawer: FC<CustomDrawerProps> = ({
	children,
	onClose,
	width,
	...otherProps
}) => {
	const classes = useStyles({ width })

	const onCloseClick: MouseEventHandler = (e) => {
		if (onClose) {
			onClose(e, 'backdropClick')
		}
	}

	return (
		<Drawer
			classes={{ paper: classes.drawer }}
			anchor="right"
			onClose={onClose}
			{...otherProps}
		>
			<Box mt={3} ml={4} mr={4} mb={10}>
				<IconButton
					className={classes.close}
					onClick={onCloseClick}
					size="large"
				>
					<CustomIconFromValue icon="svg cancel" />
				</IconButton>
				{children}
			</Box>
		</Drawer>
	)
}
