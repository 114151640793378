import { ApolloLink } from '@apollo/client'
import { isArray, isEmptyObject, isObject } from '@app/lib/utils'
import { getYear, isValid, parseISO } from 'date-fns'
import { isIso8601 } from '@app/lib//dateUtilility'

const convertToDate = (body: any) => {
	if (!body || isEmptyObject(body)) {
		return
	}
	if (isArray(body)) {
		for (let i = 0; i < (body as any[]).length; i++) {
			convertToDate(body[i])
		}
	}
	for (const key of Object.keys(body)) {
		const value = body[key]
		if (isIso8601(value)) {
			const parsedDate = parseISO(value)
			if (isValid(parsedDate) && getYear(parsedDate) > 2) {
				body[key] = parsedDate
			} else {
				body[key] = null
			}
		} else if (value === '0001-01-01T00:00:00.0000000Z') {
			body[key] = null
		} else if (isObject(value)) {
			convertToDate(value)
		}
	}
	return body
}

export const bodyTransformerLink = new ApolloLink((operation, forward) => {
	return forward(operation).map((data) => {
		return convertToDate(data)
	})
})

const parseHeaders = (rawHeaders: string) => {
	const headers = new Headers()
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')

	preProcessedHeaders.split(/\r?\n/).forEach((line) => {
		const parts = line.split(':')
		const key = parts?.shift()?.trim()

		if (key) {
			const value = parts.join(':').trim()

			headers.append(key, value)
		}
	})

	return headers
}

const uploadFetch = (url: string, options: any) =>
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest()
		xhr.onload = () => {
			const opts: any = {
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
			}
			opts.url =
				'responseURL' in xhr
					? xhr.responseURL
					: opts.headers.get('X-Request-URL')
			const body =
				'response' in xhr ? xhr.response : (xhr as any).responseText
			resolve(new Response(body, opts))
		}
		xhr.onerror = () => {
			reject(new TypeError('Network request failed'))
		}
		xhr.ontimeout = () => {
			reject(new TypeError('Network request failed'))
		}
		xhr.open(options.method, url, true)

		Object.keys(options.headers).forEach((key) => {
			xhr.setRequestHeader(key, options.headers[key])
		})

		if (xhr.upload) {
			xhr.upload.onprogress = options.onProgress
		}

		options.onAbortPossible(() => {
			xhr.abort()
		})

		xhr.send(options.body)
	})

export const customUploadFetch = (uri: any, options: any) => {
	if (options.onProgress) {
		return uploadFetch(uri, options)
	}

	return fetch(uri, options)
}
