import React, { FC, SVGProps } from 'react'

interface FrameLargeProps extends SVGProps<any> {
	image: string
}

const FrameLarge: FC<FrameLargeProps> = ({ image, ...otherProps }) => (
	<svg
		id="4-2-img_svg__smart-label-4-2"
		data-name="smart-label-4-2"
		viewBox="0 0 609 543.36"
		width="25em"
		height="25em"
		{...otherProps}
	>
		<defs>
			<style>
				{'.4-2-img_svg__cls-3{fill-rule:evenodd;fill:#3f3f3f}'}
			</style>
		</defs>
		<rect
			x={1.5}
			y={1.5}
			width={606}
			height={540.36}
			rx={7}
			stroke="#979797"
			strokeWidth={3}
		/>
		<path
			d="M47 53.32h525v394.36H47z"
			transform="translate(-5 -10.32)"
			fillRule="evenodd"
			fill="#f0efef"
		/>
		<path
			style={{ fill: 'grey' }}
			className="4-2-img_svg__cls-3"
			d="M545.71 487.34A1.71 1.71 0 10544 489a1.68 1.68 0 001.71-1.66zm7.5 9.57h-14.66a1.66 1.66 0 100 3.32h14.66a1.66 1.66 0 100-3.32m-19.51 0a1.66 1.66 0 101.7 1.66 1.68 1.68 0 00-1.7-1.66m10.3 5.91a1.66 1.66 0 100 3.32h9.55a1.66 1.66 0 100-3.32z"
			transform="translate(-5 -10.32)"
		/>
		<path
			style={{ fill: 'grey' }}
			className="4-2-img_svg__cls-3"
			d="M558.5 485.69H549a1.66 1.66 0 100 3.32h9.51a1.15 1.15 0 110 2.29H533.7a1.66 1.66 0 100 3.32h24.76a1.15 1.15 0 110 2.29 1.66 1.66 0 100 3.32 1.15 1.15 0 110 2.29 1.66 1.66 0 100 3.32 1.15 1.15 0 110 2.29h-19.68a1.66 1.66 0 100 3.32h19.68a12.92 12.92 0 100-25.83"
			transform="translate(-5 -10.32)"
		/>
		<path
			style={{ fill: 'grey' }}
			className="4-2-img_svg__cls-3"
			d="M538.55 506.14a1.66 1.66 0 10-1.7-1.66 1.68 1.68 0 001.7 1.66"
			transform="translate(-5 -10.32)"
		/>
		<image
			width={400}
			height={300}
			transform="matrix(1.31 0 0 1.31 43 44.18)"
			xlinkHref={image}
		/>
	</svg>
)

export default FrameLarge
