import IMask, { createPipe } from 'imask'

export const ipMaskoptions = {
	mask: 'IP.IP.IP.IP',
	blocks: {
		IP: {
			mask: IMask.MaskedRange,
			from: 0,
			to: 255,
		},
	},
} as unknown as IMask.MaskedPattern

export const ipPipe = createPipe(ipMaskoptions)
