import React, { FC, SVGProps } from 'react'

export const CircleFilledArrowIcon: FC<SVGProps<any>> = ({
	fill: fillFromProps,
	...otherProps
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		{...otherProps}
	>
		<path
			fill={fillFromProps}
			d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0z"
			transform="translate(-658 -669) translate(658 669)"
		/>
		<path
			fill="#FFF"
			fillRule="nonzero"
			d="M9.397 8.432H7.25c-.138 0-.25-.112-.25-.25 0-.066.026-.13.073-.177l2.932-2.932c.098-.097.256-.097.354 0l2.932 2.932c.097.098.097.256 0 .354-.047.047-.11.073-.177.073h-2.217l.038 6.46c0 .138-.11.25-.249.251l-1 .006c-.138.001-.25-.11-.251-.248l-.038-6.469z"
			transform="translate(-658 -669) translate(658 669) rotate(180 10.182 10.075)"
		/>
	</svg>
)
