import { isSSR } from '@app/lib/utils'

export function getLocalStorageValue<T = any>(
	key: string,
	initialValue?: T
): T | undefined {
	if (isSSR()) {
		return initialValue
	}

	try {
		const item = window.localStorage.getItem(key)

		return item ? (JSON.parse(item) as T) : initialValue
	} catch (error) {
		return initialValue
	}
}
