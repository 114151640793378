import classNames from 'classnames'
import React, { FC } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { StatusType } from '@app/@types'

const useStyes = makeStyles((theme) =>
	createStyles({
		root: {
			borderRadius: '50%',
			height: theme.spacing(1.3),
			width: theme.spacing(1.3),
		},
		registered: {
			backgroundColor: theme.palette.grey[400],
		},
		disabled: {
			backgroundColor: theme.palette.grey[400],
		},
		error: {
			backgroundColor: theme.palette.error.main,
		},
		success: {
			backgroundColor: theme.palette.success.main,
		},
		warning: {
			backgroundColor: theme.palette.warning.main,
		},
	})
)

interface DotProps {
	variant?: StatusType
}

export const Dot: FC<DotProps> = ({ variant = 'success' }) => {
	const classes = useStyes()

	return <div className={classNames(classes.root, classes[variant])}></div>
}
