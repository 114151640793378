import classNames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import Button, { ButtonProps } from '@mui/material/Button'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { CustomIconFromValue } from '../CustomIconFromValue'
import { Typography, darken, lighten } from '@mui/material'
import { TranslateMessage } from '@app/lib/lang'
import { themeColors } from '@app/constants'

const useStyles = makeStyles((theme) =>
	createStyles({
		customButton: ({ color }: any) => ({
			color: 'white',
			borderRadius: '2px',
			boxShadow: 'none',
		}),
		smallCustomButton: {
			fontSize: '0.6rem',
		},
		mediumCustomButton: {
			height: '28px',
			minWidth: '145px',
			fontSize: '0.75rem',
		},
		largeCustomButton: {
			height: '48px',
			width: '175px',
			fontSize: '0.75rem',
		},
		primary: {
			// backgroundColor: themeColors.agitronDark,
			backgroundColor: themeColors.dark,
			'&:hover': {
				boxShadow: 'none',
				// backgroundColor: lighten(themeColors.agitronDark, 0.12),
				backgroundColor: lighten(themeColors.dark, 0.12),
			},
		},
		secondary: {
			backgroundColor: themeColors.white,
			border: `2px solid ${themeColors.agitronDark}`,
			color: `${themeColors.agitronDark} !important`,
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: darken(themeColors.white, 0.02),
			},
		},
		error: {
			backgroundColor: themeColors.errorRed,
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: lighten(themeColors.errorRed, 0.12),
			},
		},
	})
)

export interface CustomButtonProps extends ButtonProps {
	className?: string
	disabled?: boolean
	link?: string
	loading?: boolean
	size?: 'small' | 'medium' | 'large'
	type?: 'button' | 'submit'
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
	onClick?: () => void
	label?: string
	fontWeight?: number | string
	fontColor?: string
}

export const CustomButton: FC<CustomButtonProps> = (props) => {
	const {
		className,
		children,
		disabled = false,
		link,
		loading = false,
		size = 'large',
		type = 'button',
		color = 'primary',
		onClick,
		label,
		fontWeight = 'bold',
		fontColor,
		sx,
		fullWidth,
		...otherProps
	} = props

	const classes = useStyles({ color })

	const getCustomButtonSizeClassName = () => {
		switch (size) {
			case 'large':
				return classes.largeCustomButton
			case 'medium':
				return classes.mediumCustomButton
			default:
				return classes.smallCustomButton
		}
	}

	const customButtonSizeClassName = getCustomButtonSizeClassName()

	const getCustomButtonColor = () => {
		switch (color) {
			case 'primary':
				return classes.primary
			case 'secondary':
				return classes.secondary
			case 'error':
				return classes.error

			default:
				return classes.smallCustomButton
		}
	}

	const getCustomButtonColorClassName = getCustomButtonColor()

	const onCustomButtonClick = () => {
		if (onClick) {
			onClick()
		}
	}

	const button = (
		<Button
			disabled={loading || disabled}
			className={
				sx
					? ''
					: classNames([
							classes.customButton,
							customButtonSizeClassName,
							getCustomButtonColorClassName,
							className,
					  ])
			}
			onClick={onCustomButtonClick}
			variant="contained"
			type={type}
			sx={{ ...sx, ...(fullWidth && { minWidth: '100%' }) }}
			{...otherProps}
		>
			{loading ? (
				<CustomIconFromValue
					icon="svg loader"
					className={customButtonSizeClassName}
				/>
			) : (
				<Typography
					component="div"
					fontWeight={fontWeight}
					color={fontColor}
					fontSize={size === 'medium' || size === 'small' ? 12 : 14}
				>
					{label ? <TranslateMessage id={label} /> : <>{children}</>}
				</Typography>
			)}
		</Button>
	)

	if (link) {
		return <Link href={link}>{button}</Link>
	}

	return <>{button}</>
}
