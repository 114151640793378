import React, { FC } from 'react'
import { useField } from 'formik'
import { SwitchProps } from '@mui/material/Switch'
import { AgitronSwitch } from '../CustomSwitch'
import { useCustomForm } from './hooks'

export interface CustomFormAgitronSwitchProps extends SwitchProps {
	name: string
	onEnable?: () => void
	onDisable?: () => void
}

export const CustomFormAgitronSwitch: FC<CustomFormAgitronSwitchProps> = ({
	disabled: disabledFromProps,
	name,
	onChange,
	onEnable,
	onDisable,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const [field, , helpers] = useField({
		name,
	})

	const onSwitchChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		if (!checked && onDisable) {
			onDisable()
			return
		}

		helpers.setValue(checked)

		if (onChange) {
			onChange(e, checked)
		}

		if (checked && onEnable) {
			onEnable()
		}
	}

	return (
		<AgitronSwitch
			{...otherProps}
			disabled={disabled || disabledFromProps}
			onChange={onSwitchChange}
			checked={field.value || false}
		/>
	)
}
