import classNames from 'classnames'
import React, { FC, CSSProperties, MouseEventHandler, SVGProps } from 'react'
import { svgIconsMap } from '@app/assets/icons'
import { Box } from '@mui/material'

const convertCssPropertiesToSvg = (styles?: CSSProperties) => {
	let svgProps: SVGProps<any> = {}

	if (styles) {
		if (styles.backgroundColor) {
			svgProps.fill = styles.backgroundColor
		}

		if (styles.color) {
			svgProps.fill = styles.color
		}

		if (styles.height && styles.width) {
			svgProps.height = styles.height
			svgProps.width = styles.width
			/*
			const scale = styles.scale ? (styles.scale as number) : 2

			svgProps.viewBox = `0 0 ${parseInt(styles.width as any) / scale} ${parseInt(styles.height as any) / scale}`*/
		}

		if (styles.width) {
		}
	}

	return svgProps
}

export enum IconType {
	font = 'font',
	svg = 'svg',
}

interface IconFromValueProps {
	className?: string
	icon: string
	type?: IconType
	style?: CSSProperties
	onClick?: MouseEventHandler
	color?: string
	size?: string | number
}

export const CustomIconFromValue: FC<IconFromValueProps> = ({
	icon,
	type = IconType.font,
	className,
	onClick,
	style,
	color,
	size,
}: IconFromValueProps) => {
	if (!icon) {
		return null
	}

	for (const iconType in IconType) {
		if (icon.startsWith(IconType[iconType as keyof typeof IconType])) {
			icon = icon
				.replace(IconType[iconType as keyof typeof IconType], '')
				.trim()
			type = IconType[iconType as keyof typeof IconType]

			break
		}
	}

	const iconStyle = {
		...style,
		...(size ? { height: size, width: size } : null),
	}

	if (type === IconType.font) {
		return (
			<i
				className={classNames(icon, className)}
				style={{ ...style, color: color }}
				onClick={onClick}
			></i>
		)
	}

	if (svgIconsMap[icon]) {
		const SvgIcon = svgIconsMap[icon]
		const svgProps = convertCssPropertiesToSvg(iconStyle)

		return (
			<Box className={className} style={{ lineHeight: 0.5 }}>
				<SvgIcon {...svgProps} />
			</Box>
		)
	}

	const iconSrc = `/icons/${icon}.svg`

	return (
		<img
			className={className}
			src={iconSrc}
			style={style}
			onClick={onClick}
			alt={icon}
		/>
	)
}
