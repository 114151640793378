import { TranslateMessage } from '@app/lib/lang'
import { Box } from '@mui/material'
import Paper from '@mui/material/Paper'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import { CustomDivider } from './CustomDivider'
import { AgitronSwitch } from './CustomSwitch'
import { Dot } from './Dot'

const useStyles = makeStyles((theme) =>
	createStyles({
		header: {
			fontSize: theme.spacing(2),
			fontWeight: 'bold',
		},
	})
)

interface CustomCardProps {
	availability?: 'disabled' | 'success' | 'warning' | 'error'
	header?: string
	padding?: string | number
	isToggled?: boolean
	showToggle?: boolean
	isShadow?: boolean
	noPadding?: boolean
	children: ReactNode
}

export function CustomCard({
	availability,
	children,
	header,
	noPadding,
	isToggled = false,
	showToggle = false,
	isShadow = false,
}: CustomCardProps) {
	const classes = useStyles()

	return (
		<Paper
			elevation={0}
			sx={{
				borderRadius: '5px',
				boxShadow: isShadow
					? '0 0 5px 0 rgba(0, 0, 0, 0.1)'
					: undefined,
			}}
		>
			<Box padding={noPadding ? 0 : 2}>
				{header && (
					<>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
						>
							<Box display="flex" alignItems="center">
								{availability && (
									<Box mr={1}>
										<Dot variant={availability} />
									</Box>
								)}
								<Typography
									className={classes.header}
									variant="h4"
									component="h4"
								>
									<TranslateMessage id={header} />
								</Typography>
							</Box>
							{showToggle && (
								<AgitronSwitch
									disabled={availability === 'disabled'}
									checked={isToggled}
								/>
							)}
						</Box>
						<CustomDivider />
					</>
				)}
				{children}
			</Box>
		</Paper>
	)
}
