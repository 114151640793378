import { DefaultOptions } from '@apollo/client'
import APP from './app.json'
const {
	NAMESPACE = '_',
	CONSTANTS: {
		HTTP_ONLY = 'HTTP_ONLY',
		LOCAL_STORAGE = 'LOCAL_STORAGE',
		FORBIDDEN = 'Forbidden',
		UNAUTHORIZED = 'Unauthorized!',
		NOT_ALLOWED = 'Not allowed',
	} = {},
	PREFIX = '-x',
	TOKEN_SUFFIX = '-token',
	REFRESH_TOKEN_SUFFIX = '-refresh-token',
	AUTH_HEADER_SUFFIX = '-auth-request-type',
} = APP

const SERVER_MESSAGES = { FORBIDDEN, UNAUTHORIZED, NOT_ALLOWED }
const AUTH = {
	STRATEGIES: {
		CLIENT: {
			AUTH_HEADER: `${PREFIX}${NAMESPACE}${AUTH_HEADER_SUFFIX}`,
			HTTP_ONLY,
			LOCAL_STORAGE,
		},
	},
}

// You may change this one only to match your needs. This is how what authentication strategy
// you want your client uses

const CLIENT_AUTH_REQUEST_TYPE =
	process.env.NODE_ENV != 'development'
		? AUTH.STRATEGIES.CLIENT.HTTP_ONLY
		: AUTH.STRATEGIES.CLIENT.LOCAL_STORAGE // Or
// AUTH.STRATEGIES.CLIENT.HTTP_ONLY

const TOKEN_NAME = `${PREFIX}${NAMESPACE}${TOKEN_SUFFIX}`
const REFRESH_TOKEN_NAME = `${PREFIX}${NAMESPACE}${REFRESH_TOKEN_SUFFIX}`

const JWT = {
	HEADER: {
		TOKEN: {
			NAME: TOKEN_NAME,
		},
		REFRESH_TOKEN: {
			NAME: REFRESH_TOKEN_NAME,
		},
	},
	LOCAL_STORAGE: {
		TOKEN: {
			NAME: `${NAMESPACE}Token`,
		},
		REFRESH_TOKEN: {
			NAME: `${NAMESPACE}RefreshToken`,
		},
	},
}

const CLIENT_AUTHENTICATION_METHOD = {
	// Either both FALSE or one TRUE at any given time
	LOCAL_STORAGE: true,
}

const APOLLO_DEFAULT_OPTIONS: DefaultOptions = {
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

export {
	APOLLO_DEFAULT_OPTIONS,
	AUTH,
	JWT,
	CLIENT_AUTH_REQUEST_TYPE,
	CLIENT_AUTHENTICATION_METHOD,
	SERVER_MESSAGES,
}
